import "core-js/modules/es6.object.assign";
import React from "react";
import Div from "@ds-kit/div";
import Icon from "@ds-kit/icon";
import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import { Header, IconSpan, StyledSpan } from "./Components";
var StyledDiv = styled.div.withConfig({
  displayName: "HeaderCell__StyledDiv",
  componentId: "sc-14jveff-0"
})(["display:none;", "{display:block;}"], themeGet("mediaQueries.sm"));

var HeaderCell = function HeaderCell(_ref) {
  var column = _ref.column,
      isBlockViewOnMobile = _ref.isBlockViewOnMobile,
      cellWidth = _ref.cellWidth;
  var columnSortedOptions = !column.disableSorting && column.getSortByToggleProps();
  var headerProps = column.getHeaderProps();
  return React.createElement(Header, Object.assign({}, headerProps, {
    isBlockViewOnMobile: isBlockViewOnMobile,
    sortedIndex: column.sortedIndex,
    cellWidth: cellWidth
  }), React.createElement(Div, Object.assign({
    display: "flex",
    style: {
      justifyContent: "space-between"
    }
  }, columnSortedOptions), React.createElement(Div, {
    width: "100%"
  }, React.createElement(StyledSpan, {
    fontWeight: 600
  }, column.render("Header"))), React.createElement(StyledDiv, {
    display: column.disableSorting ? "none" : "block"
  }, React.createElement(IconSpan, {
    sortedDesc: column.sortedDesc,
    sorted: column.sorted,
    variant: "asc"
  }, React.createElement(Icon, {
    name: "chevron_up",
    size: 20
  })), React.createElement(IconSpan, {
    sortedDesc: column.sortedDesc,
    sorted: column.sorted,
    variant: "desc"
  }, React.createElement(Icon, {
    name: "chevron_down",
    size: 20
  })))), column.canFilter && React.createElement("div", null, column.render("Filter")));
};

export default HeaderCell;