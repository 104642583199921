import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.regexp.split";
import React from "react";
import { MDXProvider } from "@mdx-js/react";
import CodeBlock from "../CodeBlock";
import { Heading, P } from "../../../packages/Typography/src";

var getId = function getId(children) {
  if (typeof children === "string") {
    return children.split(" ").join("-").toLowerCase();
  } else {
    return "";
  }
};

var CustomHeading = function CustomHeading(props) {
  return React.createElement(Heading, Object.assign({
    id: getId(props.children),
    as: "h2",
    size: "xl"
  }, props));
};

var Content = function Content(_ref) {
  var children = _ref.children;
  return React.createElement(MDXProvider, {
    components: {
      h1: function h1(props) {
        return React.createElement(Heading, Object.assign({
          as: "h1",
          mt: "0",
          size: "xxxl"
        }, props));
      },
      h2: function h2(props) {
        return React.createElement(CustomHeading, props);
      },
      h3: function h3(props) {
        return React.createElement(Heading, Object.assign({
          as: "h3",
          size: "lg"
        }, props));
      },
      h4: function h4(props) {
        return React.createElement(Heading, Object.assign({
          as: "h4",
          size: "md"
        }, props));
      },
      p: function p(props) {
        return React.createElement(P, Object.assign({
          size: "md"
        }, props));
      },
      code: CodeBlock
    }
  }, children);
};

export default Content;