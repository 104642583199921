import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { typography, space, variant } from "styled-system";
var variants = variant({
  scale: "links",
  prop: "variant"
});
var StyledA = styled("a").attrs(function (props) {
  return {
    fontSize: props.size ? props.theme.responsiveFontSizes[props.size] : props.fontSize
  };
}).withConfig({
  displayName: "src__StyledA",
  componentId: "sc-87r3a2-0"
})(["", ";", ";", ";text-transform:", ";"], typography, space, variants, function (p) {
  return p.uppercase ? "uppercase" : "none";
});
var Link = React.forwardRef(function (_ref, ref) {
  var children = _ref.children,
      _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "default" : _ref$variant,
      onClick = _ref.onClick,
      restProps = _objectWithoutPropertiesLoose(_ref, ["children", "variant", "onClick"]);

  var handleClick = function handleClick(evt) {
    typeof evt.target.blur === "function" && evt.target.blur();
    if (onClick) onClick(evt);
  };

  return React.createElement(StyledA, Object.assign({
    ref: ref,
    onClick: handleClick,
    variant: variant
  }, restProps), children);
});
export default Link;