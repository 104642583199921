import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import React from "react";
import styled from "styled-components";
var StyledColorPalettes = styled.div.withConfig({
  displayName: "ColorPalettes__StyledColorPalettes",
  componentId: "sc-183qyo1-0"
})(["display:grid;grid-template-columns:repeat(3,1fr);grid-column-gap:1.5rem;grid-row-gap:1.5rem;"]);
var StyledColorBlock = styled.div.withConfig({
  displayName: "ColorPalettes__StyledColorBlock",
  componentId: "sc-183qyo1-1"
})(["position:relative;display:flex;justify-content:space-between;padding:1rem;cursor:pointer;&::after{content:\"\";position:absolute;top:0;left:0;right:0;bottom:0;background:#fff;opacity:0;}&:hover::after{opacity:0.1;}&:active::after{opacity:0.15;}"]);
var ColorName = styled.div.withConfig({
  displayName: "ColorPalettes__ColorName",
  componentId: "sc-183qyo1-2"
})(["padding:1rem;text-transform:capitalize;background-color:", ";"], function (p) {
  return p.theme.colors.grey[50];
});
var StyledColorPalette = styled.div.withConfig({
  displayName: "ColorPalettes__StyledColorPalette",
  componentId: "sc-183qyo1-3"
})(["border-radius:0.25rem;overflow:hidden;"]);

var ColorBlock = function ColorBlock(_ref) {
  var val = _ref.val,
      color = _ref.color;

  var handleCopyToClipboard = function handleCopyToClipboard() {
    var el = document.createElement("textarea");
    el.value = color;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return React.createElement(StyledColorBlock, {
    onClick: handleCopyToClipboard,
    style: {
      color: +val > 400 ? "#FFF" : "currentcolor",
      backgroundColor: color
    }
  }, React.createElement("div", null, val), React.createElement("div", null, color));
};

var ColorPalette = function ColorPalette(_ref2) {
  var colors = _ref2.colors,
      name = _ref2.name;
  return React.createElement(StyledColorPalette, null, Object.keys(colors).map(function (color) {
    return React.createElement(ColorBlock, {
      key: color,
      val: color,
      color: colors[color]
    });
  }), React.createElement(ColorName, null, name));
};

var ColorPalettes = function ColorPalettes(_ref3) {
  var colors = _ref3.colors;
  var colorPalettes = Object.keys(colors).reduce(function (acc, cur) {
    if (typeof colors[cur] !== "object" || cur === "dataScales") return acc;
    acc.push({
      name: cur,
      colors: colors[cur]
    });
    return acc;
  }, []);
  return React.createElement(React.Fragment, null, React.createElement("h2", null, "Color palettes"), React.createElement(StyledColorPalettes, null, colorPalettes.map(function (colorPalette) {
    return React.createElement(ColorPalette, {
      key: colorPalette.name,
      colors: colorPalette.colors,
      name: colorPalette.name
    });
  })));
};

export default ColorPalettes;