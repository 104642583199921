import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from "react";
import styled, { withTheme } from "styled-components";
import { color, width, height, space } from "styled-system";
var iconsWithoutStroke = ["facebook", "twitter", "instagram", "code", "github", "bold", "iframe", "numbered_list", "translate", "unordered_list"];
var Svg = styled.svg.withConfig({
  displayName: "src__Svg",
  componentId: "r3ctsc-0"
})(["display:inline-block;vertical-align:middle;", " ", " ", " ", ""], width, height, color, space);
var Use = withTheme(function (_ref) {
  var name = _ref.name,
      _ref$theme = _ref.theme,
      theme = _ref$theme === void 0 ? {} : _ref$theme,
      _ref$kind = _ref.kind,
      kind = _ref$kind === void 0 ? "icon" : _ref$kind;
  var spriteSheet = theme.spriteSheet,
      iconPrefix = theme.iconPrefix;
  return React.createElement("use", {
    xlinkHref: (spriteSheet && spriteSheet[kind] || "") + "#" + (iconPrefix || "") + name
  });
});
export var Flag = function Flag(_ref2) {
  var restProps = Object.assign({}, _ref2);
  return Icon(_objectSpread({
    kind: "flag",
    strokeWidth: 0
  }, restProps));
};
/**
 * The Icon component can be used to render icons from any symbol sprite sheet.
 */

var Icon = function Icon(_ref3) {
  var _ref3$kind = _ref3.kind,
      kind = _ref3$kind === void 0 ? "icon" : _ref3$kind,
      _ref3$size = _ref3.size,
      size = _ref3$size === void 0 ? "1.5rem" : _ref3$size,
      _ref3$name = _ref3.name,
      name = _ref3$name === void 0 ? "share" : _ref3$name,
      _ref3$strokeWidth = _ref3.strokeWidth,
      strokeWidth = _ref3$strokeWidth === void 0 ? 2 : _ref3$strokeWidth,
      _ref3$stroke = _ref3.stroke,
      stroke = _ref3$stroke === void 0 ? "currentcolor" : _ref3$stroke,
      _ref3$fill = _ref3.fill,
      fill = _ref3$fill === void 0 ? "currentcolor" : _ref3$fill,
      _ref3$color = _ref3.color,
      color = _ref3$color === void 0 ? "currentcolor" : _ref3$color,
      restProps = _objectWithoutPropertiesLoose(_ref3, ["kind", "size", "name", "strokeWidth", "stroke", "fill", "color"]);

  return React.createElement(Svg, Object.assign({
    width: size,
    height: size,
    viewBox: "0 0 24 24",
    strokeWidth: iconsWithoutStroke.indexOf(name) === -1 ? strokeWidth : 0,
    stroke: stroke,
    fill: fill,
    color: color
  }, restProps), React.createElement(Use, {
    kind: kind,
    name: name
  }));
};

export default Icon;