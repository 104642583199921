import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled, { keyframes } from "styled-components";
import { boxShadow, zIndex, color, borderRadius, system } from "styled-system";
import { useDialogState, Dialog, DialogDisclosure, DialogBackdrop } from "reakit/Dialog";
import { filterProps } from "../../../src/utils/filterProps";
var allPropNames = [].concat(boxShadow.propNames, color.propNames, borderRadius.propNames, zIndex.propNames, ["zIndex", "size"]);
var modalSize = system({
  size: {
    property: "maxWidth",
    scale: "modalSizes",
    defaultScale: {
      sm: "20rem",
      md: "30rem",
      lg: "40rem"
    }
  }
});
var modalEnterAnimation = keyframes(["0%{transform:translate(-50%,0);}100%{transform:translate(-50%,-50%);}"]);
var Div = styled("div").withConfig({
  displayName: "src__Div",
  componentId: "imcnqb-0"
})(["max-height:", ";overflow-y:scroll;-webkit-overflow-scrolling:touch;"], function (p) {
  return p.maxHeight ? p.maxHeight : "calc(-7rem + 100vh)";
});
var StyledDialog = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(Dialog, filteredProps);
}).attrs({
  boxShadow: "lg",
  bg: "white"
}).withConfig({
  displayName: "src__StyledDialog",
  componentId: "imcnqb-1"
})(["position:fixed;top:50%;left:50%;background-color:white;outline:none;width:calc(100% - 2rem);max-height:calc(100% - 4rem);padding:1.5rem;", ";", ";", ";", ";", ";animation:", " 300ms cubic-bezier(0.215,0.61,0.355,1) forwards;"], borderRadius, boxShadow, modalSize, color, zIndex, modalEnterAnimation);
var backdropEnterAnimation = keyframes(["0%{opacity:0;}100%{opacity:0.5;}"]);
var StyledDialogBackdrop = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(DialogBackdrop, filteredProps);
}).withConfig({
  displayName: "src__StyledDialogBackdrop",
  componentId: "imcnqb-2"
})(["position:fixed;top:0px;right:0px;bottom:0px;left:0px;opacity:0.5;", ";", ";animation:", " 500ms ease forwards;"], color, zIndex, backdropEnterAnimation);

var Modal = function Modal(_ref) {
  var disclosure = _ref.disclosure,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      _ref$borderRadius = _ref.borderRadius,
      borderRadius = _ref$borderRadius === void 0 ? "md" : _ref$borderRadius,
      _ref$zIndex = _ref.zIndex,
      zIndex = _ref$zIndex === void 0 ? 9999 : _ref$zIndex,
      _ref$enableDialogBack = _ref.enableDialogBackdrop,
      enableDialogBackdrop = _ref$enableDialogBack === void 0 ? true : _ref$enableDialogBack,
      children = _ref.children,
      _ref$visible = _ref.visible,
      visible = _ref$visible === void 0 ? false : _ref$visible,
      props = _objectWithoutPropertiesLoose(_ref, ["disclosure", "size", "borderRadius", "zIndex", "enableDialogBackdrop", "children", "visible"]);

  var dialog = useDialogState({
    visible: visible
  });
  return React.createElement(React.Fragment, null, React.createElement(DialogDisclosure, dialog, function (disclosureProps) {
    return React.cloneElement(React.Children.only(disclosure ? React.createElement("span", null, disclosure) : React.createElement("button", null, "Specify disclosure")), disclosureProps);
  }), enableDialogBackdrop && React.createElement(StyledDialogBackdrop, Object.assign({
    bg: "#000",
    zIndex: zIndex
  }, dialog)), React.createElement(StyledDialog, Object.assign({
    "aria-label": "Dialog",
    size: size,
    borderRadius: borderRadius,
    zIndex: zIndex
  }, dialog, props), React.createElement(Div, null, typeof children == "function" ? children(dialog) : children)));
};

export default Modal;