import React from "react";
import styled from "styled-components";
import { display, flexWrap, alignItems, justifyContent, space, color, height } from "styled-system";
var StyledFlex = styled.div.withConfig({
  displayName: "src__StyledFlex",
  componentId: "sc-146iwid-0"
})(["", " ", " ", " ", " ", " ", " ", ""], display, flexWrap, alignItems, justifyContent, space, color, height);

var Flex = function Flex(props) {
  return React.createElement(StyledFlex, props);
};

Flex.defaultProps = {
  display: "flex",
  flexWrap: "wrap"
};
export default Flex;