import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variant, system, typography, space, borderRadius, borderColor, boxShadow } from 'styled-system';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  display: inline-block;\n  vertical-align: middle;\n  border: 0.125rem solid transparent;\n  outline: none;\n  &::-webkit-inner-spin-button,\n  &::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n  &::-webkit-input-placeholder {\n    ", "\n  }\n  &:-ms-input-placeholder {\n    ", "\n  }\n  &::placeholder {\n    ", "\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  &:focus {\n    ", "\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var inputVariants = variant({
  scale: "inputs",
  prop: "variant"
});
var placeholderColor = system({
  placeholderColor: {
    property: "color",
    scale: "colors"
  }
});
var raisedBoxShadow = system({
  raisedBoxShadow: {
    property: "boxShadow",
    scale: "shadows"
  }
});
var inputSize = system({
  size: {
    property: "height",
    scale: "inputSizes",
    defaultScale: {
      sm: "2rem",
      md: "2.5rem",
      lg: "3rem"
    }
  },
  width: {
    property: "width",
    scale: "widths"
  }
});
var inputPadding = system({
  size: {
    properties: ["paddingLeft", "paddingRight"],
    scale: "inputPaddings",
    defaultScale: {
      sm: "0.75rem",
      md: "1rem",
      lg: "1.25rem"
    }
  }
});
var Textfield = styled("input").attrs(function (props) {
  var attrs = props.raised && {
    borderColor: props.borderColor || "transparent",
    boxShadow: "sm",
    raisedBoxShadow: "md"
  };
  return _objectSpread2({}, attrs);
}).withConfig({
  displayName: "indexes__Textfield",
  componentId: "sc-1o9mk7i-0"
})(_templateObject(), placeholderColor, placeholderColor, placeholderColor, inputVariants, inputSize, inputPadding, typography, space, borderRadius, borderColor, boxShadow, raisedBoxShadow);
Textfield.defaultProps = {
  size: "md",
  variant: "default",
  fontSize: "inherit",
  fontFamily: "inherit",
  lineHeight: "normal",
  borderRadius: "md"
};
Textfield.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fontFamily: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};
export default Textfield;