import React from "react";
import styled, { css } from "styled-components";
import { display, gridTemplateColumns, gridTemplateRows, gridColumnGap, gridRowGap, alignItems, space, color } from "styled-system";
var StyledGrid = styled.div.withConfig({
  displayName: "grid__StyledGrid",
  componentId: "sc-5ooovh-0"
})(["max-width:100%;", " ", " ", " ", ""], function () {
  return css(["display:flex;flex-wrap:wrap;@supports(display:grid){flex-wrap:unset;", " ", " ", " ", " ", "}"], display, gridTemplateColumns, gridTemplateRows, gridColumnGap, gridRowGap);
}, alignItems, space, color);

var Grid = function Grid(props) {
  return React.createElement(StyledGrid, props);
};

Grid.defaultProps = {
  display: "grid",
  gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
  gridTemplateRows: "auto",
  gridColumnGap: ["1.25rem", "1.5rem"],
  gridRowGap: ["1.25rem", "1.5rem"]
};
export default Grid;