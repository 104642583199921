import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import { Card } from "@ds-kit/card";
import { Flex } from "@ds-kit/grid";
import Div from "@ds-kit/div";
import Lozenge from "@ds-kit/lozenge";
import { Heading, P } from "@ds-kit/typography";

var LargeDataStoryCard = function LargeDataStoryCard(_ref) {
  var title = _ref.title,
      description = _ref.description,
      _ref$image = _ref.image,
      image = _ref$image === void 0 ? {} : _ref$image,
      updated = _ref.updated,
      props = _objectWithoutPropertiesLoose(_ref, ["title", "description", "image", "updated"]);

  return React.createElement(Card, props, React.createElement(Div, {
    overflow: "hidden",
    borderRadius: "md"
  }, React.createElement("img", {
    src: image.url + "?w=800&h=450&fit=crop",
    alt: title,
    style: {
      display: "block",
      width: "100%",
      height: "auto"
    }
  })), React.createElement(Div, {
    pt: "0.75rem",
    overflow: "hidden"
  }, React.createElement(Heading, {
    size: "xl",
    my: "0"
  }, title), React.createElement(P, {
    muted: true,
    lineHeight: "dense",
    size: "sm",
    mt: "0.25rem",
    mb: "0",
    style: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  }, description), React.createElement(Lozenge, {
    mt: "0.75rem",
    bg: "green.50",
    color: "green.600",
    borderColor: "green.300"
  }, updated)));
};

var MediumDataStoryCard = function MediumDataStoryCard(_ref2) {
  var title = _ref2.title,
      description = _ref2.description,
      _ref2$image = _ref2.image,
      image = _ref2$image === void 0 ? {} : _ref2$image,
      updated = _ref2.updated,
      props = _objectWithoutPropertiesLoose(_ref2, ["title", "description", "image", "updated"]);

  return React.createElement(Card, props, React.createElement(Flex, {
    flexWrap: ["nowrap", "wrap"]
  }, React.createElement(Div, {
    flex: "none",
    width: ["6rem", "100%"],
    height: ["6rem", "auto"],
    bg: "grey.50",
    borderRadius: "md",
    overflow: "hidden"
  }, React.createElement("img", {
    src: image.url + "?w=400&h=400&fit=crop",
    alt: title,
    style: {
      display: "block",
      width: "100%",
      height: "auto"
    }
  })), React.createElement(Div, {
    flex: "1 1 auto",
    pl: ["1.25rem", "0"],
    pt: ["0", "0.75rem"],
    overflow: "hidden"
  }, React.createElement(Heading, {
    size: "lg",
    mb: "0",
    mt: ["0.75rem", "0"]
  }, title), React.createElement(P, {
    muted: true,
    lineHeight: "dense",
    size: "sm",
    mt: "0.25rem",
    mb: "0",
    style: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  }, description), React.createElement(Lozenge, {
    mt: "0.5rem",
    bg: "green.50",
    color: "green.600",
    borderColor: "green.300"
  }, updated))));
};

var SmallDataStoryCard = function SmallDataStoryCard(_ref3) {
  var title = _ref3.title,
      description = _ref3.description,
      _ref3$image = _ref3.image,
      image = _ref3$image === void 0 ? {} : _ref3$image,
      updated = _ref3.updated,
      props = _objectWithoutPropertiesLoose(_ref3, ["title", "description", "image", "updated"]);

  return React.createElement(Card, props, React.createElement(Flex, {
    flexWrap: "nowrap"
  }, React.createElement(Div, {
    flex: "none",
    width: "6rem",
    height: "6rem",
    bg: "grey.50",
    borderRadius: "md",
    overflow: "hidden"
  }, React.createElement("img", {
    src: image.url + "?w=96&h=96&fit=crop",
    alt: title,
    style: {
      display: "block",
      width: "100%",
      height: "auto"
    }
  })), React.createElement(Div, {
    flex: "1 1 auto",
    pl: "1.25rem",
    overflow: "hidden"
  }, React.createElement(Heading, {
    size: "lg",
    mt: "0.75rem",
    mb: "0"
  }, title), React.createElement(P, {
    muted: true,
    lineHeight: "dense",
    size: "sm",
    mt: "0.25rem",
    mb: "0",
    style: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }
  }, description), React.createElement(Lozenge, {
    mt: "0.5rem",
    bg: "green.50",
    color: "green.600",
    borderColor: "green.300"
  }, updated))));
};

var DataStoryCard = function DataStoryCard(_ref4) {
  var size = _ref4.size,
      props = _objectWithoutPropertiesLoose(_ref4, ["size"]);

  return React.createElement(React.Fragment, null, size === "sm" && React.createElement(SmallDataStoryCard, props), size === "md" && React.createElement(MediumDataStoryCard, props), size === "lg" && React.createElement(LargeDataStoryCard, props));
};

DataStoryCard.defaultProps = {
  size: "md"
};
export default DataStoryCard;