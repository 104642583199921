import "core-js/modules/es6.function.name";
import React from "react";
import styled from "styled-components";
var StyledMainColors = styled.div.withConfig({
  displayName: "MainColors__StyledMainColors",
  componentId: "sc-1yvz0gh-0"
})(["display:grid;grid-template-columns:repeat(4,1fr);grid-column-gap:1.5rem;grid-row-gap:1.5rem;"]);
var StyledColor = styled.div.withConfig({
  displayName: "MainColors__StyledColor",
  componentId: "sc-1yvz0gh-1"
})(["overflow:hidden;border-radius:0.25rem;> div:first-of-type{height:4.5rem;}> div:last-of-type{padding:1rem;background-color:", ";}"], function (p) {
  return p.theme.colors.grey[50];
});

var Color = function Color(_ref) {
  var color = _ref.color,
      name = _ref.name;
  return React.createElement(StyledColor, null, React.createElement("div", {
    style: {
      backgroundColor: color,
      color: "#FFFFFF"
    }
  }), React.createElement("div", null, color, React.createElement("br", null), name));
};

var MainColors = function MainColors(_ref2) {
  var colors = _ref2.colors;
  return React.createElement(React.Fragment, null, React.createElement("h2", null, "UI primary colors"), React.createElement(StyledMainColors, null, React.createElement(Color, {
    name: "Primary",
    color: colors.primary
  }), React.createElement(Color, {
    name: "Text",
    color: colors.text
  }), React.createElement(Color, {
    name: "Secondary text",
    color: colors.secondaryText
  }), React.createElement(Color, {
    name: "White",
    color: colors.white
  })), React.createElement("h2", null, "UI secondary colors"), React.createElement(StyledMainColors, null, React.createElement(Color, {
    name: "Success",
    color: colors.success
  }), React.createElement(Color, {
    name: "danger",
    color: colors.danger
  }), React.createElement(Color, {
    name: "Warning",
    color: colors.warning
  }), React.createElement(Color, {
    name: "Disabled",
    color: colors.disabled
  })));
};

export default MainColors;