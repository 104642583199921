import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { display, system } from "styled-system";
var avatarSize = system({
  size: {
    properties: ["width", "height"]
  }
});
var StyledAvatar = styled("div").withConfig({
  displayName: "src__StyledAvatar",
  componentId: "sc-1pp5na4-0"
})(["", " ", " > img{border-radius:100%;}"], display, avatarSize);

var Avatar = function Avatar(_ref) {
  var src = _ref.src,
      alt = _ref.alt,
      restProps = _objectWithoutPropertiesLoose(_ref, ["src", "alt"]);

  return React.createElement(StyledAvatar, restProps, React.createElement("img", {
    src: src,
    alt: alt
  }));
};

Avatar.defaultProps = {
  size: "3rem"
};
export default Avatar;