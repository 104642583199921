import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useState } from "react";
import styled from "styled-components";
import { space, color, width, layout, system } from "styled-system";
import Icon from "@ds-kit/icon";
import { Button } from "reakit/Button";
import Div from "@ds-kit/div";
import { motion, AnimatePresence } from "framer-motion";
import { filterProps } from "../../../src/utils/filterProps";
export var Accordion = function Accordion(_ref) {
  var children = _ref.children;
  return React.createElement("div", null, children);
};
var PanelContainer = styled(Div).withConfig({
  displayName: "src__PanelContainer",
  componentId: "sc-9havrz-0"
})(["", ";", ";", ";", ";"], space, width, layout, color);
var colorHover = system({
  bgHover: {
    property: "backgroundColor",
    scale: "colors"
  }
});
var allPropNames = [].concat(color.propNames, layout.propNames, ["bgHover"]);
var StyledButton = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(Button, filteredProps);
}).withConfig({
  displayName: "src__StyledButton",
  componentId: "sc-9havrz-1"
})(["", " ", " justify-content:space-between;align-items:center;font-size:inherit;display:flex;border:none;outline:none;&:hover,&:focus{", "}&::-moz-focus-inner{border:0;}"], color, layout, colorHover);
var Content = styled.div.withConfig({
  displayName: "src__Content",
  componentId: "sc-9havrz-2"
})(["display:flex;overflow:hidden;"]);
export var AccordionPanel = function AccordionPanel(_ref2) {
  var header = _ref2.header,
      _ref2$headerHeight = _ref2.headerHeight,
      headerHeight = _ref2$headerHeight === void 0 ? "3.5rem" : _ref2$headerHeight,
      children = _ref2.children,
      _ref2$open = _ref2.open,
      open = _ref2$open === void 0 ? false : _ref2$open,
      _ref2$borderTop = _ref2.borderTop,
      borderTop = _ref2$borderTop === void 0 ? "1px solid rgba(0,0,0,0.2)" : _ref2$borderTop,
      _ref2$bgHover = _ref2.bgHover,
      bgHover = _ref2$bgHover === void 0 ? "grey.50" : _ref2$bgHover,
      headerBg = _ref2.headerBg,
      restProps = _objectWithoutPropertiesLoose(_ref2, ["header", "headerHeight", "children", "open", "borderTop", "bgHover", "headerBg"]);

  var _useState = useState(open),
      isOpen = _useState[0],
      setIsOpen = _useState[1];

  var handleClick = function handleClick() {
    return setIsOpen(!isOpen);
  };

  var isHeaderCustomComponent = typeof header !== "string";
  return React.createElement(PanelContainer, Object.assign({
    borderTop: borderTop
  }, restProps), React.createElement(StyledButton, {
    "aria-expanded": isOpen,
    width: "100%",
    height: isHeaderCustomComponent ? "auto" : headerHeight,
    onClick: handleClick,
    bgHover: bgHover,
    bg: headerBg
  }, header, React.createElement(AccordionPanelHeaderIcon, {
    isOpen: isOpen
  })), React.createElement(AnimatePresence, null, isOpen && React.createElement(motion.section, {
    key: "content",
    initial: "collapsed",
    animate: "open",
    exit: "collapsed",
    variants: {
      open: {
        opacity: 1,
        height: "auto"
      },
      collapsed: {
        opacity: 0,
        height: 0
      }
    },
    transition: {
      duration: 0.25
    }
  }, React.createElement(Content, null, children))));
};

var AccordionPanelHeaderIcon = function AccordionPanelHeaderIcon(_ref3) {
  var isOpen = _ref3.isOpen;
  return React.createElement(Icon, {
    name: isOpen ? "chevron_up" : "chevron_down"
  });
};