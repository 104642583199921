export var sizes = {
  sm: {
    height: "2rem",
    fontSize: "0.875rem"
  },
  md: {
    height: "2.5rem",
    fontSize: "1rem"
  },
  lg: {
    height: "3rem",
    fontSize: "1.25rem"
  }
};
export var shadows = ["0 0.1875rem 0.3125rem 0.0625rem rgba(0,0,0,0.15)", "0 0.3125rem 0.5rem 0.125rem rgba(0,0,0,0.2)"];
export var suggestions = [{
  label: "Afghanistan"
}, {
  label: "Aland Islands"
}, {
  label: "Albania"
}, {
  label: "Algeria"
}, {
  label: "American Samoa"
}, {
  label: "Andorra"
}, {
  label: "Angola"
}, {
  label: "Anguilla"
}, {
  label: "Antarctica"
}].map(function (d) {
  return {
    value: d.label,
    label: d.label
  };
});