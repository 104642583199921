import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var colorScales = {
  lime: {
    "50": "#fdfff0",
    "100": "#fcfff0",
    "200": "#e7f2bf",
    "300": "#d0e691",
    "400": "#b8d966",
    "500": "#9fcb3f",
    "600": "#7ba62b",
    "700": "#59801b",
    "800": "#3a590e",
    "900": "#1f3308"
  },
  green: {
    "50": "#f0fff5",
    "100": "#e3fced",
    "200": "#b1f0cc",
    "300": "#84e3b0",
    "400": "#5ad698",
    "500": "#35c983",
    "600": "#22a36b",
    "700": "#147d53",
    "800": "#0a573a",
    "900": "#053022"
  },
  teal: {
    "50": "#dcf5f1",
    "100": "#95e8dd",
    "200": "#69dbd0",
    "300": "#42cfc5",
    "400": "#1fc2bc",
    "500": "#00b6b6",
    "600": "#008a8f",
    "700": "#006269",
    "800": "#003c42",
    "900": "#00181c"
  },
  cyan: {
    "50": "#e6faff",
    "100": "#a3eaff",
    "200": "#7adcff",
    "300": "#52cbff",
    "400": "#29b8ff",
    "500": "#009cf7",
    "600": "#007dd1",
    "700": "#0061ab",
    "800": "#004785",
    "900": "#002f5e"
  },
  blue: {
    "50": "#e6f6ff",
    "100": "#a3daff",
    "200": "#7ac6ff",
    "300": "#52aeff",
    "400": "#2994ff",
    "500": "#0077ff",
    "600": "#005ed9",
    "700": "#0047b3",
    "800": "#00338c",
    "900": "#002266"
  },
  blurble: {
    "50": "#f5edff",
    "100": "#dbc4ff",
    "200": "#be9cff",
    "300": "#9f73ff",
    "400": "#7847f5",
    "500": "#511ee9",
    "600": "#3610c2",
    "700": "#20059c",
    "800": "#120075",
    "900": "#09004f"
  },
  purple: {
    "50": "#f5dcef",
    "100": "#e89bd9",
    "200": "#db70c9",
    "300": "#cf48bd",
    "400": "#c225b2",
    "500": "#b605ab",
    "600": "#8f008a",
    "700": "#690069",
    "800": "#400042",
    "900": "#1a001c"
  },
  pink: {
    "50": "#ffe6ee",
    "100": "#ffa8c8",
    "200": "#fc7eb1",
    "300": "#f05196",
    "400": "#e32980",
    "500": "#d6046e",
    "600": "#b0005e",
    "700": "#8a004e",
    "800": "#63003c",
    "900": "#3d0027"
  },
  red: {
    "50": "#fff0f0",
    "100": "#ffd1d1",
    "200": "#ffa8ab",
    "300": "#fc7e87",
    "400": "#f05161",
    "500": "#e32942",
    "600": "#bd1934",
    "700": "#960c28",
    "800": "#70031d",
    "900": "#4a0115"
  },
  orange: {
    "50": "#fff7eb",
    "100": "#ffe6c2",
    "200": "#ffd199",
    "300": "#ffba70",
    "400": "#ffa047",
    "500": "#ff841e",
    "600": "#d9630f",
    "700": "#b34704",
    "800": "#8c3100",
    "900": "#662000"
  },
  yellow: {
    "50": "#fffce6",
    "100": "#fff4ab",
    "200": "#ffea82",
    "300": "#ffde59",
    "400": "#ffcf30",
    "500": "#ffbd07",
    "600": "#d99800",
    "700": "#b37700",
    "800": "#8c5900",
    "900": "#663d00"
  },
  grey: {
    "50": "#f6f5f8",
    "100": "#dddae5",
    "200": "#c5bfd2",
    "300": "#ada6be",
    "400": "#968daa",
    "500": "#7f7496",
    "600": "#685d81",
    "700": "#53476c",
    "800": "#3e3257",
    "900": "#2a1e42"
  }
};
var dataScales = {
  categorical: {
    default: [colorScales.blurble["400"], colorScales.pink["400"], colorScales.orange["500"], colorScales.yellow["400"], colorScales.teal["400"], colorScales.blue["300"], colorScales.blue["600"], colorScales.purple["500"], colorScales.red["400"]]
  },
  sequential: {
    default: [colorScales.blurble["700"], colorScales.blurble["300"], colorScales.blurble["50"]],
    lime: [colorScales.lime["700"], colorScales.lime["300"], colorScales.lime["50"]],
    green: [colorScales.green["700"], colorScales.green["300"], colorScales.green["50"]],
    blue: [colorScales.blue["700"], colorScales.blue["300"], colorScales.blue["50"]],
    purple: [colorScales.purple["700"], colorScales.purple["300"], colorScales.purple["50"]],
    pink: [colorScales.pink["700"], colorScales.pink["300"], colorScales.pink["50"]],
    red: [colorScales.red["600"], colorScales.red["300"], colorScales.red["50"]],
    orange: [colorScales.orange["600"], colorScales.yellow["200"], colorScales.yellow["50"]],
    sunset: [colorScales.purple["600"], colorScales.pink["400"], colorScales.yellow["300"]]
  },
  diverging: {
    default: [colorScales.pink["500"], colorScales.lime["500"]],
    pink_cyan: [colorScales.pink["500"], colorScales.cyan["500"]],
    yellow_teal: [colorScales.yellow["500"], colorScales.teal["500"]],
    orange_teal: [colorScales.orange["500"], colorScales.teal["500"]],
    red_lime: [colorScales.red["500"], colorScales.lime["500"]],
    orange_blue: [colorScales.orange["500"], colorScales.blue["600"]]
  }
};

var colors = _objectSpread({
  white: "#FFFFFF",
  dark: colorScales.grey["900"],
  text: colorScales.grey["900"],
  secondaryText: colorScales.grey["500"],
  background: "#FFFFFF",
  disabled: colorScales.grey["200"],
  primary: colorScales.blurble["500"],
  primaryLight: colorScales.blurble["400"],
  primaryLighter: colorScales.blurble["200"],
  primaryDark: colorScales.blurble["600"],
  primaryDarker: colorScales.blurble["800"],
  primaryContrast: "#FFFFFF",
  danger: colorScales.red["500"],
  dangerLight: colorScales.red["400"],
  dangerLighter: colorScales.red["200"],
  dangerDark: colorScales.red["600"],
  dangerDarker: colorScales.red["800"],
  dangerContrast: "#FFFFFF",
  warning: colorScales.yellow["500"],
  warningLight: colorScales.yellow["400"],
  warningLighter: colorScales.yellow["200"],
  warningDark: colorScales.yellow["600"],
  warningDarker: colorScales.yellow["800"],
  warningContrast: colorScales.yellow["900"],
  success: colorScales.green["500"],
  successLight: colorScales.green["400"],
  successLighter: colorScales.green["200"],
  successDark: colorScales.green["600"],
  successDarker: colorScales.green["800"],
  successContrast: "#FFFFFF"
}, colorScales, {
  dataScales: dataScales,
  facebook: "#3b5998",
  facebookDark: "#273F71",
  facebookLight: "#4F73C0",
  twitter: "#00aced",
  twitterDark: "#0073A2",
  twitterLight: "#20C1FE"
});

export default colors;