import React, { useState } from "react";
import styled from "styled-components";
import Div from "@ds-kit/div";
import { Grid, GridItem } from "@ds-kit/grid";
import Textfield from "@ds-kit/textfield";
import { Heading, Span } from "@ds-kit/typography";
import ChartFooter from "@ds-kit/chart-footer";
import Download from "./Download";
import InternalTable from "./Table";
var Container = styled.div.withConfig({
  displayName: "src__Container",
  componentId: "sc-3bk8wu-0"
})(["", ""], function (p) {
  return p.fontSize && "font-size: " + p.fontSize;
});

var Table = function Table(_ref) {
  var _ref$data = _ref.data,
      data = _ref$data === void 0 ? [] : _ref$data,
      _ref$columns = _ref.columns,
      columns = _ref$columns === void 0 ? [] : _ref$columns,
      _ref$pageSize = _ref.pageSize,
      pageSize = _ref$pageSize === void 0 ? 10 : _ref$pageSize,
      fontSize = _ref.fontSize,
      title = _ref.title,
      subtitle = _ref.subtitle,
      height = _ref.height,
      paddingHorizontal = _ref.paddingHorizontal,
      _ref$chartFooter = _ref.chartFooter,
      chartFooter = _ref$chartFooter === void 0 ? {} : _ref$chartFooter,
      _ref$enableDownload = _ref.enableDownload,
      enableDownload = _ref$enableDownload === void 0 ? false : _ref$enableDownload,
      _ref$isEditable = _ref.isEditable,
      isEditable = _ref$isEditable === void 0 ? false : _ref$isEditable,
      _ref$enableSearch = _ref.enableSearch,
      enableSearch = _ref$enableSearch === void 0 ? false : _ref$enableSearch,
      _ref$hideTableWithout = _ref.hideTableWithoutSearchString,
      hideTableWithoutSearchString = _ref$hideTableWithout === void 0 ? false : _ref$hideTableWithout,
      _ref$isBlockViewOnMob = _ref.isBlockViewOnMobile,
      isBlockViewOnMobile = _ref$isBlockViewOnMob === void 0 ? true : _ref$isBlockViewOnMob,
      _ref$backgroundColor = _ref.backgroundColor,
      backgroundColor = _ref$backgroundColor === void 0 ? "#f5f5f5" : _ref$backgroundColor,
      _ref$cellWidth = _ref.cellWidth,
      cellWidth = _ref$cellWidth === void 0 ? "150px" : _ref$cellWidth,
      _ref$updateData = _ref.updateData,
      updateData = _ref$updateData === void 0 ? function () {} : _ref$updateData;

  var _useState = useState(""),
      searchString = _useState[0],
      setSearchString = _useState[1];

  var handleSearchInput = function handleSearchInput(e) {
    return setSearchString(e.target.value);
  };

  return React.createElement(Container, {
    fontSize: fontSize
  }, React.createElement(Div, {
    textAlign: "center"
  }, React.createElement(Heading, {
    as: "h5",
    size: "sm",
    my: "0"
  }, title), React.createElement(Span, null, subtitle), enableSearch && React.createElement(Textfield, {
    onChange: handleSearchInput
  })), React.createElement(InternalTable, {
    data: data,
    columns: columns,
    infinite: false,
    isEditable: isEditable,
    pageSize: pageSize,
    updateData: updateData,
    searchString: searchString,
    hideTableWithoutSearchString: hideTableWithoutSearchString,
    backgroundColor: backgroundColor,
    height: height,
    paddingHorizontal: paddingHorizontal,
    isBlockViewOnMobile: isBlockViewOnMobile,
    cellWidth: cellWidth
  }), React.createElement(Grid, {
    gridColumnGap: ["0.25rem", "0.5rem"]
  }, chartFooter.sourceText && React.createElement(GridItem, {
    gridColumn: "span " + (enableDownload ? 11 : 12)
  }, React.createElement(ChartFooter, chartFooter)), enableDownload && React.createElement(GridItem, {
    gridColumn: "span 1",
    space: "end"
  }, React.createElement(Download, {
    data: data,
    columns: columns
  }))));
};

export default Table;