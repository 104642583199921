import staticQueryData from "../../../public/static/d/2984923512.json";

/* eslint-disable import/no-duplicates */
import React, { useState } from "react";
import TextField from "../../../packages/Textfield/src";
import Div from "../../../packages/Div/src";
import Category from "./Category";
import Design from "./Design";
var designLinks = [// { slug: "/getting-started", label: "Global styles" },
{
  slug: "/typography",
  label: "Typography"
}, {
  slug: "/colors",
  label: "Colors"
}, {
  slug: "/chart-colors",
  label: "Chart colors"
}, {
  slug: "/icons",
  label: "Icons"
}, // { slug: "/getting-started", label: "Charts" },
{
  slug: "/templates",
  label: "Templates"
}];
var categories = ["general", "cards", "layout", "control", "charts", "image", "overlay", "utils"]; // &::-webkit-scrollbar {
//   width: 0.25rem;
// }
// &::-webkit-scrollbar-track {
//   background-color: ${themeGet("colors.grey.50")};
// }
// &::-webkit-scrollbar-thumb {
//   background-color: ${themeGet("colors.grey.200")};
//   border-radius: 0.5rem;
//   box-shadow: 0 0 0 0.125rem #fff;
// }
// &::-webkit-scrollbar-thumb:hover {
//   background-color: ${themeGet("colors.grey.400")};
// }

var Sidebar = function Sidebar(_ref) {
  var sidebarType = _ref.sidebarType,
      activeItem = _ref.activeItem;

  var _useState = useState(""),
      searchValue = _useState[0],
      setSearchValue = _useState[1];

  var handleSearchValue = function handleSearchValue(e) {
    return setSearchValue(e.target.value);
  };

  var data = staticQueryData.data;
  return React.createElement(Div, {
    as: "nav",
    pt: "1.25rem",
    pb: "2.5rem",
    height: "100vh",
    position: "sticky",
    top: "0",
    mx: "-1rem",
    px: "1rem",
    style: {
      overflowY: "scroll"
    }
  }, React.createElement(TextField, {
    width: "100%",
    onChange: handleSearchValue,
    placeholder: sidebarType === "components" ? "Search for a component..." : "Search..."
  }), sidebarType === "design" && React.createElement(Design, {
    designLinks: designLinks,
    searchValue: searchValue,
    activeItemSlug: activeItem
  }), sidebarType === "components" && data.allMdx.edges.length && categories.map(function (i) {
    return React.createElement(Category, {
      key: i,
      element: i,
      edges: data.allMdx.edges,
      searchValue: searchValue,
      activeItemName: activeItem
    });
  }));
};

export default Sidebar;