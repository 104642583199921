import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable react/prop-types */

/* eslint-disable no-unused-vars */
import React, { forwardRef, useContext, createContext } from "react";
import styled from "styled-components";
import { borderRadius, boxShadow } from "styled-system";
import { useMenuState, Menu as BaseMenu, MenuItem as BaseMenuItem, MenuDisclosure as BaseMenuDisclosure } from "reakit/Menu";
import { filterProps } from "../../../src/utils/filterProps";
var MenuContext = createContext({});
var MenuWrapper = styled.div.withConfig({
  displayName: "src__MenuWrapper",
  componentId: "sc-1420f0i-0"
})(["display:inline-block;vertical-align:middle;"]);
export var Menu = function Menu(_ref) {
  var children = _ref.children,
      _ref$gutter = _ref.gutter,
      gutter = _ref$gutter === void 0 ? 4 : _ref$gutter,
      visible = _ref.visible;
  var menu = useMenuState({
    visible: visible,
    unstable_gutter: gutter
  });
  return React.createElement(MenuContext.Provider, {
    value: menu
  }, React.createElement(MenuWrapper, null, children));
};
export var MenuDisclosure = function MenuDisclosure(props) {
  var menu = useContext(MenuContext);
  return React.createElement(BaseMenuDisclosure, Object.assign({}, menu, props));
};
var StyledMenu = styled(function (props) {
  var filteredProps = filterProps(props, ["boxShadow", "borderRadius"]);
  return React.createElement(BaseMenu, filteredProps);
}).withConfig({
  displayName: "src__StyledMenu",
  componentId: "sc-1420f0i-1"
})(["", " ", " background:#FFFFFF;border:0.0625rem solid #eee;outline:none;z-index:999;"], borderRadius, boxShadow);
var Caret = styled("div").withConfig({
  displayName: "src__Caret",
  componentId: "sc-1420f0i-2"
})(["position:absolute;", ";left:1.25rem;transform:", ";"], function (p) {
  return p.placement === "bottom-start" ? "top: -0.75rem" : "bottom: -0.75rem";
}, function (p) {
  return p.placement === "bottom-start" ? "translateY(-100%)" : "translateY(100%)";
});
export var MenuPanel = function MenuPanel(_ref2) {
  var children = _ref2.children,
      props = _objectWithoutPropertiesLoose(_ref2, ["children"]);

  var menu = useContext(MenuContext);
  return React.createElement(React.Fragment, null, React.createElement(StyledMenu, Object.assign({
    "aria-label": "menu",
    boxShadow: "sm",
    borderRadius: "sm"
  }, menu, props), React.createElement("div", {
    style: {
      position: "relative",
      overflow: "visible"
    }
  }, React.createElement(Caret, {
    placement: menu.placement
  }, React.createElement("svg", {
    viewBox: "0 0 20 10",
    style: {
      width: "1.5rem",
      display: "block"
    }
  }, React.createElement("path", {
    d: menu.placement === "bottom-start" ? "M0,10 L10,1 L20,10" : "M0,0 L10,9 L20,0",
    stroke: "#EEE",
    fill: "#FFF",
    strokeWidth: 1,
    strokeLinejoin: "round"
  }))), React.createElement("div", {
    style: {
      overflow: "hidden",
      margin: "0.75rem 0"
    }
  }, children))));
};
var StyledMenuItem = styled(forwardRef(function (_ref3, ref) {
  var is = _ref3.is,
      props = _objectWithoutPropertiesLoose(_ref3, ["is"]);

  return React.createElement(BaseMenuItem, Object.assign({
    as: is,
    ref: ref
  }, props));
})).withConfig({
  displayName: "src__StyledMenuItem",
  componentId: "sc-1420f0i-3"
})(["display:block;text-align:left;padding:0.75rem 1.25rem;font-family:inherit;font-size:inherit;width:100%;border:0;background:transparent;outline:none;&:hover,&:focus{background:rgba(0,0,0,0.05);}"]);
export var MenuItem = function MenuItem(props) {
  var menu = useContext(MenuContext);
  return React.createElement(StyledMenuItem, Object.assign({}, menu, props, {
    onClick: function onClick(evt) {
      if (props.onClick) props.onClick(evt, menu);
    }
  }));
};