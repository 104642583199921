import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import Avatar from "@ds-kit/avatar";
import Card from "@ds-kit/card";
import { Heading, P } from "@ds-kit/typography";
import Flex from "@ds-kit/flex";
import Div from "@ds-kit/div";

var AuthorCard = function AuthorCard(_ref) {
  var raised = _ref.raised,
      interactive = _ref.interactive,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "inherit" : _ref$color,
      alt = _ref.alt,
      src = _ref.src,
      title = _ref.title,
      description = _ref.description,
      date = _ref.date,
      bg = _ref.bg,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? ["3.5rem", "4rem"] : _ref$size,
      restProps = _objectWithoutPropertiesLoose(_ref, ["raised", "interactive", "color", "alt", "src", "title", "description", "date", "bg", "size"]);

  return React.createElement(Card, Object.assign({
    bg: bg,
    color: color,
    raised: raised,
    interactive: interactive,
    px: bg || raised ? restProps.px || "1rem" : 0,
    py: bg || raised ? restProps.py || "0.75rem" : 0,
    borderRadius: "md",
    display: "inline-block"
  }, restProps), React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Div, null, React.createElement(Avatar, {
    size: size,
    src: src,
    alt: alt || title
  })), React.createElement(Div, {
    pl: "0.75rem"
  }, title && React.createElement(Heading, {
    size: "sm",
    my: "0"
  }, title), description && React.createElement(P, {
    muted: true,
    size: "xs",
    lineHeight: "dense",
    mt: "0.125rem",
    mb: "0"
  }, description), date && React.createElement(P, {
    muted: true,
    size: "xs",
    lineHeight: "dense",
    mt: "0.125rem",
    mb: "0"
  }, date))));
};

export default AuthorCard;