import React from "react";

var BeeswarmSymbol = function BeeswarmSymbol(_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? 20 : _ref$size;
  return React.createElement("div", {
    style: {
      borderRadius: size / 2,
      backgroundColor: "#0a4",
      width: size,
      height: size
    }
  });
};

export default BeeswarmSymbol;