import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import { Card } from "@ds-kit/card";
import Flex from "@ds-kit/flex";
import Div from "@ds-kit/div";
import RatioContainer from "@ds-kit/ratio-container";
import { Heading, P } from "@ds-kit/typography";

var LargeTopicCard = function LargeTopicCard(_ref) {
  var _ref$seo = _ref.seo,
      seo = _ref$seo === void 0 ? {} : _ref$seo,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? {} : _ref$color,
      _ref$iconSquare = _ref.iconSquare,
      iconSquare = _ref$iconSquare === void 0 ? {} : _ref$iconSquare,
      count = _ref.count,
      props = _objectWithoutPropertiesLoose(_ref, ["seo", "color", "iconSquare", "count"]);

  return React.createElement(Card, Object.assign({
    raised: true,
    interactive: true,
    borderRadius: "lg",
    overflow: "hidden"
  }, props), React.createElement(Flex, null, React.createElement(Div, {
    width: [1 / 3, null, 1 / 2],
    bg: color.hex
  }, React.createElement(RatioContainer, {
    ratio: [2 / 1, 1.25 / 1, 1],
    bg: "transparent"
  }, React.createElement(Div, {
    width: "100%",
    height: "100%",
    p: "1.25rem",
    overflow: "hidden"
  }, React.createElement("img", {
    src: iconSquare.url,
    alt: seo.title,
    style: {
      display: "block",
      width: "auto",
      height: "100%"
    }
  })))), React.createElement(Div, {
    width: [2 / 3, null, 1 / 2],
    p: "1.25rem",
    textAlign: "center",
    alignSelf: "center"
  }, React.createElement(P, {
    muted: true,
    lineHeight: "dense",
    mb: "1.25rem"
  }, "Topic"), React.createElement(Heading, {
    size: "xl",
    my: "0.5rem"
  }, "Swedish Media"), React.createElement(P, {
    muted: true,
    lineHeight: "dense",
    mb: "0"
  }, "Topic description or subtitle"), count && React.createElement(P, {
    muted: true,
    lineHeight: "dense",
    mt: "1.25rem",
    mb: "0"
  }, count + " apps"))));
};

var MediumTopicCard = function MediumTopicCard(_ref2) {
  var _ref2$seo = _ref2.seo,
      seo = _ref2$seo === void 0 ? {} : _ref2$seo,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? {} : _ref2$color,
      _ref2$iconSquare = _ref2.iconSquare,
      iconSquare = _ref2$iconSquare === void 0 ? {} : _ref2$iconSquare,
      count = _ref2.count,
      props = _objectWithoutPropertiesLoose(_ref2, ["seo", "color", "iconSquare", "count"]);

  return React.createElement(Card, Object.assign({
    borderRadius: "lg",
    overflow: "hidden",
    color: "#FFFFFF",
    bg: color.hex,
    height: "100%"
  }, props), React.createElement(RatioContainer, {
    ratio: 1,
    bg: "transparent"
  }, React.createElement(Div, {
    width: "100%",
    height: "100%",
    p: "1.5rem"
  }, React.createElement("img", {
    src: iconSquare.url,
    alt: seo.title,
    style: {
      display: "block",
      width: "100%",
      height: "auto"
    }
  }))), React.createElement(Div, {
    textAlign: "center",
    px: "1.25rem",
    pb: "2rem"
  }, React.createElement(Heading, {
    size: "lg",
    my: "0"
  }, seo.title), count && React.createElement(P, {
    muted: true,
    lineHeight: "dense",
    size: "xs",
    mt: "0.25rem",
    mb: "0"
  }, count + " apps")));
};

var SmallTopicCard = function SmallTopicCard(_ref3) {
  var _ref3$seo = _ref3.seo,
      seo = _ref3$seo === void 0 ? {} : _ref3$seo,
      _ref3$color = _ref3.color,
      color = _ref3$color === void 0 ? {} : _ref3$color,
      _ref3$iconSquare = _ref3.iconSquare,
      iconSquare = _ref3$iconSquare === void 0 ? {} : _ref3$iconSquare,
      count = _ref3.count,
      props = _objectWithoutPropertiesLoose(_ref3, ["seo", "color", "iconSquare", "count"]);

  return React.createElement(Card, Object.assign({
    borderRadius: "md",
    overflow: "hidden",
    color: "#FFFFFF",
    bg: color.hex
  }, props), React.createElement(Flex, {
    alignItems: "center",
    flexWrap: "nowrap"
  }, React.createElement(Div, {
    flex: "none",
    width: "6rem",
    p: "0.75rem"
  }, React.createElement("img", {
    src: iconSquare.url,
    alt: seo.title,
    style: {
      display: "block",
      width: "100%",
      height: "auto"
    }
  })), React.createElement(Div, {
    flex: "1 1 auto",
    py: "1.25rem",
    px: "1.25rem"
  }, React.createElement(Heading, {
    size: "md",
    my: "0"
  }, seo.title), count && React.createElement(P, {
    muted: true,
    lineHeight: "dense",
    size: "xs",
    mb: "0"
  }, count + " apps"))));
};

var TopicCard = function TopicCard(_ref4) {
  var size = _ref4.size,
      props = _objectWithoutPropertiesLoose(_ref4, ["size"]);

  return React.createElement(React.Fragment, null, size === "sm" && React.createElement(SmallTopicCard, props), size === "md" && React.createElement(MediumTopicCard, props), size === "lg" && React.createElement(LargeTopicCard, props));
};

TopicCard.defaultProps = {
  size: "md"
};
export default TopicCard;