import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { borderColor, system, borderRadius, color, space, boxShadow } from "styled-system";
var switchHeight = system({
  size: {
    property: "height",
    scale: "switchHeights",
    defaultScale: {
      sm: "1.5rem",
      md: "2rem",
      lg: "2.5rem"
    }
  }
});
var switchWidth = system({
  size: {
    property: "width",
    scale: "switchWidths",
    defaultScale: {
      sm: "3rem",
      md: "4rem",
      lg: "5rem"
    }
  }
});
var activeBg = system({
  activeBg: {
    property: "background",
    scale: "colors"
  }
});
var activeColor = system({
  activeColor: {
    property: "background",
    scale: "colors"
  }
});
var StyledSwitch = styled("div").attrs(function (props) {
  return {
    boxShadow: props.raised ? "sm" : "none"
  };
}).withConfig({
  displayName: "src__StyledSwitch",
  componentId: "hdfuds-0"
})(["display:inline-flex;vertical-align:middle;flex-shrink:0;align-items:center;position:relative;", " ", " ", " > input:checked + label{", "}> input:checked + label + div{left:100%;transform:translateX(-100%);> div{", " ", "}}> input:focus + label::after,> input:active + label::after{content:\"\";position:absolute;top:-0.25rem;left:-0.25rem;right:-0.25rem;bottom:-0.25rem;background:transparent;pointer-events:none;border:0.25rem solid;opacity:0.3;", " ", "}"], switchWidth, switchHeight, borderRadius, activeBg, activeColor, boxShadow, borderRadius, borderColor);
var SwitchInput = styled.input.withConfig({
  displayName: "src__SwitchInput",
  componentId: "hdfuds-1"
})(["position:absolute;left:0;top:0;margin:0;padding:0;opacity:0;height:0;width:0;pointer-events:none;outline:none;"]);
var SwitchLabel = styled.label.withConfig({
  displayName: "src__SwitchLabel",
  componentId: "hdfuds-2"
})(["position:absolute;left:0;top:0;width:100%;height:100%;color:transparent;user-select:none;", " border-radius:inherit;z-index:1;transition:background 200ms;"], color);
var markerSize = system({
  size: {
    properties: ["width", "height"],
    scale: "switchHeights",
    defaultScale: {
      sm: "1.5rem",
      md: "2rem",
      lg: "2.5rem"
    }
  }
});
var SwitchMarker = styled("div").withConfig({
  displayName: "src__SwitchMarker",
  componentId: "hdfuds-3"
})(["position:relative;", " ", " ", " z-index:2;left:0;pointer-events:none;transition:left 200ms,transform 200ms;will-change:left;"], color, markerSize, space);
var Thumb = styled("div").withConfig({
  displayName: "src__Thumb",
  componentId: "hdfuds-4"
})(["width:100%;height:100%;", " ", ""], borderRadius, color);

var Switch = function Switch(_ref) {
  var name = _ref.name,
      _ref$label = _ref.label,
      label = _ref$label === void 0 ? "On" : _ref$label,
      checked = _ref.checked,
      _onChange = _ref.onChange,
      _ref$offBg = _ref.offBg,
      offBg = _ref$offBg === void 0 ? "grey.100" : _ref$offBg,
      _ref$onBg = _ref.onBg,
      onBg = _ref$onBg === void 0 ? "primary" : _ref$onBg,
      _ref$onColor = _ref.onColor,
      onColor = _ref$onColor === void 0 ? "white" : _ref$onColor,
      _ref$offColor = _ref.offColor,
      offColor = _ref$offColor === void 0 ? "white" : _ref$offColor,
      outlineColor = _ref.outlineColor,
      _ref$borderRadius = _ref.borderRadius,
      borderRadius = _ref$borderRadius === void 0 ? "pill" : _ref$borderRadius,
      _ref$p = _ref.p,
      p = _ref$p === void 0 ? "0.1875rem" : _ref$p,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      _ref$thumbBorderRadiu = _ref.thumbBorderRadius,
      thumbBorderRadius = _ref$thumbBorderRadiu === void 0 ? "circle" : _ref$thumbBorderRadiu,
      restProps = _objectWithoutPropertiesLoose(_ref, ["name", "label", "checked", "onChange", "offBg", "onBg", "onColor", "offColor", "outlineColor", "borderRadius", "p", "size", "thumbBorderRadius"]);

  return React.createElement(StyledSwitch, Object.assign({
    activeBg: onBg,
    activeColor: onColor,
    size: size,
    borderRadius: borderRadius,
    borderColor: outlineColor || onBg,
    p: p
  }, restProps), React.createElement(SwitchInput, {
    type: "checkbox",
    id: name,
    checked: checked,
    onChange: function onChange(evt) {
      return _onChange(evt);
    }
  }), React.createElement(SwitchLabel, {
    ariaHidden: "true",
    htmlFor: name,
    bg: offBg
  }, label), React.createElement(SwitchMarker, {
    ariaHidden: "true",
    size: size,
    p: p
  }, React.createElement(Thumb, {
    bg: offColor,
    borderRadius: thumbBorderRadius
  })));
};

export default Switch;