import "core-js/modules/es6.object.assign";
import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
var StyledOption = styled.div.withConfig({
  displayName: "Option__StyledOption",
  componentId: "sc-12le0kp-0"
})(["display:flex;align-items:start;padding:0.5em 0.75rem;cursor:default;color:", ";background-color:", ";", " > svg{display:inline-block;vertical-align:middle;margin-right:0.25rem;flex:none;}> span{line-height:1.25;", "}"], function (p) {
  return p.isSelected ? themeGet("colors.blurble.500")(p) : "currentcolor";
}, function (p) {
  return p.isFocused ? themeGet("colors.grey.50")(p) : "transparent";
}, ""
/* pointer-events: ${p => p.isSelected ? "none" : "all"}; */
, function (p) {
  return p.isVirtualized ? "white-space: nowrap; text-overflow: ellipsis; overflow-x: hidden;" : "";
});

var Option = function Option(_ref) {
  var children = _ref.children,
      innerProps = _ref.innerProps,
      isSelected = _ref.isSelected,
      isFocused = _ref.isFocused,
      isVirtualized = _ref.isVirtualized,
      selectProps = _ref.selectProps;
  return React.createElement(StyledOption, Object.assign({}, innerProps, {
    isSelected: isSelected,
    isFocused: isFocused,
    isVirtualized: isVirtualized
  }), selectProps.isMulti && (isSelected ? React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "20",
    height: "20",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "2",
    strokeLinecap: "square",
    strokeLinejoin: "arcs"
  }, React.createElement("polyline", {
    points: "9 11 12 14 22 4"
  }), React.createElement("path", {
    d: "M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
  })) : React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "20",
    height: "20",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "2",
    strokeLinecap: "square",
    strokeLinejoin: "arcs"
  }, React.createElement("rect", {
    x: "3",
    y: "3",
    width: "18",
    height: "18",
    rx: "2",
    ry: "2"
  }))), React.createElement("span", null, children));
};

export default Option;