import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { color, layout } from "styled-system";
var StyledProgress = styled("progress").withConfig({
  displayName: "src__StyledProgress",
  componentId: "sc-8z22dl-0"
})(["-webkit-appearance:none;appearance:none;position:fixed;position:sticky;z-index:99999;top:0;left:0;width:100%;pointer-events:none;display:block;", ";", ";&::-webkit-progress-bar{background-color:transparent;}&::-webkit-progress-value{background-color:currentColor;}&::-moz-progress-bar{background-color:currentColor;}"], color, layout);

var calculateValue = function calculateValue(_ref) {
  var top = _ref.top,
      height = _ref.height,
      window = _ref.window;
  if (top >= 0) return 0;
  return 100 * (0 - top) / (height - window);
};

var getProgress = function getProgress(indicatorContent) {
  return {
    height: indicatorContent.offsetHeight,
    window: window.innerHeight,
    top: indicatorContent.getBoundingClientRect().top
  };
};

var ReadingProgressBar = function ReadingProgressBar(_ref2) {
  var _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? "#511ee9" : _ref2$color,
      _ref2$height = _ref2.height,
      height = _ref2$height === void 0 ? "5px" : _ref2$height,
      children = _ref2.children;

  var _useState = useState(0),
      indicatorValue = _useState[0],
      setIndicatorValue = _useState[1];

  var content = useRef(null);
  var isProcessed = useRef(false);

  var setProgressIndicator = function setProgressIndicator() {
    isProcessed.current = false;
    var indicatorContent = content.current;
    var progress = getProgress(indicatorContent);
    if (progress.height <= progress.window) return;
    var value = calculateValue(progress);
    setIndicatorValue(value);
  };

  useEffect(function () {
    if (typeof window !== undefined) {
      var handleScroll = function handleScroll() {
        if (isProcessed.current) return;
        isProcessed.current = true;

        if (window.requestAnimationFrame) {
          return window.requestAnimationFrame(setProgressIndicator);
        }

        setTimeout(setProgressIndicator, 250);
      };

      var handleResize = function handleResize() {
        if (isProcessed.current) return;
        isProcessed.current = true;

        if (window.requestAnimationFrame) {
          return window.requestAnimationFrame(setProgressIndicator);
        }

        setTimeout(setProgressIndicator, 250);
      };

      window.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
      return function () {
        window.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  return React.createElement("div", {
    ref: content
  }, React.createElement(StyledProgress, {
    "aria-hidden": "true",
    max: "100",
    color: color,
    height: height,
    value: indicatorValue
  }), children);
};

export default ReadingProgressBar;