import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import React from 'react';
import styled, { css } from 'styled-components';
import { display, gridTemplateColumns, gridTemplateRows, gridColumnGap, gridRowGap, alignItems, space, color, width, gridColumn, gridRow, alignSelf } from 'styled-system';
import 'core-js/modules/es6.regexp.split';
import css$1 from '@styled-system/css';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

function _templateObject2() {
  var data = _taggedTemplateLiteralLoose(["\n      display: flex;\n      flex-wrap: wrap;\n      @supports(display: grid) {\n        flex-wrap: unset;\n        ", "\n        ", "\n        ", "\n        ", "\n        ", "\n      }\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  max-width: 100%;\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledGrid = styled.div.withConfig({
  displayName: "indexes__StyledGrid",
  componentId: "sc-1ip3veu-0"
})(_templateObject(), function () {
  return css(_templateObject2(), display, gridTemplateColumns, gridTemplateRows, gridColumnGap, gridRowGap);
}, alignItems, space, color);

var Grid = function Grid(props) {
  return React.createElement(StyledGrid, props);
};

Grid.defaultProps = {
  display: "grid",
  gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
  gridTemplateRows: "auto",
  gridColumnGap: ["1.25rem", "1.5rem"],
  gridRowGap: ["1.25rem", "1.5rem"]
};

function _templateObject$1() {
  var data = _taggedTemplateLiteralLoose(["\n  ", "\n  @supports(display: grid) {\n    width: auto;\n    ", "\n  }\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject$1 = function _templateObject() {
    return data;
  };

  return data;
}

var StyledGridItem = styled.div.withConfig({
  displayName: "indexes__StyledGridItem",
  componentId: "sc-1ip3veu-1"
})(_templateObject$1(), function (p) {
  if (!p.gridColumn) return "";
  var gc = typeof p.gridColumn === "string" ? [p.gridColumn] : p.gridColumn;
  var vals = gc.reduce(function (acc, cur) {
    if (!cur) return acc;
    acc.push(+cur.split("span")[1] * (100 / 12) + "%");
    return acc;
  }, []);
  return css$1(width({
    width: vals
  }));
}, gridColumn, display, gridRow, alignSelf, space, color);

var GridItem = function GridItem(props) {
  return React.createElement(StyledGridItem, props);
};

GridItem.defaultProps = {
  gridColumn: "span 1"
};
export { Grid, GridItem };