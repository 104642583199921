import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.object.assign";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable import/max-dependencies */
import React, { Fragment } from "react";
import { useTable, useColumns, useRows, useGroupBy, useFilters, useSortBy, useExpanded, usePagination, useFlexLayout } from "react-table";
import Textfield from "@ds-kit/textfield";
import Button from "@ds-kit/button";
import Div from "@ds-kit/div";
import Icon from "@ds-kit/icon";
import { Span } from "@ds-kit/typography";
import { Row, Cell, Divider } from "./Components";
import HeaderCell from "./HeaderCell";
import { getColumnsWithFilter, rowsAfterProcessSearchСriterion } from "./utils";
export default function MyTable(_ref) {
  var pageSize = _ref.pageSize,
      isEditable = _ref.isEditable,
      searchString = _ref.searchString,
      hideTableWithoutSearchString = _ref.hideTableWithoutSearchString,
      backgroundColor = _ref.backgroundColor,
      columns = _ref.columns,
      height = _ref.height,
      paddingHorizontal = _ref.paddingHorizontal,
      isBlockViewOnMobile = _ref.isBlockViewOnMobile,
      cellWidth = _ref.cellWidth,
      props = _objectWithoutPropertiesLoose(_ref, ["pageSize", "isEditable", "searchString", "hideTableWithoutSearchString", "backgroundColor", "columns", "height", "paddingHorizontal", "isBlockViewOnMobile", "cellWidth"]);

  var columnsWithFilter = getColumnsWithFilter(columns);
  var instance = useTable(_objectSpread({}, props, {
    columns: columnsWithFilter
  }), useColumns, useRows, useGroupBy, useFilters, useSortBy, useExpanded, usePagination, useFlexLayout);
  var getTableProps = instance.getTableProps,
      headerGroups = instance.headerGroups,
      pageOptions = instance.pageOptions,
      page = instance.page,
      _instance$state = instance.state,
      _instance$state$ = _instance$state[0],
      pageIndex = _instance$state$.pageIndex,
      choosenPageSize = _instance$state$.pageSize,
      gotoPage = instance.gotoPage,
      prepareRow = instance.prepareRow,
      previousPage = instance.previousPage,
      nextPage = instance.nextPage,
      canPreviousPage = instance.canPreviousPage,
      canNextPage = instance.canNextPage,
      setPageSize = instance.setPageSize;
  if (choosenPageSize !== pageSize) setPageSize(pageSize);

  var renderRow = function renderRow(row, index) {
    if (!row) {
      return React.createElement(Row, {
        even: index % 2
      }, React.createElement(Cell, {
        backgroundColor: backgroundColor
      }, "Loading more..."));
    }

    prepareRow(row);
    return React.createElement(Fragment, {
      key: index
    }, React.createElement(Row, {
      even: index % 2,
      isBlockViewOnMobile: isBlockViewOnMobile
    }, rowsAfterProcessSearchСriterion({
      row: row,
      hideTableWithoutSearchString: hideTableWithoutSearchString,
      searchString: searchString
    }) && row.cells.map(function (cell, cellIndex) {
      var isPivot = row.groupByID === cell.column.id;
      var showAggregate = row.subRows && !isPivot;
      var cellProps = cell.getCellProps();
      delete cellProps.style.display;
      return React.createElement(Fragment, {
        key: cellProps.key
      }, isBlockViewOnMobile && React.createElement(Div, {
        display: ["block", "none"]
      }, React.createElement(HeaderCell, {
        column: headerGroups[0].headers[cellIndex],
        isBlockViewOnMobile: isBlockViewOnMobile,
        cellWidth: cellWidth
      })), React.createElement(Cell, Object.assign({
        paddingHorizontal: paddingHorizontal,
        height: height,
        backgroundColor: backgroundColor,
        isBlockViewOnMobile: isBlockViewOnMobile,
        cellWidth: cellWidth
      }, cellProps, {
        key: cellProps.key,
        isEven: index % 2 === 0,
        onBlur: function onBlur(e) {
          if (!isEditable) return;
          props.data[row.index][cell.column.id] = e.target.innerHTML;
          props.updateData(props.data);
        }
      }), showAggregate ? cell.column.aggregate && cell.render("Aggregated") : React.createElement("span", {
        contentEditable: isEditable
      }, cell.render("Cell"), isPivot && React.createElement("span", null, " (", row.subRows.length, ")"))));
    })), React.createElement(Divider, {
      isBlockViewOnMobile: isBlockViewOnMobile
    }));
  };

  var tableBody = page && page.length && page.map(function (row, i) {
    return renderRow(row, i);
  });
  return React.createElement("div", null, React.createElement(Div, Object.assign({
    display: "block",
    overflow: "scroll"
  }, getTableProps()), headerGroups.map(function (headerGroup) {
    var key = headerGroup.getRowProps().key; // eslint-disable-next-line

    return isBlockViewOnMobile ? React.createElement(Div, {
      display: ["none", "block"],
      key: key
    }, React.createElement(Row, headerGroup.getRowProps(), headerGroup.headers.map(function (column) {
      return React.createElement(HeaderCell, {
        key: column.id,
        column: column,
        cellWidth: cellWidth
      });
    }))) : React.createElement(Row, Object.assign({}, headerGroup.getRowProps(), {
      key: key
    }), headerGroup.headers.map(function (column) {
      return React.createElement(HeaderCell, {
        key: column.id,
        column: column,
        cellWidth: cellWidth
      });
    }));
  }), tableBody), React.createElement(Div, {
    textAlign: "center",
    pt: "1rem"
  }, React.createElement(Button, {
    mr: "1rem",
    px: "0.625rem",
    onClick: function onClick() {
      return previousPage();
    },
    disabled: !canPreviousPage
  }, React.createElement(Icon, {
    name: "chevron_left"
  })), React.createElement(Span, null, React.createElement(Textfield, {
    type: "number",
    width: "3rem",
    px: "4px",
    value: pageIndex + 1,
    onChange: function onChange(e) {
      var page = e.target.value ? Number(e.target.value) - 1 : 0;
      gotoPage(page);
    }
  })), React.createElement(Span, {
    ml: "0.2rem"
  }, "/", pageOptions.length), React.createElement(Button, {
    ml: "1rem",
    px: "0.625rem",
    onClick: function onClick() {
      return nextPage();
    },
    disabled: !canNextPage
  }, React.createElement(Icon, {
    name: "chevron_right"
  }))));
}