import "core-js/modules/es6.object.assign";
import React from "react";
import styled from "styled-components";
import { color, height, boxShadow, borderColor, borderRadius, system } from "styled-system";
import { sizes } from "./utils";
var shadowColor = system({
  shadowColor: {
    property: "color",
    scale: "colors"
  }
});
var StyledControl = styled.div.withConfig({
  displayName: "Control__StyledControl",
  componentId: "sc-50v2lo-0"
})(["position:relative;display:flex;align-items:center;border-radius:0.1875rem;", " ", " ", " border:0.125rem solid;", " ", " input{font-family:inherit;font-size:inherit;}&::after{content:\"\";position:absolute;top:-0.125rem;left:-0.125rem;right:-0.125rem;bottom:-0.125rem;", " box-shadow:0 0 0 0.25rem currentcolor;pointer-events:none;opacity:0.3;", "}"], color, height, borderRadius, borderColor, boxShadow, shadowColor, borderRadius);

var Control = function Control(_ref) {
  var children = _ref.children,
      innerProps = _ref.innerProps,
      isFocused = _ref.isFocused,
      selectProps = _ref.selectProps;
  var isRaised = selectProps.raised;
  return React.createElement(StyledControl, Object.assign({
    bg: selectProps.raised ? "white" : "grey.25",
    height: sizes[selectProps.size].height,
    borderColor: isFocused ? "primary" : isRaised ? "transparent" : "grey.200",
    boxShadow: isRaised ? isFocused ? "md" : "sm" : "none",
    shadowColor: isFocused ? "primary" : "transparent",
    borderRadius: "md"
  }, innerProps), children);
};

export default Control;