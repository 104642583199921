import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
var StyledDropdownIndicator = styled.div.withConfig({
  displayName: "DropdownIndicator__StyledDropdownIndicator",
  componentId: "sc-18eae8d-0"
})(["display:flex;padding:0.5rem;cursor:pointer;background:transparent;border-radius:100%;color:", ";"], themeGet("colors.grey.600"));

var DropdownIndicator = function DropdownIndicator() {
  return React.createElement(StyledDropdownIndicator, null, React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "20",
    height: "20",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "3",
    strokeLinecap: "square",
    strokeLinejoin: "arcs"
  }, React.createElement("circle", {
    cx: "11",
    cy: "11",
    r: "8"
  }), React.createElement("line", {
    x1: "21",
    y1: "21",
    x2: "16.65",
    y2: "16.65"
  })));
};

export default DropdownIndicator;