import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
var StyledValueContainer = styled.div.withConfig({
  displayName: "ValueContainer__StyledValueContainer",
  componentId: "h8t4tm-0"
})(["display:flex;align-items:center;flex:1;padding:0.125rem 0.75rem;-webkit-overflow-scrolling:touch;position:relative;overflow:hidden;"]);
var HiddenValueIndicator = styled.span.withConfig({
  displayName: "ValueContainer__HiddenValueIndicator",
  componentId: "h8t4tm-1"
})(["display:inline-block;white-space:nowrap;vertical-align:middle;font-weight:600;font-size:0.875rem;color:", ";"], themeGet("colors.grey.600"));

var ValueContainer = function ValueContainer(_ref) {
  var children = _ref.children,
      selectProps = _ref.selectProps;
  var visibleOptionsCount = selectProps.visibleOptionsCount;
  var len = selectProps.value ? selectProps.value.length : null;
  return React.createElement(StyledValueContainer, null, children, React.createElement(HiddenValueIndicator, null, len > visibleOptionsCount && "+ " + (len - visibleOptionsCount) + " more"));
};

export default ValueContainer;