import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { layout, position, space, color, textAlign, borderRadius, overflow, boxShadow, system } from "styled-system";
import { filterProps } from "../../../src/utils/filterProps";
var allPropNames = [].concat(layout.propNames, position.propNames, space.propNames, color.propNames, textAlign.propNames, borderRadius.propNames, overflow.propNames, boxShadow.propNames, ["is", "boxShadowHover", "interactive", "raised"]);
var boxShadowHover = system({
  boxShadowHover: {
    property: "box-shadow",
    scale: "shadows"
  }
});

var configureAttributes = function configureAttributes(props) {
  return {
    boxShadow: props.raised ? "sm" : null,
    boxShadowHover: props.raised && props.interactive ? "md" : null
  };
};

var StyledCard = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(props.is || "div", filteredProps);
}).attrs(configureAttributes).withConfig({
  displayName: "src__StyledCard",
  componentId: "v0grey-0"
})(["display:block;", " ", " ", " ", " ", " ", " ", " ", " transform:", ";transition:transform 250ms ease,box-shadow 250ms ease;&:hover,&:focus,a:hover &,a:focus &{", " transform:", ";}&:active,a:active &{", " transform:", ";}"], layout, position, space, color, textAlign, borderRadius, overflow, boxShadow, function (p) {
  return p.raised && p.interactive ? "translateY(0)" : null;
}, boxShadowHover, function (p) {
  return p.raised && p.interactive ? "translateY(-0.125rem)" : null;
}, boxShadow, function (p) {
  return p.raised && p.interactive ? "translateY(0)" : null;
});
export var Card = function Card(_ref) {
  var as = _ref.as,
      restProps = _objectWithoutPropertiesLoose(_ref, ["as"]);

  return React.createElement(StyledCard, Object.assign({
    is: as
  }, restProps));
};
export default Card;