import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useEffect, useContext, createContext } from "react";
import { useRadioState, RadioGroup, Radio as BaseRadioItem } from "reakit/Radio";
import styled from "styled-components";
import { color, border } from "styled-system";
import { filterProps } from "../../../src/utils/filterProps";
var RadioContext = createContext({});
var allPropNames = [].concat(color.propNames, border.propNames);
var StyledRadioGroup = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(RadioGroup, filteredProps);
}).withConfig({
  displayName: "src__StyledRadioGroup",
  componentId: "kpuwsk-0"
})(["", ";", ";"], color, border);

var Radio = function Radio(_ref) {
  var _ref$ariaLabel = _ref.ariaLabel,
      ariaLabel = _ref$ariaLabel === void 0 ? "default-aria-label" : _ref$ariaLabel,
      _ref$orientation = _ref.orientation,
      orientation = _ref$orientation === void 0 ? "horizontal" : _ref$orientation,
      _ref$borderColor = _ref.borderColor,
      borderColor = _ref$borderColor === void 0 ? "none" : _ref$borderColor,
      state = _ref.state,
      checked = _ref.checked,
      children = _ref.children;
  var radio = useRadioState({
    state: state,
    orientation: orientation
  });
  useEffect(function () {
    radio.setState(checked);
  }, [checked]);
  return React.createElement(RadioContext.Provider, {
    value: radio
  }, React.createElement(StyledRadioGroup, Object.assign({
    "aria-label": ariaLabel,
    borderColor: borderColor
  }, radio), children));
};

var RadioItem = function RadioItem(_ref2) {
  var text = _ref2.text,
      props = _objectWithoutPropertiesLoose(_ref2, ["text"]);

  var radio = useContext(RadioContext);
  return React.createElement("label", null, React.createElement(BaseRadioItem, Object.assign({}, radio, props)), text);
};

export { Radio, RadioItem };