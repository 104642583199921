import React from "react";
import styled from "styled-components";
var StyledIndicatorContainer = styled.div.withConfig({
  displayName: "IndicatorsContainer__StyledIndicatorContainer",
  componentId: "sc-1uraqjl-0"
})(["background:transparent;display:flex;align-items:center;align-self:stretch;flex-shrink:0;padding:0 0.5rem;"]);

var IndicatorsContainer = function IndicatorsContainer(_ref) {
  var children = _ref.children;
  return React.createElement(StyledIndicatorContainer, null, children);
};

export default IndicatorsContainer;