import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { layout, position, space, color, textAlign, border, overflow, flexbox, boxShadow } from "styled-system";
import { filterProps } from "../../../src/utils/filterProps";
var allPropNames = [].concat(layout.propNames, position.propNames, space.propNames, color.propNames, textAlign.propNames, border.propNames, overflow.propNames, flexbox.propNames, boxShadow.propNames, ["is"]);
var StyledDiv = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(props.is || "div", filteredProps);
}).withConfig({
  displayName: "src__StyledDiv",
  componentId: "sc-1dfqrq1-0"
})(["", " ", " ", " ", " ", " ", " ", " ", " ", ""], layout, position, space, color, textAlign, border, overflow, flexbox, boxShadow);

var Div = function Div(_ref) {
  var as = _ref.as,
      restProps = _objectWithoutPropertiesLoose(_ref, ["as"]);

  return React.createElement(StyledDiv, Object.assign({
    is: as
  }, restProps));
};

export default Div;