import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable import/max-dependencies */
import React from "react";
import Select from "react-select";
import styled from "styled-components";
import { space, width, fontSize } from "styled-system";
import { sizes, suggestions } from "./utils";
import Menu from "./Menu";
import Option from "./Option";
import Control from "./Control";
import Placeholder from "./Placeholder";
import ValueContainer from "./ValueContainer";
import ClearIndicator from "./ClearIndicator";
import MultiValueRemove from "./MultiValueRemove";
import DropdownIndicator from "./DropdownIndicator";
import IndicatorSeparator from "./IndicatorSeparator";
import IndicatorsContainer from "./IndicatorsContainer";
import MultiValueContainer from "./MultiValueContainer";
var defaultComponents = {
  Menu: Menu,
  Option: Option,
  Control: Control,
  Placeholder: Placeholder,
  ValueContainer: ValueContainer,
  ClearIndicator: ClearIndicator,
  MultiValueRemove: MultiValueRemove,
  DropdownIndicator: DropdownIndicator,
  IndicatorSeparator: IndicatorSeparator,
  MultiValueContainer: MultiValueContainer,
  IndicatorsContainer: IndicatorsContainer
};
var StyledAutocomplete = styled.div.withConfig({
  displayName: "Autocomplete__StyledAutocomplete",
  componentId: "sc-5au1vl-0"
})(["display:inline-block;vertical-align:middle;", " ", " ", ""], fontSize, width, space);

var Autocomplete = function Autocomplete(_ref) {
  var _ref$options = _ref.options,
      options = _ref$options === void 0 ? suggestions : _ref$options,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      raised = _ref.raised,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? "100%" : _ref$width,
      _ref$placeholder = _ref.placeholder,
      placeholder = _ref$placeholder === void 0 ? "Start typing to search..." : _ref$placeholder,
      m = _ref.m,
      mx = _ref.mx,
      my = _ref.my,
      ml = _ref.ml,
      mr = _ref.mr,
      mt = _ref.mt,
      mb = _ref.mb,
      isMulti = _ref.isMulti,
      _ref$valueMaxWidth = _ref.valueMaxWidth,
      valueMaxWidth = _ref$valueMaxWidth === void 0 ? "none" : _ref$valueMaxWidth,
      _ref$visibleOptionsCo = _ref.visibleOptionsCount,
      visibleOptionsCount = _ref$visibleOptionsCo === void 0 ? 3 : _ref$visibleOptionsCo,
      _ref$components = _ref.components,
      components = _ref$components === void 0 ? {} : _ref$components,
      restProps = _objectWithoutPropertiesLoose(_ref, ["options", "size", "raised", "width", "placeholder", "m", "mx", "my", "ml", "mr", "mt", "mb", "isMulti", "valueMaxWidth", "visibleOptionsCount", "components"]);

  return React.createElement(StyledAutocomplete, {
    fontSize: sizes[size].fontSize,
    width: width,
    m: m,
    mx: mx,
    my: my,
    ml: ml,
    mr: mr,
    mt: mt,
    mb: mb
  }, React.createElement(Select, Object.assign({
    components: _objectSpread({}, defaultComponents, {}, components),
    options: options,
    isClearable: true,
    placeholder: placeholder,
    size: size,
    raised: raised,
    hideSelectedOptions: false,
    closeMenuOnSelect: !isMulti,
    isMulti: isMulti,
    valueMaxWidth: valueMaxWidth,
    visibleOptionsCount: visibleOptionsCount
  }, restProps)));
};

export default Autocomplete;