import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { color, space, typography, system } from "styled-system";
import { filterProps } from "../../../src/utils/filterProps";
import { mutedText } from "./utils";
var allPropNames = [].concat(space.propNames, typography.propNames, color.propNames, ["size", "muted", "is", "textTransform"]);
var textTransform = system({
  textTransform: {
    property: "textTransform"
  }
});
var StyledHeading = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(props.is || "h1", filteredProps);
}).attrs(function (props) {
  return {
    fontSize: props.size ? props.theme.responsiveFontSizes[props.size] : props.fontSize
  };
}).withConfig({
  displayName: "heading__StyledHeading",
  componentId: "k2mbnv-0"
})(["margin-top:1em;margin-bottom:0.5em;", " ", " ", " ", " ", ""], color, typography, space, mutedText, textTransform);

var Heading = function Heading(_ref) {
  var as = _ref.as,
      restProps = _objectWithoutPropertiesLoose(_ref, ["as"]);

  // Note the use of `is` in order to bypass the styled-components
  // `as` prop... The top-level api can stay the same this way.
  return React.createElement(StyledHeading, Object.assign({
    is: as
  }, restProps));
};

Heading.defaultProps = {
  fontSize: "xxxl",
  lineHeight: 1.125
};
export default Heading;