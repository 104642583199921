import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import React from 'react';
import styled from 'styled-components';
import { color, space } from 'styled-system';
import { Span } from '@ds-kit/typography';
import Logo from '@ds-kit/logo';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  ", "\n  ", "\n  > div {\n    display: flex;\n    align-items: center;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledChartFooter = styled.div.withConfig({
  displayName: "indexes__StyledChartFooter",
  componentId: "sc-11meefz-0"
})(_templateObject(), color, space);

var ChartFooter = function ChartFooter(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "grey.600" : _ref$color,
      _ref$py = _ref.py,
      py = _ref$py === void 0 ? "0.75rem" : _ref$py,
      _ref$sourceText = _ref.sourceText,
      sourceText = _ref$sourceText === void 0 ? "source" : _ref$sourceText,
      logoProps = _ref.logoProps,
      textProps = _ref.textProps,
      restProps = _objectWithoutProperties(_ref, ["color", "py", "sourceText", "logoProps", "textProps"]);

  return React.createElement(StyledChartFooter, _extends({
    color: color,
    py: py
  }, restProps), React.createElement(Span, textProps, sourceText), React.createElement("div", null, React.createElement(Span, {
    mr: "0.5rem",
    ml: "1.25rem"
  }, "datastory.org"), React.createElement(Logo, logoProps)));
};

export default ChartFooter;