import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
var StyledImage = styled("img").withConfig({
  displayName: "src__StyledImage",
  componentId: "sc-1d418zz-0"
})(["display:inline-block;vertical-align:middle;max-width:100%;height:auto;"]);

var Image = function Image(_ref) {
  var src = _ref.src,
      alt = _ref.alt,
      restProps = _objectWithoutPropertiesLoose(_ref, ["src", "alt"]);

  return React.createElement(StyledImage, Object.assign({
    src: src,
    alt: alt
  }, restProps));
};

export default Image;