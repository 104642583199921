var sizes = {
  sm: {
    width: "20rem"
  },
  md: {
    width: "30rem"
  },
  lg: {
    width: "40rem"
  }
};
export default sizes;