import React from "react";

var Label = function Label(_ref) {
  var children = _ref.children;
  return React.createElement("span", {
    style: {
      display: "inline-block",
      padding: "0 0.5rem"
    }
  }, children);
};

export default Label;