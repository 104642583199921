import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import WSDMSlider from "@wsdm/ui-slider";
import { color, borderColor, space } from "styled-system";
var StyledSlider = styled.div.withConfig({
  displayName: "src__StyledSlider",
  componentId: "sc-1anz323-0"
})(["& .sldr{overflow:visible;", ";}& .sldr-background{background-color:#d8d8d8;position:relative;border-radius:4px;height:4px;top:0px;width:100%;}& .sldr-progress{", " position:absolute;border-radius:4px;height:4px;top:0px;}& .sldr-handle,& .sldr-play{cursor:pointer;width:32px;height:32px;border-width:4px;border-style:solid;background-color:#ffffff;", " border-radius:32px;outline:none;z-index:2;box-shadow:0 0 0 4px ", ";margin-left:-16px;top:-14px;}& .sldr-handle:active{", ";}& .sldr-play{position:relative;margin:0;width:48px;height:48px;color:#666;top:-22px;border:0;", " color:#FFFFFF;svg{width:16px;height:16px;}}& .sldr-playable .sldr-holder{margin-left:20px;}& .sldr-handle:before,& .sldr-handle:after{content:\"\";display:block;position:absolute;background-color:#dadfe8;}& .sldr-tooltip{padding:0.1875rem 0.5rem;font-size:0.875rem;", " color:#FFFFFF;border-radius:0.25rem;transition:opacity 0.3s;margin-top:-1rem;}& .sldr-snap-hint{position:absolute;top:-2px;width:8px;height:8px;border-radius:50%;background:#fff;border:1px solid #aaa;margin-left:-4px;}& .sldr-snap-hint-active{", " ", ";}& .sldr-axis{.tick{font-size:0.875rem !important;}.tick:first-child{transform:translateX(-25%) !important;}}"], space, color, borderColor, function (p) {
  return p.buttonOutlineColor;
}, color, color, color, color, borderColor);
/**
 * The Slider component can be used for picking values.
 */

var Slider = function Slider(_ref) {
  var mt = _ref.mt,
      mb = _ref.mb,
      my = _ref.my,
      p = _ref.p,
      pt = _ref.pt,
      pb = _ref.pb,
      pr = _ref.pr,
      pl = _ref.pl,
      px = _ref.px,
      py = _ref.py,
      color = _ref.color,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? color : _ref$bg,
      _ref$borderColor = _ref.borderColor,
      borderColor = _ref$borderColor === void 0 ? color : _ref$borderColor,
      buttonOutlineColor = _ref.buttonOutlineColor,
      restProps = _objectWithoutPropertiesLoose(_ref, ["mt", "mb", "my", "p", "pt", "pb", "pr", "pl", "px", "py", "color", "bg", "borderColor", "buttonOutlineColor"]);

  return React.createElement(StyledSlider, {
    color: color,
    bg: bg,
    borderColor: borderColor,
    mt: mt,
    mb: mb,
    my: my,
    p: p,
    pt: pt,
    pb: pb,
    pr: pr,
    pl: pl,
    px: px,
    py: py,
    buttonOutlineColor: buttonOutlineColor
  }, React.createElement(WSDMSlider, restProps));
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  interpolate: true,
  step: 0.05,
  snapStep: 1,
  showSnapHints: false,
  playMode: false,
  duration: 500,
  autoPlay: false,
  loop: false,
  tooltip: "auto",
  tooltipPosition: "top",
  tickValues: "auto",
  color: "primary",
  buttonOutlineColor: "#FFF"
};
export default Slider;