import staticQueryData from "../../../public/static/d/1986609575.json";
import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery } from "gatsby";

var Seo = function Seo(_ref) {
  var title = _ref.title,
      description = _ref.description,
      pathname = _ref.pathname,
      article = _ref.article;
  return React.createElement(StaticQuery, {
    query: query,
    render: function render(_ref2) {
      var _ref2$site$siteMetada = _ref2.site.siteMetadata,
          defaultTitle = _ref2$site$siteMetada.defaultTitle,
          defaultDescription = _ref2$site$siteMetada.defaultDescription,
          siteUrl = _ref2$site$siteMetada.siteUrl;
      var seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: "" + siteUrl + (pathname || "/")
      };
      return React.createElement(React.Fragment, null, React.createElement(Helmet, {
        title: seo.title
      }, React.createElement("meta", {
        name: "description",
        content: seo.description
      }), React.createElement("meta", {
        name: "image",
        content: seo.image
      }), seo.url && React.createElement("meta", {
        property: "og:url",
        content: seo.url
      }), (article ? true : null) && React.createElement("meta", {
        property: "og:type",
        content: "article"
      }), seo.title && React.createElement("meta", {
        property: "og:title",
        content: seo.title
      }), seo.description && React.createElement("meta", {
        property: "og:description",
        content: seo.description
      }), seo.image && React.createElement("meta", {
        property: "og:image",
        content: seo.image
      }), React.createElement("meta", {
        name: "twitter:card",
        content: "summary_large_image"
      }), seo.title && React.createElement("meta", {
        name: "twitter:title",
        content: seo.title
      }), seo.description && React.createElement("meta", {
        name: "twitter:description",
        content: seo.description
      }), seo.image && React.createElement("meta", {
        name: "twitter:image",
        content: seo.image
      })));
    },
    data: staticQueryData
  });
};

export default Seo;
Seo.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false
};
var query = "1986609575";