import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable import/no-namespace */

/* eslint-disable import/max-dependencies */
import React from "react";
import { ThemeProvider } from "styled-components";
import Seo from "../Seo";
import SiteHeader from "../SiteHeader";
import Sidebar from "../Sidebar";
import Content from "../Content";
import SiteFooter from "../SiteFooter";
import GlobalStyle from "../GlobalStyle";
import * as theme from "../../../packages/theme/src";
import Container from "../../../packages/Container/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import Div from "../../../packages/Div/src";
import { Card } from "../../../packages/Card/src";
import iconSpriteSheet from "../../images/datastory-icons.svg";
import flagSpriteSheet from "../../images/datastory-flags.svg";

var customTheme = _objectSpread({}, theme, {}, theme.typography, {
  spriteSheet: {
    icon: iconSpriteSheet,
    flag: flagSpriteSheet
  }
});

var DefaultLayout = function DefaultLayout(_ref) {
  var children = _ref.children,
      location = _ref.location;
  return React.createElement(ThemeProvider, {
    theme: customTheme
  }, React.createElement(React.Fragment, null, React.createElement(Seo, null), React.createElement(GlobalStyle, null), React.createElement(SiteHeader, null), React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, {
    pb: "2.5rem"
  }, React.createElement(Grid, null, React.createElement(GridItem, {
    gridColumn: "span 3",
    py: "2.5rem"
  }, React.createElement(Sidebar, {
    sidebarType: "design",
    activeItem: location.pathname
  })), React.createElement(GridItem, {
    gridColumn: "span 9",
    py: "2.5rem"
  }, React.createElement(Card, {
    raised: true,
    py: "5rem",
    px: "5rem",
    borderRadius: "lg",
    bg: "white"
  }, React.createElement(Content, null, children)))))), React.createElement(SiteFooter, null)));
};

export default DefaultLayout;