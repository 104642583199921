import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import React from 'react';
import styled from 'styled-components';
import { space, color } from 'styled-system';

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

function _templateObject2() {
  var data = _taggedTemplateLiteralLoose(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  position: relative;\n  height: 0;\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledRatioContainer = styled.div.withConfig({
  displayName: "indexes__StyledRatioContainer",
  componentId: "sc-1rhieqz-0"
})(_templateObject(), space);
var StyledRatioContent = styled.div.withConfig({
  displayName: "indexes__StyledRatioContent",
  componentId: "sc-1rhieqz-1"
})(_templateObject2(), color);
/**
 * RatioContainer can be used to wrap content in a fixed ratio div.
 *
 */

var RatioContainer = function RatioContainer(_ref) {
  var _ref$ratio = _ref.ratio,
      ratio = _ref$ratio === void 0 ? 1 / 2 : _ref$ratio,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? "primary" : _ref$bg,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "white" : _ref$color,
      restProps = _objectWithoutProperties(_ref, ["ratio", "bg", "color"]);

  var pb = ratio.map ? ratio.map(function (d) {
    return d ? 100 * d + "%" : null;
  }) : 100 * ratio + "%";
  return React.createElement(StyledRatioContainer, {
    pb: pb
  }, React.createElement(StyledRatioContent, _extends({
    bg: bg,
    color: color
  }, restProps)));
};

export default RatioContainer;