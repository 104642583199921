var fontSizes = [12, 14, 16, 20, 24, 28, 32, 36, 48, 56, 64, 72, 96, 112]; // For backwards compatibility the aliases ignore
// the numeric scale above and specify font sizes
// in rems

fontSizes.xxs = "0.75rem";
fontSizes.xs = "0.875rem";
fontSizes.sm = "1rem";
fontSizes.md = "1.125rem";
fontSizes.lg = "1.25rem";
fontSizes.xl = "1.75rem";
fontSizes.xxlm = "2.125rem"; // This should not be used directly!

fontSizes.xxl = "2.5rem";
fontSizes.xxxlm = "2.5rem"; // This should not be used directly!

fontSizes.xxxl = "3rem"; // The responsive sizes are used by the
// size prop.

var responsiveFontSizes = {
  xxxs: "xxxs",
  xxs: "xxs",
  xs: "xs",
  sm: "sm",
  md: "md",
  lg: "lg",
  xl: "xl",
  xxl: ["xxlm", "xxl"],
  xxxl: ["xxxlm", "xxxl"]
};
var typography = {
  fontFamily: "'Averta', -apple-system, sans-serif",
  fontSizeBase: "1rem",
  lineHeightBase: 1.5,
  lineHeightSmall: 1.25,
  lineHeights: {
    default: 1.5,
    dense: 1.25
  },
  sizes: ["0.75rem", "0.875rem", "1rem", "1.25rem", "1.5rem", "1.75rem", "2rem", "2.25rem", "3rem", "3.5rem", "4rem", "4.5rem"],
  fontSizes: fontSizes,
  responsiveFontSizes: responsiveFontSizes,
  mutedTextOpacities: [1, 0.6, 0.3]
};
export default typography;