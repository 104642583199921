import "core-js/modules/es6.object.assign";
import React from "react";
import { Heading, P } from "@ds-kit/typography";

var ChartHeader = function ChartHeader(_ref) {
  var _ref$title = _ref.title,
      title = _ref$title === void 0 ? "Chart title" : _ref$title,
      _ref$subtitle = _ref.subtitle,
      subtitle = _ref$subtitle === void 0 ? "Chart subtitle" : _ref$subtitle,
      titleProps = _ref.titleProps,
      subtitleProps = _ref.subtitleProps;
  return React.createElement("div", null, React.createElement(Heading, Object.assign({
    size: "md",
    mt: "0.75rem",
    mb: "0.75rem"
  }, titleProps), title), React.createElement(P, Object.assign({
    color: "grey.600",
    mt: "0.75rem",
    mb: "0.75rem"
  }, subtitleProps), subtitle));
};

export default ChartHeader;