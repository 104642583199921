/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import styled from "styled-components";
import { display, width, minWidth, maxWidth, color, space } from "styled-system";
var StyledDivider = styled.hr.withConfig({
  displayName: "src__StyledDivider",
  componentId: "sc-10w4qor-0"
})(["", " ", " ", " ", " ", " ", " border:0;border-bottom:", ";"], display, width, minWidth, maxWidth, color, space, function (p) {
  return p.borderWidth * 0.0625 + "rem solid currentcolor";
});

var Divider = function Divider(props) {
  return React.createElement(StyledDivider, props);
};

Divider.defaultProps = {
  my: 0,
  borderWidth: 1
};
export default Divider;