import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.object.to-string";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from "react";
import Textfield from "@ds-kit/textfield";
export var getColumnsWithFilter = function getColumnsWithFilter(columns) {
  return columns.map(function (i) {
    return i.enableFilter ? _objectSpread({}, i, {
      Filter: function Filter(header) {
        return React.createElement(Textfield, {
          mt: "2",
          size: "sm",
          value: header.filterValue || "",
          onChange: function onChange(e) {
            return header.setFilter(e.target.value);
          }
        });
      }
    }) : i;
  });
};
export var rowsAfterProcessSearchСriterion = function rowsAfterProcessSearchСriterion(_ref) {
  var row = _ref.row,
      hideTableWithoutSearchString = _ref.hideTableWithoutSearchString,
      searchString = _ref.searchString;
  if (hideTableWithoutSearchString && !searchString) return false;
  var cells = row && row.cells ? row.cells : [];
  return cells.reduce(function (acum, e) {
    if (acum) return acum;
    var value = typeof e.value === "number" ? e.value.toString() : e.value;
    return (value || "").includes(searchString);
  }, false);
};