import React from "react";
import Label from "./Label";

var Axis = function Axis(_ref) {
  var labels = _ref.labels,
      getLabelRef = _ref.getLabelRef,
      labelComponent = _ref.labelComponent,
      tickComponent = _ref.tickComponent,
      top = _ref.top,
      tickValues = _ref.tickValues,
      domain = _ref.domain,
      scale = _ref.scale,
      width = _ref.width,
      labelWidths = _ref.labelWidths;
  scale.range([0, width]);
  var ticks = tickValues === "on" ? scale.ticks() : tickValues === "auto" ? domain : null;
  return React.createElement("div", null, labels && labels.map(function (label, i) {
    return label && React.createElement("span", {
      ref: getLabelRef(i),
      key: i,
      style: {
        position: "absolute",
        top: top,
        left: i ? "auto" : 0,
        right: !i ? "auto" : 0,
        transform: "translate(0, -50%)",
        transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)"
      }
    }, labelComponent ? labelComponent(labels[i], i) : React.createElement(Label, null, labels[i]));
  }), React.createElement("div", {
    style: {
      position: "absolute",
      left: labelWidths[0] || 0,
      top: top,
      height: 1,
      width: width,
      backgroundColor: "#666B75",
      transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)"
    }
  }), ticks && ticks.map(function (tick, i) {
    return React.createElement("div", {
      key: "tick-" + tick,
      style: {
        position: "absolute",
        top: !top ? top - 16 : top + 2,
        left: labelWidths[0] ? labelWidths[0] + scale(tick) : scale(tick),
        transform: "translateX(-50%)",
        transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)"
      }
    }, tickComponent ? tickComponent(tick, i) : React.createElement("div", {
      style: {
        color: "#666B75",
        fontSize: "11px"
      }
    }, tick));
  }));
};

export default Axis;