import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.function.name";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useState, useContext, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { position, space, color, width, maxWidth } from "styled-system";
var DrawerContext = createContext({
  open: false,
  toggleDrawer: function toggleDrawer() {
    return null;
  }
});
export var DrawerContainer = function DrawerContainer(_ref) {
  var children = _ref.children,
      _ref$blockScroll = _ref.blockScroll,
      blockScroll = _ref$blockScroll === void 0 ? true : _ref$blockScroll,
      name = _ref.name,
      _ref$isOpen = _ref.isOpen,
      isOpen = _ref$isOpen === void 0 ? false : _ref$isOpen;

  var _useState = useState(isOpen),
      open = _useState[0],
      setOpen = _useState[1];

  var toggleDrawer = function toggleDrawer() {
    var action = open ? closeDrawer : openDrawer;
    action();
  };

  var openDrawer = function openDrawer() {
    if (blockScroll) document.body.style.overflow = "hidden";
    setOpen(true);
  };

  var closeDrawer = function closeDrawer() {
    if (blockScroll) document.body.style.overflow = "auto";
    setOpen(false);
  };

  useEffect(function () {
    if (isOpen) {
      openDrawer();
    } else {
      closeDrawer();
    }
  }, [isOpen]);
  return React.createElement(DrawerContext.Provider, {
    value: {
      name: name,
      open: open,
      openDrawer: openDrawer,
      closeDrawer: closeDrawer,
      toggleDrawer: toggleDrawer
    }
  }, children);
};
var StyledDrawerToggle = styled.button.withConfig({
  displayName: "src__StyledDrawerToggle",
  componentId: "sc-6914pm-0"
})([""]);
export var DrawerToggle = function DrawerToggle(props) {
  var _useContext = useContext(DrawerContext),
      toggleDrawer = _useContext.toggleDrawer,
      name = _useContext.name;

  return React.createElement(StyledDrawerToggle, Object.assign({
    "aria-controls": name,
    onClick: function onClick() {
      return toggleDrawer();
    }
  }, props));
};
var StyledDrawer = styled.div.withConfig({
  displayName: "src__StyledDrawer",
  componentId: "sc-6914pm-1"
})(["", " top:0;right:0;bottom:0;pointer-events:", ";z-index:9999;", " ", ""], position, function (p) {
  return p.open ? "all" : "none";
}, width, maxWidth);
var DrawerContent = styled.div.withConfig({
  displayName: "src__DrawerContent",
  componentId: "sc-6914pm-2"
})(["position:relative;height:100%;transform:", ";transition:all 250ms;overflow:scroll;-webkit-overflow-scrolling:touch;box-shadow:0 0 0.75rem rgba(0,0,0,0.5);", " ", ""], function (p) {
  return p.open ? "translateX(0)" : "translateX(calc(100% + 1rem))";
}, space, color);
DrawerContent.propTypes = {
  bg: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  open: PropTypes.bool
};
DrawerContent.defaultProps = {
  bg: "white"
};
var Overlay = styled.div.withConfig({
  displayName: "src__Overlay",
  componentId: "sc-6914pm-3"
})(["", " top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,0.5);opacity:", ";transition:all 250ms;visibility:", ";z-index:9999;&:hover{background:rgba(0,0,0,0.4);}"], position, function (p) {
  return p.open ? 1 : 0;
}, function (p) {
  return p.open ? "visible" : "hidden";
});
Overlay.propTypes = {
  open: PropTypes.bool
};
export var Drawer = function Drawer(_ref2) {
  var _ref2$position = _ref2.position,
      position = _ref2$position === void 0 ? "fixed" : _ref2$position,
      _ref2$overlay = _ref2.overlay,
      overlay = _ref2$overlay === void 0 ? true : _ref2$overlay,
      children = _ref2.children,
      _ref2$width = _ref2.width,
      width = _ref2$width === void 0 ? "20rem" : _ref2$width,
      _ref2$maxWidth = _ref2.maxWidth,
      maxWidth = _ref2$maxWidth === void 0 ? "100%" : _ref2$maxWidth,
      restProps = _objectWithoutPropertiesLoose(_ref2, ["position", "overlay", "children", "width", "maxWidth"]);

  var ctx = useContext(DrawerContext);
  var isFunc = typeof children === "function";
  return React.createElement(React.Fragment, null, overlay && React.createElement(Overlay, {
    open: ctx.open,
    onClick: function onClick() {
      return ctx.toggleDrawer();
    },
    position: position
  }), React.createElement(StyledDrawer, {
    id: ctx.name,
    open: ctx.open,
    position: position,
    width: width,
    maxWidth: maxWidth
  }, React.createElement(DrawerContent, Object.assign({
    open: ctx.open,
    role: "alertdialog"
  }, restProps), isFunc ? children(ctx) : children)));
};
export default Drawer;