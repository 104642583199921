import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/317f3503/src/components/layouts/index.js";
import { colors } from "../../packages/theme/src";
import MainColors from "../components/MainColors";
import ColorPalettes from "../components/ColorPalettes";
import Lozenge from "../../packages/Lozenge/src";
import Button from "../../packages/Button/src";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Colors`}</h1>
    <p>{`Ds-kit contains a set of seven colors and a set of neutrals to create the visual impact associated with the datastory brand. `}</p>
    <MainColors colors={colors} mdxType="MainColors" />
    <ColorPalettes colors={colors} mdxType="ColorPalettes" />
    <h2>{`Backgrounds`}</h2>
    <p>{`The lightest colors in the color palettes (color25) can be used as background colors. In cases where colored backgrounds are used, the text color should be the main text color (grey900) to meet accesibility standards.`}</p>
    <h2>{`Colors and the `}<inlineCode parentName="h2">{`<Lozenge />`}</inlineCode>{` component`}</h2>
    <p>{`Similarly to backgrounds, Lozenges use the lightest shade of the respective color (color25). In this case however, the text color should correspond to color700 in the same color palette. For example, a blue lozenge would use blue25 for background and blue700 for text in order to meet accessibility standards. This doesn't apply to colored icons on light colored backgrounds, as they can usually be lighter than color700.`}</p>
    <Lozenge mr={2} color="blue.600" bg="blue.50" borderColor="blue.300" mdxType="Lozenge">Lozenge</Lozenge>
    <Lozenge mr={2} color="green.600" bg="green.50" borderColor="green.300" mdxType="Lozenge">Lozenge</Lozenge>
    <Lozenge mr={2} color="red.600" bg="red.50" borderColor="red.300" mdxType="Lozenge">Lozenge</Lozenge>
    <Lozenge color="yellow.600" bg="yellow.50" borderColor="yellow.300" mdxType="Lozenge">Lozenge</Lozenge>
    <h2>{`The Datastory Blurble`}</h2>
    <p>{`The Datastory Blurble (blue400) is our main brand color. The Datastory Blurble is used to indicate states. It is currently used for buttons, check marks, toggles, tabs and highlighted text fields.`}</p>
    <Button variant="primary" mdxType="Button">Primary Button</Button>
    <h2>{`Working with colors in Sketch`}</h2>
    <p>{`If you are using one of the Datastory `}<a parentName="p" {...{
        "href": "https://www.dropbox.com/sh/qrbu706uv6l9ue0/AADgJl2q6vim2E7tpDo5YNgDa?dl=0"
      }}>{`templates`}</a>{`, you will notice that you can access all Datastory colors in the Document colors section.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/254a7f5bd53ee42cd300b8ebe7f7618d/8e724/sketch-palette-example.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.97222222222223%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACGklEQVQoz3VSWY7aQBDl0pFygFxjcoBIuUR+MhJDAgybbbYBG+94aS/dtuFNVZuQRcTSc1e3uqrfe1UDy7JgmAaWiyVWK1oNEyvThLVeY2UsYFgrTBdjzBYTumdivdlgQzApz6Q9wzAob/YDk8kYA8dx4Ps+0jSFEAJ5TqBViIKQU9xDaNB5UfSguCgEzkkKx4sQBj6iKMKAfwVdaJrmH6gHZwSloG7gfVVVRCJHWZaIYirI7BhSyoeoa0ZNsbqdqXvMRZmpTSo9z0ccx1TQ6wtykrq/Lm9Q+PVJVfdFGi7ax22rUDGzMERJ8pmlZuieThDkXaNadF2HQlSIw1LLmU5n2G63CD1BBRpEfok4yrUl9imDnyXwojOCpEBG0geBH4Abk2W5bgRfzJIaB4sSyZOPHz7h6ekzNoucHpBIzzU9Xmkv94cMlhvgsEkQU1OjOOkZ7rY7JGkCWZfEsKEECWef43Jp75LTRFDBGpdrp/eKfPR9gRN1eWGEiDyPmLvEMAhwPNo0CiSJ/LlSkbKQsHfMtqWiHUltNXO2g+Pr9ao93i8bWOYJL6MprLFDM/rKDAO4rqubwmOgGjZakmSFIld6z11V6m/ojtcNbDvB88jBt+Eas/kbzWHgwTkeSE55v8hrd+m7/b9x0l3uWmRxiLfxM9aj79i/TjH4MhL4Oq5wjCW6Vv1OqOVDZn8yZPls2c/RC+bDIcz5Eu/SKo1eiUceVwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sketch palette example",
            "title": "sketch palette example",
            "src": "/static/254a7f5bd53ee42cd300b8ebe7f7618d/f570d/sketch-palette-example.png",
            "srcSet": ["/static/254a7f5bd53ee42cd300b8ebe7f7618d/0783d/sketch-palette-example.png 240w", "/static/254a7f5bd53ee42cd300b8ebe7f7618d/782f4/sketch-palette-example.png 480w", "/static/254a7f5bd53ee42cd300b8ebe7f7618d/f570d/sketch-palette-example.png 960w", "/static/254a7f5bd53ee42cd300b8ebe7f7618d/8d0ff/sketch-palette-example.png 1440w", "/static/254a7f5bd53ee42cd300b8ebe7f7618d/a987b/sketch-palette-example.png 1920w", "/static/254a7f5bd53ee42cd300b8ebe7f7618d/8e724/sketch-palette-example.png 2880w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you are working with a new sketch file and don't have the colors installed, follow these steps to add all Datastory colors in any sketch file with`}<a parentName="p" {...{
        "href": "https://www.dropbox.com/s/jo8cs10lzh6nngg/datastory-colors.sketchpalette?dl=0"
      }}><inlineCode parentName="a">{`datastory-colors.sketchpalette`}</inlineCode></a>{`. To get started follow these steps:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Download the `}<a parentName="p" {...{
            "href": "https://github.com/andrewfiorillo/sketch-palettes"
          }}>{`sketch-palettes plugin`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install the plugin by double clicking the file highlighted in the image below:`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cec81b92861d7e1b16cde51913031376/8aae4/sketch-palettes-install.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.94778067885117%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAACTklEQVQ4y6WT60/aYBSH+/9/2yXZTPy0LLuo864ESuXWVtsKWGhrBVsCtKWogHPSG9PfDjVZli3bVD48OX3Pe/L0vDfGNRXoXR3nLQvt83N0Oh2ojQYUWYLVc3HcNNBQT9KcYRiwbRuWZaV1vV4vxfM8DAaDNDLKkQCFL0IWCb6AmiygqarQNA2u68B1+j+Lh8MhHMdJxaZppt++71Odm4rn80xhfxXs9kdUchuYf9dFDna3i9NWG67nI4xnCMII0zAmKAZRmu87HvzhJW6DELfTEKPJNS6uRmDYnc/YXXuH/O4K6ocHMGsixjfXsK0zjC/6AGZ/MP1KoskQwc0ljWPijkiQBBMwpew6uL1V8PldaEoZsiRhRfSxnOviTaaLD+IEK9I1lotjvMiN8Iod4S3rY4kdpHE+fsmO8Zq9SnNMIbOWdlhht2HUBOh1GW3vG6xhRMRoDQK0CdMNoPen0J0p5cKUMy+EQeN53nCCNMeUsxu0h58gl7M4rYuwmjLuv9My7pIH7n+Jj4Dh2S3qbgt6lUdLlWBrCsIoQhQnRPxkmEpuExwdiMjtwSRppyEhImGcJIip4KkwYn4bFVq2QHuoH9GVqfGLCcvsHnh2B5pUgCGy6ByXFhMWSzlwmXWoYh6akIOtLCgUD/ax/+U9qpUsmtRhi+7i34X/+wmdcq3Mgs9uQill0KAuzX8KH9GhSm9XPeRwQvFEYBcX1gUOD+RRq+Sgy78J5/EJMFWeRMIDVXotjaMiCcPnd1jlOZIRJD4moXpYTCdmsxmSZ/ADFQExKHD7CgUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "sketch palettes install",
            "title": "sketch palettes install",
            "src": "/static/cec81b92861d7e1b16cde51913031376/f570d/sketch-palettes-install.png",
            "srcSet": ["/static/cec81b92861d7e1b16cde51913031376/0783d/sketch-palettes-install.png 240w", "/static/cec81b92861d7e1b16cde51913031376/782f4/sketch-palettes-install.png 480w", "/static/cec81b92861d7e1b16cde51913031376/f570d/sketch-palettes-install.png 960w", "/static/cec81b92861d7e1b16cde51913031376/8d0ff/sketch-palettes-install.png 1440w", "/static/cec81b92861d7e1b16cde51913031376/8aae4/sketch-palettes-install.png 1532w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`In a new sketch file navigate to `}<inlineCode parentName="li">{`Plugins -> Sketch Palettes -> Load Palette`}</inlineCode>{` and select `}{`[`}<inlineCode parentName="li">{`datastory-colors.sketchpalette`}</inlineCode>{`]`}{` on Dropbox under `}<a parentName="li" {...{
          "href": "https://www.dropbox.com/s/jo8cs10lzh6nngg/datastory-colors.sketchpalette?dl=0"
        }}><inlineCode parentName="a">{`/Templates.`}</inlineCode></a></li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/645629d0b2f4227de14ab31d79781fd2/8e724/load-palette.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAABnUlEQVQ4y6WTWU+DQBSF+f+/xGjSB2N8sMbEWmkL1YILiku1Gzils0ALUrE9ztDUpZaY2JvcIYHDN4czF61eq6Ch6zBqp7DPDZhGHa2mjtJBHfuHVbhWFbZ5jItWDc6VBeeyhTOzBv34CE39BGa1AkOv4Gx3B63SNrSnQQDPH4JSCsY4RpRBCAHbaeNAv0H1aoiy2cFexUG58QifjMAFB+NCNgcZBvB9X7Yn+wXa1nmKDp9htfqDFxhNC7d3bbgPz3ju+fAIxXz+UxdPJrmZMAwRRSE0tVOapvnDuVQv+/09Q/oa4y19RfaWfgK+NAsTjDF4ngch3SqoppbpdPopXl6jKMo/nUthHCe/Nlxqw2iMYMQQJykm0q0m1gBnsxlc14Vt27AsC91utxBIeYQekVnSMHdZ6FDlQgjJW2kKgUyABlIjmHRKfwPnq6l/y241R1WEDNG47uG+z/IJKXS4zs26ez5L4HQobntCAvnmQHVwdBQgkhxelOFf9R0YBIvBXk6FppZNgOPxOD9ABcznMEkSZFn2b6B6f/GXLIAfN3DlhXpJzBYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "load sketch palette",
            "title": "load sketch palette",
            "src": "/static/645629d0b2f4227de14ab31d79781fd2/f570d/load-palette.png",
            "srcSet": ["/static/645629d0b2f4227de14ab31d79781fd2/0783d/load-palette.png 240w", "/static/645629d0b2f4227de14ab31d79781fd2/782f4/load-palette.png 480w", "/static/645629d0b2f4227de14ab31d79781fd2/f570d/load-palette.png 960w", "/static/645629d0b2f4227de14ab31d79781fd2/8d0ff/load-palette.png 1440w", "/static/645629d0b2f4227de14ab31d79781fd2/a987b/load-palette.png 1920w", "/static/645629d0b2f4227de14ab31d79781fd2/8e724/load-palette.png 2880w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can add the datastory-colors sketch  palette to any sketch file in this way.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      