import colors from "./colors";
var buttons = {
  default: {
    backgroundColor: "rgba(42, 30, 66, 0)",
    color: "inherit",
    ":hover, :focus": {
      backgroundColor: "rgba(42, 30, 66, 0.05)",
      color: "inherit"
    },
    ":focus": {
      boxShadow: "0 0 0 0.25rem rgba(42, 30, 66, 0.2)"
    },
    ":active": {
      backgroundColor: "rgba(42, 30, 66, 0.1)",
      color: "inherit",
      boxShadow: "none"
    }
  },
  primary: {
    backgroundColor: colors.primary,
    color: colors.primaryContrast,
    ":hover, :focus": {
      backgroundColor: colors.primaryDark,
      color: colors.primaryContrast
    },
    ":focus": {
      boxShadow: "0 0 0 0.25rem rgba(81, 30, 233,0.3)"
    },
    ":active": {
      backgroundColor: colors.primaryLight,
      color: colors.primaryContrast
    }
  },
  success: {
    backgroundColor: colors.success,
    color: colors.successContrast,
    ":hover, :focus": {
      backgroundColor: colors.successDark,
      color: colors.successContrast
    },
    ":focus": {
      boxShadow: "0 0 0 0.25rem rgba(53, 201, 131, 0.4)"
    },
    ":active": {
      backgroundColor: colors.successLight,
      color: colors.successContrast
    }
  },
  danger: {
    backgroundColor: colors.danger,
    color: colors.dangerContrast,
    ":hover, :focus": {
      backgroundColor: colors.dangerDark,
      color: colors.dangerContrast
    },
    ":focus": {
      boxShadow: "0 0 0 0.25rem rgba(227, 41, 66, 0.3)"
    },
    ":active": {
      backgroundColor: colors.dangerLight,
      color: colors.dangerContrast
    }
  },
  warning: {
    backgroundColor: colors.alert,
    color: colors.alertContrast,
    ":hover, :focus": {
      backgroundColor: colors.alertDark,
      color: colors.alertContrast
    },
    ":focus": {
      boxShadow: "0 0 0 0.25rem rgba(255, 189, 7, 0.3)"
    },
    ":active": {
      backgroundColor: colors.alertLight,
      color: colors.alertContrast
    }
  },
  subtle: {
    backgroundColor: colors.grey[50],
    color: "inherit",
    ":hover, :focus": {
      backgroundColor: "rgba(42, 30, 66, 0.075)",
      color: "inherit"
    },
    ":focus": {
      boxShadow: "0 0 0 0.25rem rgba(42, 30, 66, 0.2)"
    },
    ":active": {
      backgroundColor: "rgba(42, 30, 66, 0.1)",
      color: "inherit",
      boxShadow: "none"
    }
  }
};
export default buttons;