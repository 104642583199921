import React from "react";
import { beeswarm } from "d3-beeswarm";
import tooltip from "wsdm-tooltip";
import Symbol from "./Symbol";

var Chart = function Chart(_ref) {
  var data = _ref.data,
      symbol = _ref.symbol,
      symbolSize = _ref.symbolSize,
      width = _ref.width,
      height = _ref.height,
      scale = _ref.scale,
      tooltipFormat = _ref.tooltipFormat,
      tooltipOffset = _ref.tooltipOffset,
      side = _ref.side,
      radius = _ref.radius,
      selected = _ref.selected,
      keyValue = _ref.keyValue,
      top = _ref.top;
  if (!width) return null;
  var tip = tooltip();
  scale.range([0, width]);
  var swarm = beeswarm().data(data).distributeOn(function (d) {
    return scale(d[keyValue]);
  }).radius(radius).orientation("horizontal").side(side).arrange();

  var handleMouseEnter = function handleMouseEnter(d) {
    return function () {
      tip.show(tooltipFormat(d.datum));
    };
  };

  var handleMouseMove = function handleMouseMove(e) {
    tip.position(e, tooltipOffset);
  };

  var handleMouseLeave = function handleMouseLeave() {
    tip.hide();
  };

  var getOpacity = function getOpacity(_ref2) {
    var id = _ref2.datum.id;
    return !selected.length ? 1 : selected.indexOf(id) !== -1 ? 1 : 0.15;
  };

  var symbolTransform = function symbolTransform(d) {
    var x = d.x - symbolSize / 2;
    var y = top + d.y - symbolSize / 2; // place symbols above the line if negative

    if (side === "negative") y -= symbolSize / 2; // place symbols below the line if negative

    if (side === "positive") y += symbolSize / 2;
    return "translate(" + x + "px," + y + "px)";
  };

  return React.createElement("div", null, React.createElement("div", {
    style: {
      height: height
    }
  }, swarm.map(function (d) {
    return React.createElement("div", {
      key: d.datum.id,
      style: {
        position: "absolute",
        left: 0,
        top: 0,
        width: symbolSize,
        height: symbolSize,
        transform: symbolTransform(d),
        transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
        opacity: getOpacity(d)
      },
      onMouseEnter: tooltipFormat && handleMouseEnter(d),
      onMouseMove: tooltipFormat && handleMouseMove,
      onMouseLeave: tooltipFormat && handleMouseLeave
    }, symbol ? symbol(d.datum) : React.createElement(Symbol, {
      size: symbolSize
    }));
  })));
};

export default Chart;