import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import React from 'react';
import styled from 'styled-components';
import WSDMSlider from '@wsdm/ui-slider';
import { space, color, borderColor } from 'styled-system';

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  & .sldr {\n    overflow: visible;\n    ", ";\n  }\n\n  & .sldr-background {\n    background-color: #d8d8d8;\n    position: relative;\n    border-radius: 4px;\n    height: 4px;\n    top: 0px;\n    width: 100%;\n  }\n\n  & .sldr-progress {\n    ", " position: absolute;\n    border-radius: 4px;\n    height: 4px;\n    top: 0px;\n  }\n\n  & .sldr-handle,\n  & .sldr-play {\n    cursor: pointer;\n    width: 32px;\n    height: 32px;\n    border-width: 4px;\n    border-style: solid;\n    background-color: #ffffff;\n    ", " border-radius: 32px;\n    outline: none;\n    z-index: 2;\n    box-shadow: 0 0 0 4px ", ";\n    margin-left: -16px;\n    top: -14px;\n  }\n\n  & .sldr-handle:active {\n    ", ";\n  }\n\n  & .sldr-play {\n    position: relative;\n    margin: 0;\n    width: 48px;\n    height: 48px;\n    color: #666;\n    top: -22px;\n    border: 0;\n    ", " color: #FFFFFF;\n    svg {\n      width: 16px;\n      height: 16px;\n    }\n  }\n\n  & .sldr-playable .sldr-holder {\n    margin-left: 20px;\n  }\n\n  & .sldr-handle:before,\n  & .sldr-handle:after {\n    content: \"\";\n    display: block;\n    position: absolute;\n    background-color: #dadfe8;\n  }\n\n  & .sldr-tooltip {\n    padding: 0.1875rem 0.5rem;\n    font-size: 0.875rem;\n    ", " color: #FFFFFF;\n    border-radius: 0.25rem;\n    transition: opacity 0.3s;\n    margin-top: -1rem;\n  }\n\n  & .sldr-snap-hint {\n    position: absolute;\n    top: -2px;\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n    background: #fff;\n    border: 1px solid #aaa;\n    margin-left: -4px;\n  }\n\n  & .sldr-snap-hint-active {\n    ", " ", ";\n  }\n\n  & .sldr-axis {\n    .tick {\n      font-size: 0.875rem !important;\n    }\n    .tick:first-child {\n      transform: translateX(-25%) !important;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledSlider = styled.div.withConfig({
  displayName: "indexes__StyledSlider",
  componentId: "uuj0c1-0"
})(_templateObject(), space, color, borderColor, function (p) {
  return p.buttonOutlineColor;
}, color, color, color, color, borderColor);
/**
 * The Slider component can be used for picking values.
 */

var Slider = function Slider(_ref) {
  var mt = _ref.mt,
      mb = _ref.mb,
      my = _ref.my,
      p = _ref.p,
      pt = _ref.pt,
      pb = _ref.pb,
      pr = _ref.pr,
      pl = _ref.pl,
      px = _ref.px,
      py = _ref.py,
      color = _ref.color,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? color : _ref$bg,
      _ref$borderColor = _ref.borderColor,
      borderColor = _ref$borderColor === void 0 ? color : _ref$borderColor,
      buttonOutlineColor = _ref.buttonOutlineColor,
      restProps = _objectWithoutProperties(_ref, ["mt", "mb", "my", "p", "pt", "pb", "pr", "pl", "px", "py", "color", "bg", "borderColor", "buttonOutlineColor"]);

  return React.createElement(StyledSlider, {
    color: color,
    bg: bg,
    borderColor: borderColor,
    mt: mt,
    mb: mb,
    my: my,
    p: p,
    pt: pt,
    pb: pb,
    pr: pr,
    pl: pl,
    px: px,
    py: py,
    buttonOutlineColor: buttonOutlineColor
  }, React.createElement(WSDMSlider, restProps));
};

Slider.defaultProps = {
  min: 0,
  max: 100,
  interpolate: true,
  step: 0.05,
  snapStep: 1,
  showSnapHints: false,
  playMode: false,
  duration: 500,
  autoPlay: false,
  loop: false,
  tooltip: "auto",
  tooltipPosition: "top",
  tickValues: "auto",
  color: "primary",
  buttonOutlineColor: "#FFF"
};
export default Slider;