import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import RatioContainer from "@ds-kit/ratio-container";
import Flex from "@ds-kit/flex";
import Card from "@ds-kit/card";
import { Heading, P } from "@ds-kit/typography";

var ChartCard = function ChartCard(_ref) {
  var as = _ref.as,
      _ref$ratio = _ref.ratio,
      ratio = _ref$ratio === void 0 ? 2 / 3 : _ref$ratio,
      interactive = _ref.interactive,
      raised = _ref.raised,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "inherit" : _ref$color,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? "transparent" : _ref$bg,
      overlayBg = _ref.overlayBg,
      src = _ref.src,
      alt = _ref.alt,
      _ref$headerProps = _ref.headerProps,
      headerProps = _ref$headerProps === void 0 ? {} : _ref$headerProps,
      _ref$headingProps = _ref.headingProps,
      headingProps = _ref$headingProps === void 0 ? {} : _ref$headingProps,
      _ref$footerProps = _ref.footerProps,
      footerProps = _ref$footerProps === void 0 ? {} : _ref$footerProps,
      _ref$dateProps = _ref.dateProps,
      dateProps = _ref$dateProps === void 0 ? {} : _ref$dateProps,
      title = _ref.title,
      date = _ref.date,
      restProps = _objectWithoutPropertiesLoose(_ref, ["as", "ratio", "interactive", "raised", "color", "bg", "overlayBg", "src", "alt", "headerProps", "headingProps", "footerProps", "dateProps", "title", "date"]);

  return React.createElement(Card, Object.assign({
    as: as,
    borderRadius: "lg",
    overflow: "hidden",
    interactive: interactive,
    raised: raised
  }, restProps), React.createElement(RatioContainer, {
    as: "article",
    ratio: ratio,
    bg: bg,
    color: color
  }, src && React.createElement("img", {
    alt: alt,
    src: src,
    style: {
      display: "block",
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  }), React.createElement(Flex, {
    style: {
      position: "relative"
    },
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    bg: overlayBg
  }, React.createElement("header", headerProps, React.createElement(Heading, Object.assign({
    size: "lg",
    mt: "1.25rem",
    mx: "1.25rem"
  }, headingProps), title)), React.createElement("footer", footerProps, React.createElement(P, Object.assign({
    fontWeight: 600,
    size: "xs",
    lineHeight: "dense",
    mb: "1.25rem",
    mx: "1.25rem"
  }, dateProps), date)))));
};

export default ChartCard;