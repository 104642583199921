function _templateObject3() {
  var data = _taggedTemplateLiteralLoose(["\n  .prism-code {\n    display: block;\n    overflow: scroll;\n\n    background-color: ", ";\n    color: ", ";\n\n    border-bottom-left-radius: 0.25rem;\n    border-bottom-right-radius: 0.25rem;\n\n    padding: 1.5rem !important;\n    margin: 0;\n\n    box-sizing: border-box;\n    outline: none;\n    text-shadow: none;\n    -webkit-hyphens: none;\n    -ms-hyphens: none;\n    hyphens: none;\n    word-wrap: normal;\n    word-break: normal;\n    text-align: left;\n    word-spacing: normal;\n    -moz-tab-size: 2;\n    -o-tab-size: 2;\n    tab-size: 2;\n\n    font-family: menlo, monospace;\n    line-height: 1.5;\n  }\n\n  .token.comment,\n  .token.prolog,\n  .token.doctype,\n  .token.cdata {\n    color: hsl(30, 20%, 50%);\n  }\n\n  .token.punctuation {\n    opacity: .7;\n  }\n\n  .namespace {\n    opacity: .7;\n  }\n\n  .token.property,\n  .token.tag,\n  .token.boolean,\n  .token.number,\n  .token.constant,\n  .token.symbol {\n    color: ", ";\n  }\n\n  .token.selector,\n  .token.attr-name,\n  .token.string,\n  .token.char,\n  .token.builtin,\n  .token.inserted {\n    color: ", ";\n  }\n\n  .token.operator,\n  .token.entity,\n  .token.url,\n  .language-css .token.string,\n  .style .token.string,\n  .token.variable {\n    color: ", ";\n  }\n\n  .token.atrule,\n  .token.attr-value,\n  .token.keyword {\n    color: ", ";\n  }\n\n  .token.regex,\n  .token.important {\n    color: ", ";\n  }\n\n  .token.important,\n  .token.bold {\n    font-weight: bold;\n  }\n  .token.italic {\n    font-style: italic;\n  }\n\n  .token.entity {\n    cursor: help;\n  }\n\n  .token.deleted {\n    color: #f72f1c;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteralLoose(["\n  p a {\n    color: ", ";\n  }\n  blockquote {\n    margin: 0;\n    padding: 0 1.5rem;\n    overflow: hidden;\n    background-color: ", ";\n    box-shadow: ", ";\n    > p {\n      margin: 1.5rem 0;\n      > em {\n        font-weight: 400;\n        font-style: normal;\n        display: block;\n        background-color: ", ";\n        margin: -1.5rem;\n        padding: 1.5rem;\n        border-left: ", ";\n      }\n      > strong > em {\n        font-weight: 400;\n        font-style: normal;\n        display: block;\n        background-color: ", ";\n        margin: -1.5rem;\n        padding: 1.5rem;\n        border-left: ", ";\n      }\n    }\n  }\n  .react-live-preview {\n    background: #FFFFFF;\n    padding: 1rem;\n    overflow: hidden;\n    font-family: \"Averta PE\", system-ui, sans-serif;\n    border: 0.0625rem solid #DDD;\n    border-bottom: 0;\n\n    border-top-left-radius: 0.25rem;\n    border-top-right-radius: 0.25rem;\n\n    white-space: normal;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  @font-face {\n    font-family: \"Averta\";\n    src: url(", ") format(\"woff2\"),\n        url(", ") format(\"woff\");\n    font-weight: 700;\n    font-style: normal;\n    font-display: swap;\n  }\n  @font-face {\n    font-family: \"Averta\";\n    src: url(", ") format(\"woff2\"),\n        url(", ") format(\"woff\");\n    font-weight: 600;\n    font-style: normal;\n    font-display: swap;\n  }\n  @font-face {\n    font-family: \"Averta\";\n    src: url(", ") format(\"woff2\"),\n        url(", ") format(\"woff\");\n    font-weight: 400;\n    font-style: normal;\n    font-display: swap;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }

import React from "react";
import { createGlobalStyle } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import avertaRegularWoff from "../../fonts/averta-regular.woff";
import avertaRegularWoff2 from "../../fonts/averta-regular.woff2";
import avertaSemiboldWoff from "../../fonts/averta-semibold.woff";
import avertaSemiboldWoff2 from "../../fonts/averta-semibold.woff2";
import avertaBoldWoff from "../../fonts/averta-bold.woff";
import avertaBoldWoff2 from "../../fonts/averta-bold.woff2";
import GlobalStyles from "../../../packages/GlobalStyles/src";
export var FontStyle = createGlobalStyle(_templateObject(), avertaBoldWoff2, avertaBoldWoff, avertaSemiboldWoff2, avertaSemiboldWoff, avertaRegularWoff2, avertaRegularWoff);
export var DSKitStyle = createGlobalStyle(_templateObject2(), function (p) {
  return p.theme.colors.primary;
}, function (p) {
  return p.theme.colors.green[25];
}, function (p) {
  return "inset 0.25rem 0 0 " + p.theme.colors.green[500];
}, function (p) {
  return p.theme.colors.yellow[25];
}, function (p) {
  return "0.25rem solid " + p.theme.colors.yellow[500];
}, function (p) {
  return p.theme.colors.red[25];
}, function (p) {
  return "0.25rem solid " + p.theme.colors.red[500];
});
export var PrismStyle = createGlobalStyle(_templateObject3(), themeGet("colors.grey.900"), themeGet("colors.grey.50"), themeGet("colors.orange.500"), themeGet("colors.green.500"), themeGet("colors.yellow.400"), themeGet("colors.cyan.400"), themeGet("colors.yellow.400"));

var GlobalStyle = function GlobalStyle() {
  return React.createElement(React.Fragment, null, React.createElement(FontStyle, null), React.createElement(GlobalStyles, null), React.createElement(DSKitStyle, null), React.createElement(PrismStyle, null));
};

export default GlobalStyle;