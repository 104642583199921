import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { color, space } from "styled-system";
import { Span } from "@ds-kit/typography";
import Logo from "@ds-kit/logo";
var StyledChartFooter = styled.div.withConfig({
  displayName: "src__StyledChartFooter",
  componentId: "sc-4m8ayo-0"
})(["display:flex;justify-content:space-between;align-items:center;", " ", " > div{display:flex;align-items:center;}"], color, space);

var ChartFooter = function ChartFooter(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "grey.600" : _ref$color,
      _ref$py = _ref.py,
      py = _ref$py === void 0 ? "0.75rem" : _ref$py,
      _ref$sourceText = _ref.sourceText,
      sourceText = _ref$sourceText === void 0 ? "source" : _ref$sourceText,
      logoProps = _ref.logoProps,
      textProps = _ref.textProps,
      restProps = _objectWithoutPropertiesLoose(_ref, ["color", "py", "sourceText", "logoProps", "textProps"]);

  return React.createElement(StyledChartFooter, Object.assign({
    color: color,
    py: py
  }, restProps), React.createElement(Span, textProps, sourceText), React.createElement("div", null, React.createElement(Span, {
    mr: "0.5rem",
    ml: "1.25rem"
  }, "datastory.org"), React.createElement(Logo, logoProps)));
};

export default ChartFooter;