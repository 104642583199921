import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
var StyledMultiValueContainer = styled.div.withConfig({
  displayName: "MultiValueContainer__StyledMultiValueContainer",
  componentId: "sc-1szcn5i-0"
})(["align-items:center;display:flex;background:", ";-webkit-overflow-scrolling:touch;position:relative;overflow:hidden;border-radius:0.1875rem;height:1.75em;padding:0;margin-right:0.25rem;max-width:", ";font-weight:600;> div:first-child{padding:0 0.25rem 0 0.5rem;}"], themeGet("colors.grey.100"), function (p) {
  return p.maxWidth;
});

var MultiValueContainer = function MultiValueContainer(_ref) {
  var children = _ref.children,
      selectProps = _ref.selectProps,
      data = _ref.data;
  var visibleOptionsCount = selectProps.visibleOptionsCount;
  var shouldDisplay = selectProps.value && selectProps.value.map(function (d) {
    return d.value;
  }).indexOf(data.value) < visibleOptionsCount;
  return React.createElement(StyledMultiValueContainer, {
    maxWidth: selectProps.valueMaxWidth,
    style: !shouldDisplay ? {
      display: "none"
    } : {}
  }, children);
};

export default MultiValueContainer;