import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { fontSize, fontWeight, textAlign, lineHeight, space, color, maxWidth } from "styled-system";
var Text = styled.span.withConfig({
  displayName: "text__Text",
  componentId: "sc-1ji4bvr-0"
})(["", " ", " ", " ", " ", " ", " ", " ", ""], fontSize, fontWeight, textAlign, lineHeight, space, color, maxWidth, function (p) {
  return p.truncated && css(["white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"]);
});
Text.propTypes = _objectSpread({
  truncated: PropTypes.bool
}, fontSize.propTypes, {}, fontWeight.propTypes, {}, textAlign.propTypes, {}, lineHeight.propTypes, {}, space.propTypes, {}, color.propTypes, {}, maxWidth.propTypes);
export default Text;