import React from "react";
import { FixedSizeList as List } from "react-window";

var MenuList = function MenuList(props) {
  var options = props.options,
      getValue = props.getValue;

  var _getValue = getValue(),
      value = _getValue[0];

  var itemSize = 34;
  var initialOffset = options.indexOf(value) * itemSize;
  var children = React.Children.toArray(props.children);
  var height = itemSize * children.length;
  return React.createElement(List, {
    height: Math.min(height, 300),
    itemCount: children.length,
    itemSize: itemSize,
    initialScrollOffset: initialOffset
  }, function (_ref) {
    var index = _ref.index,
        style = _ref.style;
    return React.createElement("div", {
      style: style
    }, children[index]);
  });
};

export default MenuList;