import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
var StyledPlaceholder = styled.div.withConfig({
  displayName: "Placeholder__StyledPlaceholder",
  componentId: "sc-8eao92-0"
})(["color:", ";margin-left:0.125rem;margin-right:0.125rem;top:50%;transform:translateY(-50%);white-space:nowrap;text-overflow:ellipsis;overflow:hidden;max-width:100%;position:absolute;"], themeGet("colors.grey.600"));

var Placeholder = function Placeholder(_ref) {
  var children = _ref.children;
  return React.createElement(StyledPlaceholder, null, children);
};

export default Placeholder;