import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import React from "react";
import { Link } from "gatsby";
import Div from "../../../packages/Div/src";

var Design = function Design(_ref) {
  var designLinks = _ref.designLinks,
      searchValue = _ref.searchValue,
      activeItemSlug = _ref.activeItemSlug;
  var filteredLinks = designLinks.filter(function (el) {
    return el.label.toLowerCase().includes(searchValue.toLowerCase());
  });
  var newActiveItemSlug = activeItemSlug[activeItemSlug.length - 1] === "/" ? activeItemSlug.slice(0, activeItemSlug.length - 1) : activeItemSlug;
  return React.createElement(Div, {
    pt: "1.25rem"
  }, filteredLinks.map(function (designLink) {
    var isActive = designLink.slug === newActiveItemSlug;
    return React.createElement(Link, {
      key: designLink.slug,
      to: designLink.slug
    }, React.createElement(Div, {
      bg: isActive ? "grey.100" : "transparent",
      px: "1rem",
      py: "0.5rem",
      borderRadius: "md"
    }, designLink.label));
  }));
};

export default Design;