import "core-js/modules/es6.object.assign";
import React from "react";
import styled from "styled-components";
import { boxShadow, borderRadius } from "styled-system";
var StyledMenu = styled.div.withConfig({
  displayName: "Menu__StyledMenu",
  componentId: "m9xumi-0"
})(["position:absolute;z-index:9999;left:0;right:0;", " ", " padding-top:0.25rem;padding-bottom:0.25rem;margin-top:0.75rem;background-color:#ffffff;"], boxShadow, borderRadius);

var Menu = function Menu(_ref) {
  var children = _ref.children,
      innerProps = _ref.innerProps;
  return React.createElement(StyledMenu, Object.assign({}, innerProps, {
    borderRadius: "md",
    boxShadow: "md"
  }), children);
};

export default Menu;