import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled, { withTheme } from "styled-components";
import { space, textAlign, maxWidth } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
var StyledContainer = styled.div.withConfig({
  displayName: "src__StyledContainer",
  componentId: "mw5iz0-0"
})(["width:100%;margin:0 auto;", ";", ";", ";"], space, textAlign, maxWidth);

var Container = function Container(_ref) {
  var size = _ref.size,
      restProps = _objectWithoutPropertiesLoose(_ref, ["size"]);

  return React.createElement(StyledContainer, Object.assign({
    maxWidth: themeGet("containers." + size, "90rem")(restProps)
  }, restProps));
};

Container.defaultProps = {
  px: ["1rem", "3rem"]
};
export default withTheme(Container);