import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { space, color } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
var variants = {
  success: {
    bg: "green.50",
    color: "green.700",
    dismissHoverColor: "green.100",
    dismissFocusColor: "green.200"
  },
  danger: {
    bg: "red.50",
    color: "red.700",
    dismissHoverColor: "red.100",
    dismissFocusColor: "red.200"
  },
  alert: {
    bg: "yellow.50",
    color: "yellow.700",
    dismissHoverColor: "yellow.100",
    dismissFocusColor: "yellow.200"
  },
  info: {
    bg: "blue.50",
    color: "blue.700",
    dismissHoverColor: "blue.100",
    dismissFocusColor: "blue.200"
  }
};
var StyledNotification = styled.div.withConfig({
  displayName: "src__StyledNotification",
  componentId: "sc-1eemrkn-0"
})(["position:relative;border-radius:0.1875rem;overflow:hidden;text-align:left;line-height:1.4;", " ", ""], color, space);
var Dismiss = styled.button.withConfig({
  displayName: "src__Dismiss",
  componentId: "sc-1eemrkn-1"
})(["appearance:none;border:0;border-radius:100%;background:transparent;position:absolute;top:0.5rem;right:0.5rem;padding:0.25rem;color:inherit;> svg{display:block;}&:hover,&:focus{background:", ";}&:focus{outline:none;box-shadow:0 0 0 0.125rem ", ";}"], function (p) {
  return themeGet("colors." + p.hoverColor)(p);
}, function (p) {
  return themeGet("colors." + p.focusColor)(p);
});

var Notification = function Notification(_ref) {
  var _ref$variant = _ref.variant,
      variant = _ref$variant === void 0 ? "info" : _ref$variant,
      _ref$show = _ref.show,
      show = _ref$show === void 0 ? true : _ref$show,
      children = _ref.children,
      onClose = _ref.onClose,
      restProps = _objectWithoutPropertiesLoose(_ref, ["variant", "show", "children", "onClose"]);

  return React.createElement(StyledNotification, Object.assign({
    bg: variants[variant].bg,
    color: variants[variant].color,
    py: "1rem",
    pl: "1rem",
    pr: "2rem",
    style: {
      display: show ? "block" : "none"
    }
  }, restProps), React.createElement(Dismiss, {
    onClick: onClose,
    hoverColor: variants[variant].dismissHoverColor,
    focusColor: variants[variant].dismissFocusColor
  }, React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "3",
    strokeLinecap: "square",
    strokeLinejoin: "arcs"
  }, React.createElement("line", {
    x1: "18",
    y1: "6",
    x2: "6",
    y2: "18"
  }), React.createElement("line", {
    x1: "6",
    y1: "6",
    x2: "18",
    y2: "18"
  }))), children);
};

export default Notification;