import "core-js/modules/es6.regexp.split";
import React from "react";
import styled from "styled-components";
import { display, gridColumn, gridRow, alignSelf, space, color, width } from "styled-system";
import css from "@styled-system/css";
var StyledGridItem = styled.div.withConfig({
  displayName: "grid-item__StyledGridItem",
  componentId: "gk21wb-0"
})(["", " @supports(display:grid){width:auto;", "}", " ", " ", " ", " ", ""], function (p) {
  if (!p.gridColumn) return "";
  var gc = typeof p.gridColumn === "string" ? [p.gridColumn] : p.gridColumn;
  var vals = gc.reduce(function (acc, cur) {
    if (!cur) return acc;
    acc.push(+cur.split("span")[1] * (100 / 12) + "%");
    return acc;
  }, []);
  return css(width({
    width: vals
  }));
}, gridColumn, display, gridRow, alignSelf, space, color);

var GridItem = function GridItem(props) {
  return React.createElement(StyledGridItem, props);
};

GridItem.defaultProps = {
  gridColumn: "span 1"
};
export default GridItem;