import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable import/max-dependencies */
import React from "react";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import Button from "../../../packages/Button/src";
import Div from "../../../packages/Div/src";
import Avatar from "../../../packages/Avatar/src";
import ButtonGroup, { ButtonGroupBase } from "../../../packages/ButtonGroup/src";
import { Carousel, CarouselItem } from "../../../packages/Carousel/src";
import Container from "../../../packages/Container/src";
import Logo from "../../../packages/Logo/src";
import Lozenge from "../../../packages/Lozenge/src";
import Modal from "../../../packages/Modal/src";
import RatioContainer from "../../../packages/RatioContainer/src";
import Select from "../../../packages/Select/src";
import Switch from "../../../packages/Switch/src";
import Slider from "../../../packages/Slider/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import { P, Span, Heading } from "../../../packages/Typography/src";
import BeeswarmChart from "../../../packages/BeeswarmChart/src";
import Divider from "../../../packages/Divider/src";
import { Card } from "../../../packages/Card/src";
import { CalloutKicker, CalloutBody, Callout } from "../../../packages/Callout/src";
import Autocomplete, { AutocompleteVirtualized } from "../../../packages/Autocomplete/src";
import ChartHeader from "../../../packages/ChartHeader/src";
import ChartFooter from "../../../packages/ChartFooter/src";
import Icon, { Flag } from "../../../packages/Icon/src";
import { TabBar } from "../../../packages/Tabs/src";
import Textfield from "../../../packages/Textfield/src";
import { DrawerContainer, DrawerToggle, Drawer } from "../../../packages/Drawer/src";
import PageHeader from "../../../packages/PageHeader/src";
import Notification from "../../../packages/Notification/src";
import Table from "../../../packages/Table/src";
import Tooltip from "../../../packages/Tooltip/src";
import Popover from "../../../packages/Popover/src";
import { Radio, RadioItem } from "../../../packages/Radio/src";
import Checkbox from "../../../packages/Checkbox/src";
import { Menu, MenuItem, MenuDisclosure, MenuPanel } from "../../../packages/Menu/src";
import { setLocale, format, formatDistance, formatDistanceStrict, formatRelative, parse, startOfWeek, formatDistanceToNow, subDays, differenceInCalendarDays } from "../../../packages/utils-date/src";
import { Ul, Ol, Li } from "../../../packages/List/src";
import TopicCard from "../../../packages/TopicCard/src";
import DataStoryCard from "../../../packages/DataStoryCard/src";
import BlogPostCard from "../../../packages/BlogPostCard/src";
import Textarea from "../../../packages/Textarea/src";
import Flex from "../../../packages/Flex/src";
import ReadingProgressBar from "../../../packages/ReadingProgressBar/src";
import Image from "../../../packages/Image/src";
import Link from "../../../packages/Link/src";
import { Accordion, AccordionPanel } from "../../../packages/Accordion/src";
import Breadcrumbs from "../../../packages/Breadcrumbs/src";
import AuthorCard from "../../../packages/AuthorCard/src";
import ChartCard from "../../../packages/ChartCard/src";
import Numberfield from "../../../packages/Numberfield/src";
import Toolbar from "./Toolbar";
import SimpleCodeBlock from "./SimpleCodeBlock";
import CopyButton from "./CopyButton";
var scope = {
  Button: Button,
  ButtonGroup: ButtonGroup,
  ButtonGroupBase: ButtonGroupBase,
  Div: Div,
  Avatar: Avatar,
  Carousel: Carousel,
  CarouselItem: CarouselItem,
  Container: Container,
  Logo: Logo,
  Lozenge: Lozenge,
  Modal: Modal,
  RatioContainer: RatioContainer,
  Select: Select,
  Switch: Switch,
  Slider: Slider,
  Grid: Grid,
  Flex: Flex,
  GridItem: GridItem,
  Heading: Heading,
  P: P,
  Span: Span,
  BeeswarmChart: BeeswarmChart,
  Divider: Divider,
  Card: Card,
  CalloutKicker: CalloutKicker,
  CalloutBody: CalloutBody,
  Callout: Callout,
  Autocomplete: Autocomplete,
  AutocompleteVirtualized: AutocompleteVirtualized,
  ChartHeader: ChartHeader,
  ChartFooter: ChartFooter,
  TabBar: TabBar,
  Textfield: Textfield,
  DrawerContainer: DrawerContainer,
  DrawerToggle: DrawerToggle,
  Drawer: Drawer,
  Icon: Icon,
  Flag: Flag,
  PageHeader: PageHeader,
  Notification: Notification,
  Table: Table,
  Tooltip: Tooltip,
  Popover: Popover,
  Radio: Radio,
  RadioItem: RadioItem,
  Checkbox: Checkbox,
  Menu: Menu,
  MenuItem: MenuItem,
  MenuDisclosure: MenuDisclosure,
  MenuPanel: MenuPanel,
  setLocale: setLocale,
  format: format,
  formatDistance: formatDistance,
  formatDistanceStrict: formatDistanceStrict,
  formatRelative: formatRelative,
  parse: parse,
  startOfWeek: startOfWeek,
  formatDistanceToNow: formatDistanceToNow,
  subDays: subDays,
  differenceInCalendarDays: differenceInCalendarDays,
  Ul: Ul,
  Ol: Ol,
  Li: Li,
  TopicCard: TopicCard,
  DataStoryCard: DataStoryCard,
  BlogPostCard: BlogPostCard,
  Textarea: Textarea,
  ReadingProgressBar: ReadingProgressBar,
  Image: Image,
  Link: Link,
  Accordion: Accordion,
  AccordionPanel: AccordionPanel,
  Breadcrumbs: Breadcrumbs,
  AuthorCard: AuthorCard,
  ChartCard: ChartCard,
  Numberfield: Numberfield
};

var CodeBlock = function CodeBlock(_ref) {
  var children = _ref.children,
      props = _objectWithoutPropertiesLoose(_ref, ["children"]);

  var isJsx = props.className === "language-jsx";

  var handleCopyToClipboard = function handleCopyToClipboard() {
    var el = document.createElement("textarea");
    el.value = children;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return isJsx ? React.createElement(LiveProvider, Object.assign({
    code: children,
    mountStylesheet: false,
    scope: scope,
    style: {
      width: "100%",
      position: "relative",
      minWidth: "none"
    }
  }, props), React.createElement(LivePreview, null), React.createElement(Toolbar, null, React.createElement(CopyButton, {
    onClick: handleCopyToClipboard
  }, "Copy")), React.createElement(LiveEditor, null), React.createElement(LiveError, null)) : React.createElement(SimpleCodeBlock, {
    borderRadius: "md",
    bg: "grey.50",
    px: "1.5rem",
    py: "1.25rem"
  }, children);
};

export default CodeBlock;