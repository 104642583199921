import styled from "styled-components";
import { Span } from "@ds-kit/typography";
import { themeGet } from "@styled-system/theme-get";
export var Row = styled.div.withConfig({
  displayName: "Components__Row",
  componentId: "qhhm6p-0"
})(["", " ", "{display:flex;}"], function (p) {
  return p.isBlockViewOnMobile ? " display: block;" : "display: flex;";
}, themeGet("mediaQueries.sm"));
export var Cell = styled.div.withConfig({
  displayName: "Components__Cell",
  componentId: "qhhm6p-1"
})(["min-width:", ";align-items:center;padding:15px 15px;background:", ";:last-child{border-right:0;}", ";", ";", ";", "{width:1px;padding:15px 15px;background:", ";:last-child{border-right:0;}}"], function (p) {
  return p.cellWidth;
}, function (p) {
  return p.isEven && p.backgroundColor;
}, function (p) {
  return p.height && "height: " + p.height + "; padding: 0px 15px;";
}, function (p) {
  return p.paddingHorizontal && "padding: " + p.paddingHorizontal + " 15px";
}, function (p) {
  return p.isBlockViewOnMobile ? "width: 100%;  padding: 5px 15px; border-right: 0; background: none;" : "width: 1px";
}, themeGet("mediaQueries.sm"), function (p) {
  return p.isEven && p.backgroundColor;
});
export var Header = styled(Cell).withConfig({
  displayName: "Components__Header",
  componentId: "qhhm6p-2"
})(["border-right:0;", ";", "{border-bottom:2px solid #606062;}"], function (p) {
  return p.isBlockViewOnMobile ? "border-bottom: none;" : " border-bottom: 2px solid #606062;";
}, themeGet("mediaQueries.sm"));
export var IconSpan = styled.span.withConfig({
  displayName: "Components__IconSpan",
  componentId: "qhhm6p-3"
})(["opacity:0.3;height:20px;margin-top:-8px;display:block;opacity:", ";"], function (p) {
  return p.sorted && (p.sortedDesc ? p.variant === "desc" && 1 : p.variant !== "desc" && 1);
});
export var StyledSpan = styled(Span).withConfig({
  displayName: "Components__StyledSpan",
  componentId: "qhhm6p-4"
})(["display:inline-block;width:100%;"]);
export var Divider = styled.div.withConfig({
  displayName: "Components__Divider",
  componentId: "qhhm6p-5"
})(["height:10px;border-bottom:0.5px solid #ddd;", ";", "{display:none;}"], function (p) {
  return p.isBlockViewOnMobile ? "display: block;" : "display:none";
}, themeGet("mediaQueries.sm"));