import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import { P } from "@ds-kit/typography";
import Div from "@ds-kit/div";
import Container from "@ds-kit/container";
export var CalloutKicker = function CalloutKicker(_ref) {
  var children = _ref.children,
      restProps = _objectWithoutPropertiesLoose(_ref, ["children"]);

  return React.createElement(P, Object.assign({
    color: "white",
    fontWeight: 600,
    size: "md",
    mt: "0",
    mb: "0"
  }, restProps), children);
};
export var CalloutBody = function CalloutBody(_ref2) {
  var children = _ref2.children,
      restProps = _objectWithoutPropertiesLoose(_ref2, ["children"]);

  return React.createElement(P, Object.assign({
    color: "white",
    fontWeight: 600,
    size: "xxl",
    mt: "0",
    mb: "0",
    pt: 3
  }, restProps), children);
};
export var Callout = function Callout(_ref3) {
  var children = _ref3.children,
      _ref3$bg = _ref3.bg,
      bg = _ref3$bg === void 0 ? "primary" : _ref3$bg,
      _ref3$py = _ref3.py,
      py = _ref3$py === void 0 ? [7, 9] : _ref3$py,
      _ref3$size = _ref3.size,
      size = _ref3$size === void 0 ? "sm" : _ref3$size,
      restProps = _objectWithoutPropertiesLoose(_ref3, ["children", "bg", "py", "size"]);

  return React.createElement(Div, Object.assign({
    bg: bg,
    py: py
  }, restProps), React.createElement(Container, {
    size: size
  }, children));
};