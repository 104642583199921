var links = {
  default: {
    textDecoration: "underline",
    "&:focus,&:hover": {
      textDecoration: "underline"
    }
  },
  standalone: {
    fontWeight: 600,
    textDecoration: "none",
    "&:focus,&:hover": {
      textDecoration: "underline"
    }
  },
  stealth: {
    textDecoration: "none",
    "&:focus,&:hover": {
      textDecoration: "none"
    }
  }
};
export default links;