import colors from "./colors";
var inputs = {
  default: {
    borderColor: colors.grey["200"],
    "&:focus": {
      borderColor: colors.primary,
      boxShadow: "0 0 0 0.25rem rgba(81, 30, 233, 0.2)"
    }
  }
};
export default inputs;