import { system } from "styled-system";
export var mutedText = system({
  muted: {
    property: "opacity",
    scale: "mutedTextOpacities",
    defaultScale: [1, 0.6, 0.3],
    transform: function transform(value, scale) {
      return scale[value] || scale[1];
    }
  }
});