function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  html {\n    box-sizing: border-box;\n    font-family: \"Averta\", \"Averta PE\", system-ui, sans-serif;\n    font-size: 100%;\n    -webkit-font-smoothing: antialiased;\n    text-rendering: optimizeLegibility;\n  }\n  *,\n  *::before,\n  *::after {\n    box-sizing: inherit;\n  }\n  body {\n    margin: 0;\n    font-size: 1rem;\n    line-height: 1.5;\n    color: ", ";\n    background: ", ";\n  }\n  a {\n    color: inherit;\n    text-decoration: none;\n  }\n  img,\n  svg {\n    display: inline-block;\n    vertical-align: middle;\n    max-width: 100%;\n    height: auto;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }

import { createGlobalStyle } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
var GlobalStyles = createGlobalStyle(_templateObject(), themeGet("colors.text", "#222"), themeGet("colors.background", "#FFF"));
export default GlobalStyles;