import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import PropTypes from "prop-types";
import styled from "styled-components";
import { typography, borderColor, space, borderRadius, system, variant, boxShadow } from "styled-system";
var inputVariants = variant({
  scale: "inputs",
  prop: "variant"
});
var placeholderColor = system({
  placeholderColor: {
    property: "color",
    scale: "colors"
  }
});
var raisedBoxShadow = system({
  raisedBoxShadow: {
    property: "boxShadow",
    scale: "shadows"
  }
});
var inputSize = system({
  size: {
    property: "height",
    scale: "inputSizes",
    defaultScale: {
      sm: "2rem",
      md: "2.5rem",
      lg: "3rem"
    }
  },
  width: {
    property: "width",
    scale: "widths"
  }
});
var inputPadding = system({
  size: {
    properties: ["paddingLeft", "paddingRight"],
    scale: "inputPaddings",
    defaultScale: {
      sm: "0.75rem",
      md: "1rem",
      lg: "1.25rem"
    }
  }
});
var Textfield = styled("input").attrs(function (props) {
  var attrs = props.raised && {
    borderColor: props.borderColor || "transparent",
    boxShadow: "sm",
    raisedBoxShadow: "md"
  };
  return _objectSpread({}, attrs);
}).withConfig({
  displayName: "src__Textfield",
  componentId: "krd57c-0"
})(["-webkit-appearance:none;-moz-appearance:none;display:inline-block;vertical-align:middle;border:0.125rem solid transparent;outline:none;&::-webkit-inner-spin-button,&::-webkit-outer-spin-button{-webkit-appearance:none;margin:0;}&::-webkit-input-placeholder{", "}&:-ms-input-placeholder{", "}&::placeholder{", "}", " ", " ", " ", " ", " ", " ", " ", " &:focus{", "}"], placeholderColor, placeholderColor, placeholderColor, inputVariants, inputSize, inputPadding, typography, space, borderRadius, borderColor, boxShadow, raisedBoxShadow);
Textfield.defaultProps = {
  size: "md",
  variant: "default",
  fontSize: "inherit",
  fontFamily: "inherit",
  lineHeight: "normal",
  borderRadius: "md"
};
Textfield.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fontFamily: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};
export default Textfield;