import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React, { useEffect } from "react";
import { useCheckboxState, Checkbox as BaseCheckbox } from "reakit/Checkbox";

var Checkbox = function Checkbox(_ref) {
  var state = _ref.state,
      checked = _ref.checked,
      onChange = _ref.onChange,
      text = _ref.text,
      props = _objectWithoutPropertiesLoose(_ref, ["state", "checked", "onChange", "text"]);

  var checkbox = useCheckboxState({
    state: state
  });
  useEffect(function () {
    checkbox.setState(checked);
  }, [checked]);
  return React.createElement("label", null, React.createElement(BaseCheckbox, Object.assign({}, props, checkbox, {
    onChange: onChange,
    checked: checked
  })), text);
};

export default Checkbox;