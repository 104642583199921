import React from "react";
import styled from "styled-components";
import { width, maxWidth, space } from "styled-system";
var carouselVariants = {
  narrow: "57rem",
  default: "87rem",
  full: "100%"
};
var CarouselWrapper = styled.div.withConfig({
  displayName: "src__CarouselWrapper",
  componentId: "t1xikl-0"
})(["overflow-y:hidden;position:relative;"]);
var Overlay = styled.div.withConfig({
  displayName: "src__Overlay",
  componentId: "t1xikl-1"
})(["position:absolute;top:0;right:0;width:20%;max-width:6rem;height:100%;pointer-events:none;z-index:99;background:rgb(", ");background:linear-gradient( 90deg,rgba(", ",0) 0%,rgba(", ",1) 100% );"], function (p) {
  return p.fadeColor;
}, function (p) {
  return p.fadeColor;
}, function (p) {
  return p.fadeColor;
});
Overlay.defaultProps = {
  fadeColor: "255,255,255"
};
var StyledCarousel = styled.div.withConfig({
  displayName: "src__StyledCarousel",
  componentId: "t1xikl-2"
})(["width:100%;display:flex;margin:0 auto;white-space:nowrap;overflow-x:auto !important;scroll-behavior:smooth;-webkit-overflow-scrolling:touch;padding-bottom:2rem;margin-bottom:-2rem;background:transparent;&::before,&::after{content:\"\";display:inline-flex;flex:none;background:transparent;width:", ";min-width:", ";height:10px;}"], function (p) {
  return "calc((100% - " + (p.maxWidth || carouselVariants[p.variant]) + " + 1rem) / 2)";
}, function (p) {
  return p.variant === "full" ? "none" : "0.5rem";
});
StyledCarousel.defaultProps = {
  variant: "default"
};
var StyledCarouselItem = styled.div.withConfig({
  displayName: "src__StyledCarouselItem",
  componentId: "t1xikl-3"
})(["display:inline-flex;flex:none;width:100%;max-width:calc(100% - 2rem);padding:1rem 1rem 1rem 0;&:first-child{margin-left:1rem;}", " ", " ", ""], width, maxWidth, space);
export var CarouselItem = function CarouselItem(props) {
  return React.createElement(StyledCarouselItem, props);
};

/**
 * Carousels can be used for vertically overflowing content.
 */
export var Carousel = function Carousel(_ref) {
  var children = _ref.children,
      variant = _ref.variant,
      maxWidth = _ref.maxWidth,
      fade = _ref.fade,
      fadeColor = _ref.fadeColor;
  return React.createElement(CarouselWrapper, null, React.createElement(StyledCarousel, {
    variant: variant,
    maxWidth: maxWidth
  }, children), fade && React.createElement(Overlay, {
    fadeColor: fadeColor
  }));
};