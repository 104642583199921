import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import styled from "styled-components";
import { color, space } from "styled-system";
import { Grid, GridItem } from "@ds-kit/grid";
import { Heading, P } from "@ds-kit/typography";
import Container from "@ds-kit/container";
import RatioContainer from "@ds-kit/ratio-container";
var StyledPageHeader = styled.div.withConfig({
  displayName: "src__StyledPageHeader",
  componentId: "sc-1ty9p97-0"
})(["", " ", ""], color, space);
var ImageWrapper = styled.div.withConfig({
  displayName: "src__ImageWrapper",
  componentId: "sc-1ty9p97-1"
})(["width:100%;height:100%;overflow:hidden;display:flex;align-items:center;justify-content:center;img{display:block;height:100%;width:auto;max-width:none;}"]);

var PageHeader = function PageHeader(_ref) {
  var title = _ref.title,
      subtitle = _ref.subtitle,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      image = _ref.image,
      _ref$imageProps = _ref.imageProps,
      imageProps = _ref$imageProps === void 0 ? {} : _ref$imageProps,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? "white" : _ref$bg,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "inherit" : _ref$color,
      _ref$titleColor = _ref.titleColor,
      titleColor = _ref$titleColor === void 0 ? "inherit" : _ref$titleColor,
      _ref$subtitleColor = _ref.subtitleColor,
      subtitleColor = _ref$subtitleColor === void 0 ? "inherit" : _ref$subtitleColor,
      children = _ref.children,
      restProps = _objectWithoutPropertiesLoose(_ref, ["title", "subtitle", "size", "image", "imageProps", "bg", "color", "titleColor", "subtitleColor", "children"]);

  return React.createElement(StyledPageHeader, Object.assign({
    bg: bg,
    color: color
  }, restProps), React.createElement(Container, {
    size: size
  }, React.createElement(Grid, null, React.createElement(GridItem, {
    gridRow: ["2", null, "1"],
    gridColumn: ["span 12", null, "1 / span 6"],
    pt: ["1.25rem", null, "5rem"],
    pb: "1.25rem",
    alignSelf: "end",
    style: {
      zIndex: 1
    }
  }, children ? children : React.createElement(React.Fragment, null, title && React.createElement(Heading, {
    my: "0",
    color: titleColor
  }, title), subtitle && React.createElement(P, {
    size: "lg",
    my: "1.25rem",
    color: subtitleColor
  }, subtitle))), image && React.createElement(GridItem, {
    gridRow: ["1", null, "1"],
    gridColumn: ["span 12", null, "6 / span 7"],
    alignSelf: "center"
  }, React.createElement(RatioContainer, {
    ratio: 9 / 16,
    bg: "transparent"
  }, React.createElement(ImageWrapper, null, React.createElement("img", Object.assign({
    src: image
  }, imageProps))))))));
};

export default PageHeader;