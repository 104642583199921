import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import React from 'react';
import styled from 'styled-components';
import { layout, position, space, color, textAlign, borderRadius, overflow, boxShadow, system } from 'styled-system';
import 'core-js/modules/web.dom.iterable';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

var filterProps = function filterProps(props, allPropNames) {
  return Object.keys(props).reduce(function (acc, cur) {
    if (allPropNames.indexOf(cur) === -1) acc[cur] = props[cur];
    return acc;
  }, {});
};

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  display: block;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  transform: ", ";\n  transition: transform 250ms ease, box-shadow 250ms ease;\n  &:hover,\n  &:focus,\n  a:hover &,\n  a:focus & {\n    ", "\n    transform: ", ";\n  }\n  &:active,\n  a:active & {\n    ", "\n    transform: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var allPropNames = [].concat(layout.propNames, position.propNames, space.propNames, color.propNames, textAlign.propNames, borderRadius.propNames, overflow.propNames, boxShadow.propNames, ["is", "boxShadowHover", "interactive", "raised"]);
var boxShadowHover = system({
  boxShadowHover: {
    property: "box-shadow",
    scale: "shadows"
  }
});

var configureAttributes = function configureAttributes(props) {
  return {
    boxShadow: props.raised ? "sm" : null,
    boxShadowHover: props.raised && props.interactive ? "md" : null
  };
};

var StyledCard = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(props.is || "div", filteredProps);
}).attrs(configureAttributes).withConfig({
  displayName: "indexes__StyledCard",
  componentId: "sc-1qb0aqz-0"
})(_templateObject(), layout, position, space, color, textAlign, borderRadius, overflow, boxShadow, function (p) {
  return p.raised && p.interactive ? "translateY(0)" : null;
}, boxShadowHover, function (p) {
  return p.raised && p.interactive ? "translateY(-0.125rem)" : null;
}, boxShadow, function (p) {
  return p.raised && p.interactive ? "translateY(0)" : null;
});

var Card = function Card(_ref) {
  var as = _ref.as,
      restProps = _objectWithoutProperties(_ref, ["as"]);

  return React.createElement(StyledCard, _extends({
    is: as
  }, restProps));
};

export default Card;
export { Card };