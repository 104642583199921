import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { system, space, typography, color, fontSize, fontWeight, textAlign, lineHeight, maxWidth } from 'styled-system';
import 'core-js/modules/web.dom.iterable';

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

var filterProps = function filterProps(props, allPropNames) {
  return Object.keys(props).reduce(function (acc, cur) {
    if (allPropNames.indexOf(cur) === -1) acc[cur] = props[cur];
    return acc;
  }, {});
};

var mutedText = system({
  muted: {
    property: "opacity",
    scale: "mutedTextOpacities",
    defaultScale: [1, 0.6, 0.3],
    transform: function transform(value, scale) {
      return scale[value] || scale[1];
    }
  }
});

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  margin-top: 1em;\n  margin-bottom: 0.5em;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var allPropNames = [].concat(space.propNames, typography.propNames, color.propNames, ["size", "muted", "is", "textTransform"]);
var textTransform = system({
  textTransform: {
    property: "textTransform"
  }
});
var StyledHeading = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(props.is || "h1", filteredProps);
}).attrs(function (props) {
  return {
    fontSize: props.size ? props.theme.responsiveFontSizes[props.size] : props.fontSize
  };
}).withConfig({
  displayName: "indexes__StyledHeading",
  componentId: "edgbxe-0"
})(_templateObject(), color, typography, space, mutedText, textTransform);

var Heading = function Heading(_ref) {
  var as = _ref.as,
      restProps = _objectWithoutProperties(_ref, ["as"]); // Note the use of `is` in order to bypass the styled-components
  // `as` prop... The top-level api can stay the same this way.


  return React.createElement(StyledHeading, _extends({
    is: as
  }, restProps));
};

Heading.defaultProps = {
  fontSize: "xxxl",
  lineHeight: 1.125
};

function _templateObject$1() {
  var data = _taggedTemplateLiteralLoose(["\n  margin-top: 0;\n  margin-bottom: 0;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject$1 = function _templateObject() {
    return data;
  };

  return data;
}

var allPropNames$1 = [].concat(space.propNames, typography.propNames, color.propNames, ["size", "muted", "is", "textTransform"]);
var textTransform$1 = system({
  textTransform: {
    property: "textTransform"
  }
});
var StyledSpan = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames$1);
  return React.createElement(props.is || "span", filteredProps);
}).attrs(function (props) {
  return {
    fontSize: props.size ? props.theme.responsiveFontSizes[props.size] : props.fontSize
  };
}).withConfig({
  displayName: "indexes__StyledSpan",
  componentId: "edgbxe-1"
})(_templateObject$1(), color, typography, space, mutedText, textTransform$1);

var Span = function Span(_ref) {
  var as = _ref.as,
      restProps = _objectWithoutProperties(_ref, ["as"]); // Note the use of `is` in order to bypass the styled-components
  // `as` prop... The top-level api can stay the same this way.


  return React.createElement(StyledSpan, _extends({
    is: as
  }, restProps));
};

Span.defaultProps = {
  fontSize: "inherit",
  lineHeight: "inherit"
};

function _templateObject$2() {
  var data = _taggedTemplateLiteralLoose(["\n  margin-top: 0;\n  margin-bottom: 1.5em;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject$2 = function _templateObject() {
    return data;
  };

  return data;
}

var allPropNames$2 = [].concat(space.propNames, typography.propNames, color.propNames, ["size", "muted", "is", "textTransform"]);
var textTransform$2 = system({
  textTransform: {
    property: "textTransform"
  }
});
var StyledParagraph = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames$2);
  return React.createElement(props.is || "p", filteredProps);
}).attrs(function (props) {
  return {
    fontSize: props.size ? props.theme.responsiveFontSizes[props.size] : props.fontSize
  };
}).withConfig({
  displayName: "indexes__StyledParagraph",
  componentId: "edgbxe-2"
})(_templateObject$2(), color, typography, space, mutedText, textTransform$2);

var Paragraph = function Paragraph(_ref) {
  var as = _ref.as,
      restProps = _objectWithoutProperties(_ref, ["as"]); // Note the use of `is` in order to bypass the styled-components
  // `as` prop... The top-level api can stay the same this way.


  return React.createElement(StyledParagraph, _extends({
    is: as
  }, restProps));
};

Paragraph.defaultProps = {
  fontSize: "inherit",
  lineHeight: "inherit"
};

function _templateObject2() {
  var data = _taggedTemplateLiteralLoose(["\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject$3() {
  var data = _taggedTemplateLiteralLoose(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject$3 = function _templateObject() {
    return data;
  };

  return data;
}

var Text = styled.span.withConfig({
  displayName: "indexes__Text",
  componentId: "edgbxe-3"
})(_templateObject$3(), fontSize, fontWeight, textAlign, lineHeight, space, color, maxWidth, function (p) {
  return p.truncated && css(_templateObject2());
});
Text.propTypes = _objectSpread2({
  truncated: PropTypes.bool
}, fontSize.propTypes, {}, fontWeight.propTypes, {}, textAlign.propTypes, {}, lineHeight.propTypes, {}, space.propTypes, {}, color.propTypes, {}, maxWidth.propTypes);
export { Heading, Paragraph as P, Span, Text };