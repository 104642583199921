import React from "react";
import { CSVLink } from "react-csv";
import Button from "@ds-kit/button";
import Icon from "@ds-kit/icon";
import Div from "@ds-kit/div";

var translateColumnsIntoHeaders = function translateColumnsIntoHeaders(columns) {
  return columns.map(function (i) {
    return {
      label: i.Header,
      key: i.accessor
    };
  });
};

var Download = function Download(_ref) {
  var data = _ref.data,
      columns = _ref.columns;
  if (!Array.isArray(data) || !Array.isArray(columns)) return;
  return React.createElement(Div, {
    pt: "0.75rem",
    textAlign: "center"
  }, React.createElement(CSVLink, {
    data: data,
    headers: translateColumnsIntoHeaders(columns)
  }, React.createElement(Button, {
    circle: true
  }, React.createElement(Icon, {
    name: "download"
  }))));
};

export default Download;