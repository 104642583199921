import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { borderColor, borderRadius } from "styled-system";
import { Span } from "@ds-kit/typography";
import Icon from "@ds-kit/icon";
import { filterProps } from "../../../src/utils/filterProps";
var allPropNames = [].concat(borderRadius.propNames, borderColor.propNames);
var StyledLozenge = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(Span, filteredProps);
}).withConfig({
  displayName: "src__StyledLozenge",
  componentId: "yl1v54-0"
})(["display:inline-flex;align-items:center;vertical-align:middle;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;border:0.0625rem solid;max-width:100%;", " ", ""], borderColor, borderRadius);

var Lozenge = function Lozenge(_ref) {
  var children = _ref.children,
      iconName = _ref.iconName,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "xs" : _ref$size,
      _ref$lineHeight = _ref.lineHeight,
      lineHeight = _ref$lineHeight === void 0 ? "dense" : _ref$lineHeight,
      _ref$fontWeight = _ref.fontWeight,
      fontWeight = _ref$fontWeight === void 0 ? 600 : _ref$fontWeight,
      _ref$borderRadius = _ref.borderRadius,
      borderRadius = _ref$borderRadius === void 0 ? "md" : _ref$borderRadius,
      _ref$borderColor = _ref.borderColor,
      borderColor = _ref$borderColor === void 0 ? "blurble.300" : _ref$borderColor,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "blurble.600" : _ref$color,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? "blurble.50" : _ref$bg,
      _ref$px = _ref.px,
      px = _ref$px === void 0 ? "0.5rem" : _ref$px,
      _ref$py = _ref.py,
      py = _ref$py === void 0 ? "0.25rem" : _ref$py,
      restProps = _objectWithoutPropertiesLoose(_ref, ["children", "iconName", "size", "lineHeight", "fontWeight", "borderRadius", "borderColor", "color", "bg", "px", "py"]);

  return React.createElement(StyledLozenge, Object.assign({
    size: size,
    bg: bg,
    color: color,
    borderRadius: borderRadius,
    borderColor: borderColor,
    lineHeight: lineHeight,
    fontWeight: fontWeight,
    py: py,
    px: px
  }, restProps), iconName && React.createElement(Icon, {
    size: "1em",
    name: iconName,
    mr: "0.5rem"
  }), children);
};

export default Lozenge;