import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

/* eslint-disable no-unused-vars */
import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
var StyledMultiValueRemove = styled.div.withConfig({
  displayName: "MultiValueRemove__StyledMultiValueRemove",
  componentId: "q2rv8i-0"
})(["cursor:pointer;padding:0 0.25rem;height:1.75em;width:1.75em;display:flex;flex:none;align-items:center;justify-content:center;color:", ";&:hover,&:focus{background-color:", ";}"], themeGet("colors.grey.600"), themeGet("colors.grey.200"));

var MultiValueRemove = function MultiValueRemove(_ref) {
  var innerProps = _ref.innerProps;

  var className = innerProps.className,
      restProps = _objectWithoutPropertiesLoose(innerProps, ["className"]);

  return React.createElement(StyledMultiValueRemove, restProps, React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "16",
    height: "16",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "3",
    strokeLinecap: "butt",
    strokeLinejoin: "arcs"
  }, React.createElement("line", {
    x1: "18",
    y1: "6",
    x2: "6",
    y2: "18"
  }), React.createElement("line", {
    x1: "6",
    y1: "6",
    x2: "18",
    y2: "18"
  })));
};

export default MultiValueRemove;