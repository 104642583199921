import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { color, fontSize, lineHeight, height, space, maxWidth } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
var sizes = {
  sm: {
    height: "4.75rem",
    fontSize: "0.875rem",
    px: "0.75rem",
    py: "0.75rem"
  },
  md: {
    height: "5.5rem",
    fontSize: "1rem",
    px: "1rem",
    py: "1rem"
  },
  lg: {
    height: "6.25rem",
    fontSize: "1.25rem",
    px: "1.5rem",
    py: "1.25rem"
  }
};
var StyledTextarea = styled.textarea.withConfig({
  displayName: "src__StyledTextarea",
  componentId: "og3ik0-0"
})(["", ";", ";", ";", ";", ";", ";", ";", ";resize:vertical;border-radius:", ";border:0.125rem solid ", ";&::placeholder{color:", ";opacity:0.5;}&:focus{outline:none;border-color:", ";box-shadow:0 0 0 0.3125rem ", ";}"], color, fontSize, fontSize, lineHeight, height, space, color, maxWidth, function (p) {
  return p.pill ? "99rem" : "0.1875rem";
}, function (p) {
  return themeGet("colors." + p.borderColor, p.borderColor)(p);
}, function (p) {
  return themeGet("colors." + p.placeholderColor, p.placeholderColor)(p);
}, function (p) {
  return themeGet("colors." + p.hoverBorderColor, p.hoverBorderColor)(p);
}, function (p) {
  return themeGet("colors." + p.hoverBorderOutline, p.hoverBorderOutline)(p);
});

function Textarea(_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? "md" : _ref$size,
      fontSize = _ref.fontSize,
      px = _ref.px,
      py = _ref.py,
      height = _ref.height,
      _ref$width = _ref.width,
      width = _ref$width === void 0 ? "100%" : _ref$width,
      value = _ref.value,
      _ref$borderColor = _ref.borderColor,
      borderColor = _ref$borderColor === void 0 ? "grey.200" : _ref$borderColor,
      _ref$hoverBorderColor = _ref.hoverBorderColor,
      hoverBorderColor = _ref$hoverBorderColor === void 0 ? "primary" : _ref$hoverBorderColor,
      _ref$hoverBorderOutli = _ref.hoverBorderOutline,
      hoverBorderOutline = _ref$hoverBorderOutli === void 0 ? "rgba(81,30,233,0.2)" : _ref$hoverBorderOutli,
      _ref$placeholderColor = _ref.placeholderColor,
      placeholderColor = _ref$placeholderColor === void 0 ? "grey.700" : _ref$placeholderColor,
      _ref$lineHeight = _ref.lineHeight,
      lineHeight = _ref$lineHeight === void 0 ? "1.25" : _ref$lineHeight,
      _ref$onChange = _ref.onChange,
      onChange = _ref$onChange === void 0 ? function () {} : _ref$onChange,
      props = _objectWithoutPropertiesLoose(_ref, ["size", "fontSize", "px", "py", "height", "width", "value", "borderColor", "hoverBorderColor", "hoverBorderOutline", "placeholderColor", "lineHeight", "onChange"]);

  return React.createElement(StyledTextarea, Object.assign({
    onChange: onChange,
    fontSize: fontSize || sizes[size].fontSize,
    px: px || sizes[size].px,
    py: py || sizes[size].py,
    width: width,
    height: height || sizes[size].height,
    lineHeight: lineHeight,
    value: value,
    borderColor: borderColor,
    hoverBorderColor: hoverBorderColor,
    hoverBorderOutline: hoverBorderOutline,
    placeholderColor: placeholderColor
  }, props));
}

export default Textarea;