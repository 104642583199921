import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { space, color, typography, background, border } from "styled-system";
var StyledUl = styled.ul.withConfig({
  displayName: "src__StyledUl",
  componentId: "sc-19v5fcf-0"
})(["", ""], space);
var StyledOl = styled.ol.withConfig({
  displayName: "src__StyledOl",
  componentId: "sc-19v5fcf-1"
})(["", ""], space);
var StyledLi = styled.li.attrs(function (props) {
  return {
    fontSize: props.size ? props.theme.responsiveFontSizes[props.size] : props.fontSize
  };
}).withConfig({
  displayName: "src__StyledLi",
  componentId: "sc-19v5fcf-2"
})(["", ";", ";", ";", ";"], color, typography, background, border);
export var Ul = function Ul(_ref) {
  var children = _ref.children,
      _ref$fontSize = _ref.fontSize,
      fontSize = _ref$fontSize === void 0 ? "inherit" : _ref$fontSize,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "fontSize"]);

  return React.createElement(StyledUl, Object.assign({
    fontSize: fontSize
  }, props), children);
};
export var Ol = function Ol(_ref2) {
  var children = _ref2.children,
      props = _objectWithoutPropertiesLoose(_ref2, ["children"]);

  return React.createElement(StyledOl, props, children);
};
export var Li = function Li(_ref3) {
  var children = _ref3.children,
      props = _objectWithoutPropertiesLoose(_ref3, ["children"]);

  return React.createElement(StyledLi, props, children);
};