import React from "react";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import Link from "../../../packages/Link/src";

var SiteFooter = function SiteFooter() {
  return React.createElement(Div, {
    bg: "grey.900",
    color: "grey.50",
    py: "2.5rem"
  }, React.createElement(Container, null, React.createElement(Link, {
    href: "https://www.datastory.org",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Go to datastory.org")));
};

export default SiteFooter;