import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable import/max-dependencies */
import formatFNS from "date-fns/format";
import formatDistanceFNS from "date-fns/formatDistance";
import formatDistanceStrictFNS from "date-fns/formatDistanceStrict";
import formatRelativeFNS from "date-fns/formatRelative";
import formatDistanceToNowFNS from "date-fns/formatDistanceToNow";
import parseFNS from "date-fns/parse";
import startOfWeekFNS from "date-fns/startOfWeek";
import subDaysFNS from "date-fns/subDays";
import differenceInCalendarDaysFNS from "date-fns/differenceInCalendarDays"; // List of supported languages for date-fns v2

import en from "date-fns/locale/en-US";
import sv from "date-fns/locale/sv";
import zh from "date-fns/locale/zh-CN";
import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import ar from "date-fns/locale/ar-DZ";
import ru from "date-fns/locale/ru";
import id from "date-fns/locale/id";
import ja from "date-fns/locale/ja";
import pt from "date-fns/locale/pt";
import pl from "date-fns/locale/pl";
import th from "date-fns/locale/th";
import tr from "date-fns/locale/tr";
import fi from "date-fns/locale/fi";
import da from "date-fns/locale/da";
import no from "date-fns/locale/nb";
import vi from "date-fns/locale/vi";
import fa from "date-fns/locale/fa-IR";
import it from "date-fns/locale/it";
import hi from "date-fns/locale/hi";
var locales = {
  ar: ar,
  da: da,
  de: de,
  en: en,
  es: es,
  fa: fa,
  fi: fi,
  fr: fr,
  id: id,
  it: it,
  ja: ja,
  no: no,
  pl: pl,
  pt: pt,
  ru: ru,
  sv: sv,
  th: th,
  tr: tr,
  vi: vi,
  zh: zh,
  hi: hi
};
var locale = en;
var defaultLocale = en;
export var setLocale = function setLocale(value) {
  return locale = locales[value] ? locales[value] : defaultLocale;
};
export var format = function format(date, _format, options) {
  if (options === void 0) {
    options = {};
  }

  return formatFNS(date, _format, _objectSpread({
    locale: locale
  }, options));
};
export var formatDistance = function formatDistance(date, baseDate, options) {
  if (options === void 0) {
    options = {};
  }

  return formatDistanceFNS(date, baseDate, _objectSpread({
    locale: locale
  }, options));
};
export var formatDistanceStrict = function formatDistanceStrict(date, baseDate, options) {
  if (options === void 0) {
    options = {};
  }

  return formatDistanceStrictFNS(date, baseDate, _objectSpread({
    locale: locale
  }, options));
};
export var formatRelative = function formatRelative(date, baseDate, options) {
  if (options === void 0) {
    options = {};
  }

  return formatRelativeFNS(date, baseDate, _objectSpread({
    locale: locale
  }, options));
};
export var parse = function parse(dateString, formatString, backupDate, options) {
  if (options === void 0) {
    options = {};
  }

  return parseFNS(dateString, formatString, backupDate, _objectSpread({
    locale: locale
  }, options));
};
export var startOfWeek = function startOfWeek(date, options) {
  if (options === void 0) {
    options = {};
  }

  return startOfWeekFNS(date, _objectSpread({
    locale: locale
  }, options));
};
export var formatDistanceToNow = function formatDistanceToNow(date, options) {
  if (options === void 0) {
    options = {};
  }

  return formatDistanceToNowFNS(date, _objectSpread({
    locale: locale
  }, options));
};
export var subDays = function subDays(date, amount) {
  return subDaysFNS(date, amount);
};
export var differenceInCalendarDays = function differenceInCalendarDays(dateLeft, dateRight) {
  return differenceInCalendarDaysFNS(dateLeft, dateRight);
};