import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _taggedTemplateLiteralLoose(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  strings.raw = raw;
  return strings;
}

function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  display: flex;\n  align-items: center;\n  font-size: ", ";\n  > svg {\n    flex: none;\n    width: ", ";\n    height: ", ";\n  }\n  > span {\n    font-size: 0.4em;\n    font-weight: 700;\n    margin-left: 0.25em;\n    color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledLogo = styled.div.withConfig({
  displayName: "indexes__StyledLogo",
  componentId: "lkj007-0"
})(_templateObject(), function (p) {
  return p.size;
}, function (p) {
  return p.size;
}, function (p) {
  return p.size;
}, function (p) {
  return themeGet("colors." + p.textColor)(p);
});
var Badge = withTheme(function (_ref) {
  var _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? "#511EE9" : _ref$bg,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "#FFFFFF" : _ref$color,
      outlined = _ref.outlined,
      restProps = _objectWithoutProperties(_ref, ["bg", "color", "outlined"]);

  return React.createElement("svg", {
    viewBox: "0 0 40 40",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("g", {
    fill: "none",
    fillRule: "evenodd"
  }, React.createElement("path", {
    d: "M37.9948772,20 C38.1976494,13.985361 30.9959318,8 26.9965345,6 C20.2927097,2.6475825 14.1367713,2.28364626 9.99909586,5 C5.86142038,7.71635374 3.9506763,13.4485353 3.99999986,20 C4.04932342,26.5514647 6.60279727,32.4523922 9.99909586,35 C13.9984932,38 20.1011023,37.4482356 26.9965345,34 C30.9959318,32 37.7921049,26.014639 37.9948772,20 Z",
    fill: outlined ? "none" : themeGet("colors." + bg, bg)(restProps),
    stroke: outlined ? themeGet("colors." + color, color)(restProps) : "none",
    strokeWidth: outlined ? 2 : 0
  }), React.createElement("path", {
    d: "M19.644,11.416959 C20.8,11.65 21.8,12 22.941,12.440009 L22.941,27.0114857 L19.812,27.0114857 L19.812,25.9194857 L19.77,25.9194857 C19.098,26.7594857 18.132,27.2004857 16.851,27.2004857 C14.016,27.2004857 12,25.0584857 12,21.8874857 C12,18.7374857 14.037,16.5534857 16.683,16.5534857 C17.838,16.5534857 18.888,16.9944857 19.623,17.8344857 L19.644,17.8344857 L19.644,11.416959 Z M17.523,24.3024857 C18.804,24.3024857 19.77,23.3574857 19.77,21.8874857 C19.77,20.4804857 18.846,19.4514857 17.523,19.4514857 C16.284,19.4514857 15.318,20.4174857 15.318,21.8874857 C15.318,23.3364857 16.242,24.3024857 17.523,24.3024857 Z",
    fill: themeGet("colors." + color, color)(restProps)
  }), React.createElement("path", {
    d: "M26.205,27.2004857 C25.155,27.2004857 24.315,26.3814857 24.315,25.3524857 C24.315,24.3234857 25.155,23.4834857 26.205,23.4834857 C27.234,23.4834857 28.095,24.3234857 28.095,25.3524857 C28.095,26.3814857 27.234,27.2004857 26.205,27.2004857 Z",
    fill: themeGet("colors." + color, color)(restProps),
    fillRule: "nonzero"
  })));
});

var Logo = function Logo(_ref2) {
  var _ref2$bg = _ref2.bg,
      bg = _ref2$bg === void 0 ? "primary" : _ref2$bg,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? "white" : _ref2$color,
      text = _ref2.text,
      _ref2$textColor = _ref2.textColor,
      textColor = _ref2$textColor === void 0 ? "text" : _ref2$textColor,
      _ref2$size = _ref2.size,
      size = _ref2$size === void 0 ? "2.5rem" : _ref2$size,
      outlined = _ref2.outlined;
  return React.createElement(StyledLogo, {
    size: size,
    textColor: textColor
  }, React.createElement(Badge, {
    bg: bg,
    color: color,
    outlined: outlined
  }), text && React.createElement("span", null, text));
};

export default Logo;