import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.string.link";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import Div from "@ds-kit/div";
import Icon from "@ds-kit/icon";
var Ol = styled("ol").withConfig({
  displayName: "src__Ol",
  componentId: "ds8a3r-0"
})(["list-style:none;display:flex;margin:0;padding:0;align-items:center;"]);
var Crumb = styled("li").withConfig({
  displayName: "src__Crumb",
  componentId: "ds8a3r-1"
})(["overflow:hidden;display:flex;flex:0 1 auto;line-height:1.25;&:first-child{flex:none;}>:first-child{white-space:nowrap;text-overflow:ellipsis;overflow:hidden;}"]);

var Breadcrumbs = function Breadcrumbs(_ref) {
  var links = _ref.links,
      link = _ref.link,
      restProps = _objectWithoutPropertiesLoose(_ref, ["links", "link"]);

  return React.createElement(Div, Object.assign({
    as: "nav",
    "aria-label": "Breadcrumbs"
  }, restProps), React.createElement(Ol, null, links.map(function (d, i) {
    var isLast = i === links.length - 1;
    var p = isLast ? _objectSpread({}, d, {
      "aria-current": "page"
    }) : d;
    return React.createElement(Crumb, {
      key: "breadcrumb-" + i
    }, link ? link(p, isLast) : React.createElement("a", p, p.label), !isLast && React.createElement("span", {
      style: {
        flex: "none",
        margin: "0 0.25rem"
      }
    }, React.createElement(Icon, {
      size: "1.25rem",
      name: "chevron_right"
    })));
  })));
};

export default Breadcrumbs;