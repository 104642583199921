import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { color, fontSize, borderRadius, padding, margin, layout } from "styled-system";
import { useTooltipState, Tooltip as ReakitTooltip, TooltipReference } from "reakit/Tooltip";
import { filterProps } from "../../../src/utils/filterProps";
var allPropNames = [].concat(borderRadius.propNames, fontSize.propNames, color.propNames, padding.propNames, margin.propNames, layout.propNames);
var StyledReakitTooltip = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(ReakitTooltip, filteredProps);
}).withConfig({
  displayName: "src__StyledReakitTooltip",
  componentId: "kgmzgu-0"
})(["position:absolute;", ";", ";", ";", ";", " ", " z-index:999;margin-top:0.25rem;"], color, fontSize, borderRadius, padding, margin, layout);

function Tooltip(_ref) {
  var children = _ref.children,
      title = _ref.title,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? "rgba(33, 33, 33, 0.9)" : _ref$bg,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? "white" : _ref$color,
      _ref$fontSize = _ref.fontSize,
      fontSize = _ref$fontSize === void 0 ? "0.8em" : _ref$fontSize,
      _ref$borderRadius = _ref.borderRadius,
      borderRadius = _ref$borderRadius === void 0 ? "0.25rem" : _ref$borderRadius,
      _ref$padding = _ref.padding,
      padding = _ref$padding === void 0 ? "0.5rem" : _ref$padding,
      _ref$placement = _ref.placement,
      placement = _ref$placement === void 0 ? "top-center" : _ref$placement,
      _ref$visible = _ref.visible,
      visible = _ref$visible === void 0 ? false : _ref$visible,
      _ref$boundariesElemen = _ref.boundariesElement,
      boundariesElement = _ref$boundariesElemen === void 0 ? "scrollParent" : _ref$boundariesElemen,
      _ref$maxWidth = _ref.maxWidth,
      maxWidth = _ref$maxWidth === void 0 ? "none" : _ref$maxWidth,
      props = _objectWithoutPropertiesLoose(_ref, ["children", "title", "bg", "color", "fontSize", "borderRadius", "padding", "placement", "visible", "boundariesElement", "maxWidth"]);

  var tooltip = useTooltipState({
    placement: placement,
    visible: visible,
    unstable_boundariesElement: boundariesElement
  });
  return React.createElement(React.Fragment, null, React.createElement(TooltipReference, tooltip, function (referenceProps) {
    return React.cloneElement(React.Children.only(React.createElement("span", null, children)), referenceProps);
  }), React.createElement(StyledReakitTooltip, Object.assign({
    bg: bg,
    color: color,
    fontSize: fontSize,
    borderRadius: borderRadius,
    padding: padding,
    maxWidth: maxWidth
  }, tooltip, props), title));
}

export default Tooltip;