import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React from "react";
import styled from "styled-components";
import { space, width, maxWidth, color, height, fontSize } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
var sizes = {
  sm: {
    height: "1.75rem",
    fontSize: "0.875rem",
    pl: "1rem",
    pr: "2.25rem"
  },
  md: {
    height: "2.5rem",
    fontSize: "1rem",
    pl: "1.5rem",
    pr: "2.75rem"
  },
  lg: {
    height: "3.25rem",
    fontSize: "1.25rem",
    pl: "2rem",
    pr: "3.25rem"
  }
};
var arrowSizes = {
  sm: {
    width: "1rem"
  },
  md: {
    width: "1.25rem"
  },
  lg: {
    width: "1.5rem"
  }
};
var offsets = {
  sm: {
    marginTop: "-0.5rem",
    right: "0.75rem"
  },
  md: {
    marginTop: "-0.625rem",
    right: "1rem"
  },
  lg: {
    marginTop: "-0.75rem",
    right: "1.25rem"
  }
};
var StyledArrow = styled.svg.withConfig({
  displayName: "src__StyledArrow",
  componentId: "g9ru3a-0"
})(["position:absolute;top:50%;display:inline-block;pointer-events:none;"]);

var Arrow = function Arrow(_ref) {
  var size = _ref.size;
  return React.createElement(StyledArrow, {
    "aria-hidden": "true",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "3",
    strokeLinecap: "square",
    strokeLinejoin: "arcs",
    style: _objectSpread({}, offsets[size], {}, arrowSizes[size])
  }, React.createElement("path", {
    d: "M6 9l6 6 6-6"
  }));
};

var StyledSelectWrapper = styled.div.withConfig({
  displayName: "src__StyledSelectWrapper",
  componentId: "g9ru3a-1"
})(["position:relative;display:inline-block;vertical-align:middle;", " ", " ", " ", ""], space, width, maxWidth, color);
var StyledSelect = styled.select.withConfig({
  displayName: "src__StyledSelect",
  componentId: "g9ru3a-2"
})(["font-family:inherit;", " font-weight:600;display:inline-block;width:100%;margin:0;line-height:1.5;border:0;border-radius:0.1875rem;-webkit-appearance:none;-moz-appearance:none;appearance:none;padding-top:0;padding-bottom:0;", " ", " ", " &:hover{color:", ";background-color:", ";}&:focus{color:", ";background-color:", ";outline:none;box-shadow:0 0 0 0.125rem rgba(81,30,233,1),0 0 0 0.3125rem rgba(81,30,233,0.2);}&:focus:-moz-focusring{color:transparent;text-shadow:0 0 0 #000;}&::-ms-expand{display:none;}@-moz-document url-prefix(){text-indent:0.01px;text-overflow:\"\";> option{background-color:", ";}}"], fontSize, height, space, color, function (p) {
  return themeGet("colors." + p.colorHover);
}, function (p) {
  return themeGet("colors." + p.bgHover);
}, function (p) {
  return themeGet("colors." + p.colorHover);
}, function (p) {
  return themeGet("colors." + p.bgHover);
}, themeGet("colors.white"));

var Select = function Select(_ref2) {
  var value = _ref2.value,
      options = _ref2.options,
      _ref2$label = _ref2.label,
      label = _ref2$label === void 0 ? "select-1" : _ref2$label,
      onChange = _ref2.onChange,
      _ref2$size = _ref2.size,
      size = _ref2$size === void 0 ? "md" : _ref2$size,
      m = _ref2.m,
      mx = _ref2.mx,
      my = _ref2.my,
      mr = _ref2.mr,
      ml = _ref2.ml,
      mt = _ref2.mt,
      mb = _ref2.mb,
      width = _ref2.width,
      maxWidth = _ref2.maxWidth,
      _ref2$color = _ref2.color,
      color = _ref2$color === void 0 ? "text" : _ref2$color,
      _ref2$bg = _ref2.bg,
      bg = _ref2$bg === void 0 ? "grey.25" : _ref2$bg,
      _ref2$bgHover = _ref2.bgHover,
      bgHover = _ref2$bgHover === void 0 ? "grey.50" : _ref2$bgHover,
      _ref2$colorHover = _ref2.colorHover,
      colorHover = _ref2$colorHover === void 0 ? "text" : _ref2$colorHover,
      restProps = _objectWithoutPropertiesLoose(_ref2, ["value", "options", "label", "onChange", "size", "m", "mx", "my", "mr", "ml", "mt", "mb", "width", "maxWidth", "color", "bg", "bgHover", "colorHover"]);

  return React.createElement(StyledSelectWrapper, {
    m: m,
    mx: mx,
    my: my,
    mr: mr,
    ml: ml,
    mt: mt,
    mb: mb,
    width: width,
    maxWidth: maxWidth,
    color: color
  }, React.createElement(Arrow, {
    size: size
  }), React.createElement(StyledSelect, Object.assign({
    "aria-label": label,
    value: value,
    onChange: onChange,
    color: color,
    bg: bg,
    colorHover: colorHover,
    bgHover: bgHover
  }, sizes[size], restProps), options.map(function (_ref3) {
    var value = _ref3.value,
        label = _ref3.label;
    return React.createElement("option", {
      key: value,
      value: value
    }, label);
  })));
};

export default Select;