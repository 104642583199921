import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import styled from "styled-components";
import { borderRadius, borderColor, borderWidth, borderStyle, color, boxShadow } from "styled-system";
import { usePopoverState, Popover as BasePopover, PopoverDisclosure, PopoverArrow } from "reakit/Popover";
import { filterProps } from "../../../src/utils/filterProps";
var Div = styled.div.withConfig({
  displayName: "src__Div",
  componentId: "sc-14b5vae-0"
})(["", " ", " ", " ", " border-right:none;border-bottom:none;height:15px;width:15px;transform:rotateY(0deg) rotate(45deg);margin:auto;margin-top:21px;"], borderColor, borderWidth, borderStyle, color);
var allPropNames = [].concat(borderRadius.propNames, boxShadow.propNames, borderColor.propNames, borderWidth.propNames, "borderStyle");
var StyledBasePopover = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(BasePopover, filteredProps);
}).withConfig({
  displayName: "src__StyledBasePopover",
  componentId: "sc-14b5vae-1"
})(["", " ", " ", " ", " ", " ", " padding:1em;max-height:calc(100vh - 56px);outline:0;z-index:999;"], borderRadius, borderColor, borderWidth, borderStyle, color, boxShadow);
var StyledPopoverArrow = styled(function (props) {
  var filteredProps = filterProps(props, allPropNames);
  return React.createElement(PopoverArrow, filteredProps);
}).withConfig({
  displayName: "src__StyledPopoverArrow",
  componentId: "sc-14b5vae-2"
})([""]);
var DisclosureWrapper = styled.div.withConfig({
  displayName: "src__DisclosureWrapper",
  componentId: "sc-14b5vae-3"
})(["display:inline-flex;"]);

var Popover = function Popover(_ref) {
  var _ref$borderColor = _ref.borderColor,
      borderColor = _ref$borderColor === void 0 ? "rgba(33, 33, 33, 0.25)" : _ref$borderColor,
      _ref$borderRadius = _ref.borderRadius,
      borderRadius = _ref$borderRadius === void 0 ? "0.25rem" : _ref$borderRadius,
      _ref$borderWidth = _ref.borderWidth,
      borderWidth = _ref$borderWidth === void 0 ? "1px" : _ref$borderWidth,
      _ref$borderStyle = _ref.borderStyle,
      borderStyle = _ref$borderStyle === void 0 ? "solid" : _ref$borderStyle,
      _ref$bg = _ref.bg,
      bg = _ref$bg === void 0 ? "white" : _ref$bg,
      _ref$boxShadow = _ref.boxShadow,
      boxShadow = _ref$boxShadow === void 0 ? "sm" : _ref$boxShadow,
      disclosure = _ref.disclosure,
      _ref$placement = _ref.placement,
      placement = _ref$placement === void 0 ? "top-center" : _ref$placement,
      _ref$visible = _ref.visible,
      visible = _ref$visible === void 0 ? false : _ref$visible,
      _ref$boundariesElemen = _ref.boundariesElement,
      boundariesElement = _ref$boundariesElemen === void 0 ? "scrollParent" : _ref$boundariesElemen,
      _ref$tabIndex = _ref.tabIndex,
      tabIndex = _ref$tabIndex === void 0 ? 0 : _ref$tabIndex,
      _ref$ariaLabel = _ref.ariaLabel,
      ariaLabel = _ref$ariaLabel === void 0 ? "aria-label" : _ref$ariaLabel,
      props = _objectWithoutPropertiesLoose(_ref, ["borderColor", "borderRadius", "borderWidth", "borderStyle", "bg", "boxShadow", "disclosure", "placement", "visible", "boundariesElement", "tabIndex", "ariaLabel"]);

  var popover = usePopoverState({
    placement: placement,
    visible: visible,
    unstable_boundariesElement: boundariesElement
  });
  return React.createElement(React.Fragment, null, React.createElement(PopoverDisclosure, popover, function (disclosureProps) {
    return React.cloneElement(React.Children.only(React.createElement(DisclosureWrapper, null, disclosure) || React.createElement("button", null, "Specify disclosure")), disclosureProps);
  }), React.createElement(StyledBasePopover, Object.assign({
    borderStyle: borderStyle,
    borderWidth: borderWidth,
    borderRadius: borderRadius,
    borderColor: borderColor,
    bg: bg,
    tabIndex: tabIndex,
    "aria-label": ariaLabel,
    boxShadow: boxShadow
  }, popover, props), React.createElement(StyledPopoverArrow, Object.assign({}, popover, {
    children: React.createElement(Div, {
      bg: bg,
      borderColor: borderColor,
      borderStyle: borderStyle,
      borderWidth: borderWidth
    })
  })), props.children));
};

export default Popover;