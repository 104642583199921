import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
var StyledClearIndicator = styled.div.withConfig({
  displayName: "ClearIndicator__StyledClearIndicator",
  componentId: "sc-1vtw9uk-0"
})(["display:flex;padding:0.5rem;cursor:pointer;background:transparent;border-radius:100%;color:", ";&:hover,&:focus{color:", ";}"], themeGet("colors.grey.600"), themeGet("colors.text"));

var ClearIndicator = function ClearIndicator(_ref) {
  var innerProps = _ref.innerProps;
  return React.createElement(StyledClearIndicator, innerProps, React.createElement("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "20",
    height: "20",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentcolor",
    strokeWidth: "3",
    strokeLinecap: "butt",
    strokeLinejoin: "arcs"
  }, React.createElement("line", {
    x1: "18",
    y1: "6",
    x2: "6",
    y2: "18"
  }), React.createElement("line", {
    x1: "6",
    y1: "6",
    x2: "18",
    y2: "18"
  })));
};

export default ClearIndicator;