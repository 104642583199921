import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import React from "react";
import { Link } from "gatsby";
import { Heading } from "../../../packages/Typography/src";
import Div from "../../../packages/Div/src";

var Category = function Category(_ref) {
  var edges = _ref.edges,
      element = _ref.element,
      searchValue = _ref.searchValue,
      activeItemName = _ref.activeItemName;
  var filteredNodes = edges.filter(function (_ref2) {
    var node = _ref2.node;
    return node.frontmatter.category === element && node.frontmatter.title.toLowerCase().includes(searchValue.toLowerCase());
  });
  return React.createElement(React.Fragment, null, filteredNodes.length > 0 && React.createElement(Heading, {
    muted: true,
    size: "sm",
    mt: "1.25rem",
    mb: "0.75rem"
  }, element.toUpperCase()), filteredNodes.filter(function (s) {
    var slug = s.node.frontmatter.slug;
    var exclude = ["/packages/utils-cms", "/packages/utils-color"];
    return slug !== exclude[0] && slug !== exclude[1];
  }).map(function (_ref3) {
    var node = _ref3.node;
    var isActive = node.frontmatter.title === activeItemName;
    return React.createElement(Link, {
      key: node.id,
      to: node.frontmatter.slug
    }, React.createElement(Div, {
      bg: isActive ? "grey.100" : "transparent",
      px: "1rem",
      py: "0.5rem",
      borderRadius: "md"
    }, node.frontmatter.title));
  }));
};

export default Category;